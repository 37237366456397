import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Alert,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { PrimaryButton } from "@components/buttons";
import {
  IAgreeText,
  IDisagreeText,
  ServiceErrorText,
  SubmitLoadingText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  MailUseContentText,
  MailUseLabelText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";
import {
  MarketingModalOptInWrapper,
  MarketingOptInLabel,
} from "@components/modal/ModalStyling";
import {
  useOptInStrivacityUserConsent,
  useOptOutStrivacityUserConsent,
} from "@utils/api-requests/StrivacityReqeusts";
import { PERSONAL_CONSENT_ID } from "@utils/api-requests/static-data";
import { DangerouslyHtml } from "@components/item";

const ChangePersonalForm = ({
  modalContent,
  profileConsent,
  successMessage,
}) => {
  const queryClient = useQueryClient();

  const [isValid, setIsValid] = useState("");
  const [invalid, setInvalid] = useState("");

  const { mutate: optInMutate, isLoading: consentIsLoading } = useMutation(
    useOptInStrivacityUserConsent,
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          // when opt In change successfully done.
          setIsValid(successMessage);
          setInvalid("");

          queryClient.refetchQueries({
            queryKey: ["strivacityConsent"],
            type: "active",
          });

          return;
        }

        // TODO: need translation for error message
        if (data?.response?.data?.status === 400) {
          setInvalid(<ServiceErrorText />);
        }
      },
    },
  );

  const { mutate: optOutMutate } = useMutation(useOptOutStrivacityUserConsent, {
    onSuccess: (data) => {
      if (data.status === 200) {
        setIsValid(successMessage);
        setInvalid("");

        queryClient.refetchQueries({
          queryKey: ["strivacityConsent"],
          type: "active",
        });

        return;
      }

      // TODO: handle error
      if (data?.response?.data?.status === 400) {
        // Handle error
        setInvalid(<ServiceErrorText />);
      }
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      personal_use: profileConsent?.accepted ? "1" : "0", // Set default value based on profileConsent
    },
  });

  const selectedPersonal = watch("personal_use");

  const PersonalConsentModalFormSubmit = useCallback(
    (data) => {
      if (data) {
        // Conditionally use opt-in or opt-out mutation based on radio button value
        if (data.personal_use === "1") {
          optInMutate({
            consentId: PERSONAL_CONSENT_ID,
            body: {
              // Add the necessary body for the opt-in
            },
          });
        } else {
          optOutMutate({
            consentId: PERSONAL_CONSENT_ID,
            body: {
              id: profileConsent?.receiptId,
            },
          });
        }
      }
    },
    [optInMutate, optOutMutate, profileConsent?.receiptId],
  );

  return (
    <div>
      {invalid && (
        <Alert severity="error" sx={{ width: "100%", marginBottom: "1.6rem" }}>
          {invalid}
        </Alert>
      )}

      {isValid !== "" && (
        <Alert
          severity="success"
          sx={{ width: "100%", marginBottom: "1.6rem" }}
        >
          <DangerouslyHtml value={isValid} />
        </Alert>
      )}

      <form
        method="post"
        onSubmit={handleSubmit(PersonalConsentModalFormSubmit)}
      >
        <MarketingModalOptInWrapper>
          <MarketingOptInLabel>
            <MailUseLabelText />
          </MarketingOptInLabel>

          <MailUseContentText />

          <FormControl component="fieldset" error={!!errors.personal_use}>
            <FormLabel component="legend">
              {modalContent?.field_radio_label}
            </FormLabel>

            <Controller
              control={control}
              name="personal_use"
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  row
                >
                  <FormControlLabel
                    style={{ alignItems: "center" }}
                    value="1"
                    control={<Radio />}
                    label={<IAgreeText />}
                  />

                  <FormControlLabel
                    style={{ alignItems: "center" }}
                    value="0"
                    control={<Radio />}
                    label={<IDisagreeText />}
                  />
                </RadioGroup>
              )}
            />
            <FormHelperText>
              {errors.mailoptin ? errors.mailoptin.message : " "}
            </FormHelperText>
          </FormControl>
        </MarketingModalOptInWrapper>

        <PrimaryButton
          buttonType="submit"
          disabled={
            consentIsLoading ||
            !selectedPersonal ||
            (profileConsent?.accepted && selectedPersonal === "1") ||
            (!profileConsent?.accepted && selectedPersonal === "0")
          }
        >
          {consentIsLoading ? (
            <SubmitLoadingText />
          ) : (
            modalContent?.field_submit_text
          )}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default ChangePersonalForm;
