import React from "react";
import styled from "@emotion/styled";
import {
  SectionTitle,
  EventButton,
  AWS_BUCKET_URL,
} from "@components/events/winter-wonderland/WinterWonderland";
import { mq } from "@utils/media-query/mediaQuery";
import { useMediaQuery } from "react-responsive";

const Container = styled.div(() => ({
  background: "#F3F3F3",
  width: "100%",
  padding: "1.5rem 2rem",

  [mq("desktop")]: {
    padding: "5rem",
  },
}));

const Title = styled.div(() => ({
  marginBottom: "2.5rem",
  textAlign: "center",
  fontWeight: 700,
}));

const EventContents = styled.div(() => ({
  display: "flex",
  flexDirection: "column-reverse",
  gap: "0 2.5rem",
  alignItems: "center",

  [mq("tablet")]: {
    flexDirection: "row",
    gap: "0 2rem",
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

const Texts = styled.div(() => ({
  display: "inline-flex",
  flexDirection: "column",
  gap: "6.4rem 0",
  fontWeight: 300,
  fontSize: "1.4rem",
  marginBottom: "1rem",

  p: {
    textAlign: "center",
  },

  [mq("tablet")]: {
    gap: "2rem 0",

    p: {
      textAlign: "left",
    },
  },

  [mq("desktop")]: {
    display: "inline-flex",
    marginBottom: 0,
    fontSize: "1.8rem",

    p: {
      textAlign: "left",
    },
  },
}));

const Image = styled.img(() => ({
  width: "100%",

  [mq("tablet")]: {
    maxWidth: "60%",
  },

  [mq("desktop")]: {
    maxWidth: "78.5rem",
  },
}));

const ButtonWrapper = styled.div(() => ({
  marginTop: "2rem",
  textAlign: "center",
}));

const SeventhSection = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Container>
      <Title>
        <SectionTitle>New Year Wish Comment Event</SectionTitle>

        <span>2025.01.15 - 2025.01.21</span>
      </Title>

      <EventContents>
        <Image
          src={
            isMobile
              ? `${AWS_BUCKET_URL}/8_mobile.jpg`
              : `${AWS_BUCKET_URL}/8_pc.jpg`
          }
          alt="새해에 이루고 싶은 소원이 있나요? 인스파이어와 공유해주세요!"
          width="100%"
        />

        <Texts>
          <div>
            <p>새해에 이루고 싶은 소원이 있나요? 인스파이어와 공유해주세요!</p>

            <p>
              여러분의 새해 소망을 응원하기 위해 준비한 새해맞이 댓글 이벤트!
              추첨을 통해 당첨되신 분들을 인스파이어로 초대합니다!
            </p>
          </div>

          {!isMobile && (
            <EventButton
              customLink="https://www.instagram.com/inspirekorea/"
              buttonName="자세히 보기"
            />
          )}
        </Texts>
      </EventContents>

      {isMobile && (
        <ButtonWrapper>
          <EventButton
            buttonName="자세히 보기"
            outerLink="https://www.instagram.com/inspirekorea/"
          />
        </ButtonWrapper>
      )}
    </Container>
  );
};

export default SeventhSection;
