import React, { memo } from "react";
import WonderlandFinal from "@components/events/WonderlandFinal";

const Wonderland2024 = () => {
  return (
    <>
      <WonderlandFinal />
    </>
  );
};

export default memo(Wonderland2024);
