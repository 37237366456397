import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { ErrorManage } from "@screens/index";
import {
  useGetHeaderListQuery,
  useGetHeaderUtilBarQuery,
} from "@utils/api-requests/GlobalRequests";
import { IsDefault, IsDesktop } from "@utils/media-query/mediaQuery";
import {
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from "@utils/api-requests/static-data";

import { MobileHeader, MobileMenuModal, WebHeader } from ".";

export const JOIN_MOMENTUM_ENTITY_ID = "184";
export const SIGN_IN_ENTITY_ID = "185";
export const SIGN_OUT_ENTITY_ID = "259";
export const MY_PROFILE_ENTITY_ID = "260";

const Header = ({ languageModal, setLanguageModal }) => {
  const pathname = useLocation().pathname;

  const [bookingModal, setBookingModal] = useState(false);
  const [utilbarArr, setUtilbarArr] = useState([]);

  const [menuModalActive, setMenuModalActive] = useState(false);
  const [headerColored, setHeaderColored] = useState(false);

  const {
    data: headerUtilBar,
    isError: utilIsError,
    error: utilError,
  } = useGetHeaderUtilBarQuery();

  const {
    data: headerList,
    isLoading,
    isError,
    error,
  } = useGetHeaderListQuery();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    if (lang !== null) return `/${lang}`;
  }, [lang]);

  const userIsLoggedIn =
    localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) !== null;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setHeaderColored(true);
      } else {
        setHeaderColored(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [baseUrl, headerColored, pathname]);

  useEffect(() => {
    if (headerUtilBar) {
      //  when user log in
      if (userIsLoggedIn) {
        setUtilbarArr(
          headerUtilBar?.filter(
            (el) =>
              el.entity_id !== JOIN_MOMENTUM_ENTITY_ID &&
              el.entity_id !== SIGN_IN_ENTITY_ID,
          ),
        );
      } else {
        // when user log out
        setUtilbarArr(
          headerUtilBar?.filter(
            (el) =>
              el.entity_id !== SIGN_OUT_ENTITY_ID &&
              el.entity_id !== MY_PROFILE_ENTITY_ID,
          ),
        );
      }
    }
  }, [headerUtilBar, userIsLoggedIn]);

  return (
    <ErrorManage isError={utilIsError} error={utilError}>
      <IsDesktop>
        <WebHeader
          isLoading={isLoading}
          isError={isError}
          error={error}
          headerList={headerList}
          utilbarArr={utilbarArr}
          languageModal={languageModal}
          setLanguageModal={setLanguageModal}
          headerColored={baseUrl === pathname ? headerColored : true}
          bookingModal={bookingModal}
          setBookingModal={setBookingModal}
        />
      </IsDesktop>

      <IsDefault>
        <MobileHeader
          active={menuModalActive}
          setActive={setMenuModalActive}
          headerColored={headerColored}
        />

        <MobileMenuModal
          active={menuModalActive}
          setActive={setMenuModalActive}
          headerList={headerList}
          utilbarArr={utilbarArr}
          headerColored={headerColored}
          isLoading={isLoading}
          isError={isError}
          error={error}
          languageModal={languageModal}
          setLanguageModal={setLanguageModal}
        />
      </IsDefault>
    </ErrorManage>
  );
};

export default Header;
