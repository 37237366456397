import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useIpInformation } from "@utils/api-requests/GlobalRequests";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

export const languageList = [
  { id: 1, name: "English", code: "en" },
  { id: 2, name: "한국어", code: "ko" },
  { id: 3, name: "日本語", code: "ja" },
  { id: 4, name: "繁體中文", code: "zh-hant" },
  { id: 5, name: "简体中文", code: "zh-hans" },
];

export const isJapanese = localStorage.getItem(LOCAL_STORAGE_LANGUAGE) === "ja";
export const isKorean = localStorage.getItem(LOCAL_STORAGE_LANGUAGE) === "ko";
export const isChineseLanguage =
  localStorage.getItem(LOCAL_STORAGE_LANGUAGE) === "zh-hans" ||
  localStorage.getItem(LOCAL_STORAGE_LANGUAGE) === "zh-hant";

export function useCheckChinese() {
  const { ipInformation } = useIpInformation();

  return (
    ipInformation?.countryCode === "CN" ||
    localStorage.getItem(LOCAL_STORAGE_LANGUAGE) === "zh-hans" ||
    localStorage.getItem(LOCAL_STORAGE_LANGUAGE) === "zh-hant"
  );
}

export function useDefaultLanguage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const url = useLocation().pathname + "/";
  const { ipInformation } = useIpInformation();

  const urlLang = useMemo(() => {
    return url.split("/")[1];
  }, [url]);

  let code;

  useEffect(() => {
    if (code && code !== urlLang && !pathname.includes("/not-found")) {
      const replacedURL = `/${code}${url}/`;

      navigate(replacedURL.replace(/\/+$/, ""));
    }
  }, [code, url, urlLang, navigate, pathname]);

  if (urlLang !== "") {
    // IN CASE : If user entered language value after site url.
    code = checkLangIncludes(urlLang) ? urlLang : undefined;
  }

  if (
    code === undefined &&
    localStorage.getItem(LOCAL_STORAGE_LANGUAGE) !== undefined &&
    localStorage.getItem(LOCAL_STORAGE_LANGUAGE) !== null
  ) {
    // Check Local Storage
    code = checkLangIncludes(localStorage.getItem(LOCAL_STORAGE_LANGUAGE))
      ? localStorage.getItem(LOCAL_STORAGE_LANGUAGE)
      : undefined;
  }

  if (code === undefined) {
    // Check Browser Language
    code = browserLanugageSwitch();
  }

  if (code === undefined) {
    // Ip Information
    code = country_check(ipInformation?.countryCode);
  }

  return code;
}

const checkLangIncludes = (code) => {
  let flag = false;

  for (let i = 0; i < languageList?.length; i++) {
    const language = languageList[i];

    if (code.includes(language.code)) {
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE, language.code);

      flag = true;

      break;
    }
  }

  return flag;
};

const browserLanugageSwitch = () => {
  if (typeof navigator === "undefined") {
    return undefined;
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0];

  if (!lang) return undefined;

  switch (lang) {
    case "ja":
      return "ja";

    case "ko-KR":
      return "ko";

    case "ko":
      return "ko";

    case "zh":
      return "zh-hans";

    case "zh-CN":
      return "zh-hans";

    case "zh-TW":
      return "zh-hant";

    case "en":
      return "en";

    default:
      return undefined;
  }
};

const country_check = (code) => {
  switch (code) {
    case "KR":
      return "ko";

    case "CN":
      return "zh-hans";

    case "JP":
      return "ja";

    default:
      return "en";
  }
};

export const countrySwitchLanguage = (code) => {
  switch (code) {
    case "ko":
      return "ko";

    case "zh-hans":
      return "zh";

    case "zh-hant":
      return "zh";

    case "ja":
      return "ja";

    default:
      return "en";
  }
};

export const strivacityLanguage = (lang) => {
  switch (lang) {
    case "en":
      return "en-US";
    case "ko":
      return "ko-KR";
    case "ja":
      return "ja-JP";
    case "zh-hans":
      return "zh-CN";
    case "zh-hant":
      return "zh-HK";
    default:
      return "en-US";
  }
};
