import React, { memo, useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Backdrop, Fade, Modal } from "@mui/material";

import { image } from "src/theme";
import { DangerouslyHtml } from "@components/item";

import {
  CommonModalCloseButton,
  CommonModalContainer,
  CommonModalContents,
  CommonModalTitle,
  CommonModalTitleWrapper,
  CommonModalWrapper,
} from "./ModalStyling";

const CommonModal = ({
  modalName,
  description,
  openModal,
  setOpenModal,
  children,
  noHeader,
  noPadding,
  noName,
  whiteClose,
  hideClose,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  useEffect(() => {
    const escKeyModalClose = (e) => {
      if (e.keyCode === 27) {
        // close modal when press Esc button.
        e.preventDefault();

        if (setOpenModal) {
          setOpenModal(false);
        }
      }
    };

    document.addEventListener("keyup", escKeyModalClose);
    return () => document.removeEventListener("keyup", escKeyModalClose);
  }, [setOpenModal]);

  useEffect(() => {
    if (openModal) {
      document.body.style.cssText = `
      overflow-y: hidden;
      max-height: 100vh;
      width: 100%;
      `;
    }

    return () => {
      document.body.style.cssText = "";
    };
  }, [openModal]);

  return (
    <Modal
      open={openModal}
      closeAfterTransition
      slots={{ overlay: Backdrop }}
      slotProps={{
        overlay: {
          timeout: 500,
        },
      }}
      aria-labelledby={modalName}
      aria-describedby={description}
    >
      <Fade in={openModal}>
        <CommonModalContainer noPadding={noPadding}>
          {!hideClose && (
            <CommonModalCloseButton type="button">
              <img
                src={
                  whiteClose
                    ? image.modalCloseWhiteIcon.default
                    : image.modalCloseIcon.default
                }
                alt="Close Modal"
                width={isDesktop ? 40 : 32}
                height={isDesktop ? 40 : 32}
                loading="lazy"
                onClick={handleClose}
              />
            </CommonModalCloseButton>
          )}

          <CommonModalWrapper>
            {!noHeader && (
              <CommonModalTitleWrapper>
                {noName ? (
                  <div></div>
                ) : (
                  <CommonModalTitle>
                    <DangerouslyHtml value={modalName} />
                  </CommonModalTitle>
                )}
              </CommonModalTitleWrapper>
            )}

            <CommonModalContents>{children}</CommonModalContents>
          </CommonModalWrapper>
        </CommonModalContainer>
      </Fade>
    </Modal>
  );
};

export default memo(CommonModal);
