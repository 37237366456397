import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { MainAdBanner } from "@components/item";
import { Header } from "@components/header";
import { Footer } from "@components/footer";

import { Home } from "@pages/home";
import { Offers } from "@pages/offers/offers";
import { Stay } from "@pages/stay";
import { RoomDetail } from "@pages/stay/detail/room";
import { AmenityDetail } from "@pages/stay/detail/amenity";
import { EatDrink } from "@pages/eat-drink";
import { RestaurantDetail } from "@pages/eat-drink/detail";
import { Shop } from "@pages/shop";
import { SplashBay } from "@pages/splash-bay";
import { Entertainment } from "@pages/entertainment";
import { EntertainmentDetail } from "@pages/entertainment/detail";
import { Meet } from "@pages/meet";
import { Casino } from "@pages/casino";
import { Momentum } from "@pages/momentum";
import { OffersDetail } from "@pages/offers/offers/detail";
import { SplashBayDomeDetail } from "@pages/splash-bay/detail/splash-dome";
import { SplashBayServicesDetail } from "@pages/splash-bay/detail/services";
import { SplashBayReserveDetail } from "@pages/splash-bay/detail/reservation";
import { MeetServicesDetail } from "@pages/meet/detail/services";
import { MeetSightseeingDetail } from "@pages/meet/detail/sightseeing";
import { MeetingSuccessDetail } from "@pages/meet/detail/meeting-success";
import { StoreDetail } from "@pages/shop/detail";
import { WeddingEventDetail } from "@pages/meet/detail/wedding-events";
import { AboutUs, AboutUsSitemap } from "@pages/about-us";
import { InspireArenaDetail } from "@pages/entertainment/detail/inspire-arena";
import { InspireResortDetail } from "@pages/about-us/detail/Inspire-resort";
import { InspireRouterContainer, InspireRouterPageAll } from "./RoutesStyling";
import { CareersDetail } from "@pages/about-us/detail/careers";
import { MoheganResortDetail } from "@pages/about-us/detail/mohegan-resort";
import { EntertainmentVenueDetail } from "@pages/entertainment/detail/attraction-venues";
import { EntertainmentEventList } from "@pages/entertainment/detail/events";
import { EntertainmentEventItemDetail } from "@pages/entertainment/detail/event-detail";
import { GettingHereDetail } from "@pages/about-us/detail/getting-here";
import { AboutUsGalleryDetail } from "@pages/about-us/detail/gallery";
import {
  MediaCenterArticle,
  MediaCenterDetail,
} from "@pages/about-us/detail/media-center";
import { MomentumTiersDetail } from "@pages/momentum/detail";
import { PerformanceDetail } from "@pages/entertainment/detail/performance";
import { CasinoDiningDetail } from "@pages/casino/detail/casino-dining";
import { CasinoTiersDetail } from "@pages/casino/detail/casino-tiers";
import {
  CasinoAboutInspire,
  CasinoGameDetail,
} from "@pages/casino/detail/about-inspire-casino";
import MomentumLifestyleDetail from "@pages/momentum/detail/lifestyle-events/MomentumLifestyleDetail";
import {
  CasinoEventDetail,
  CasinoEventList,
} from "@pages/casino/detail/casino-events";
import { PrivacyPolicyDetail } from "@pages/about-us/detail/privacy-policy";
import { AboutUsTermsConditionsDetail } from "@pages/about-us/detail/terms-conditions";
import {
  LoadingScreen,
  MaintenanceScreen,
  NotFound,
  WrongAproach,
} from "@screens/index";
import {
  useGetPageInformation,
  useGetPdfFileList,
  useIpInformation,
} from "@utils/api-requests/GlobalRequests";
import {
  FilterDetailPageData,
  FilterPageData,
} from "@utils/modify-data/modifyData";
import {
  EXPIRE_DURATION,
  LOCAL_STORAGE_EXPIRE_TOKEN,
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_VISITED,
} from "@utils/api-requests/static-data";

import { BlockRouter, LoginRouter } from ".";
import { SignIn } from "@pages/momentum/sign-in";
import { AgilisysSignUp } from "@pages/momentum/sign-up";
import { CustomerCenterDetail } from "@pages/about-us/detail/customer-center";
import { FloorGuide } from "@pages/about-us/detail/floor-guide";
import { WhyUs } from "@pages/meet/detail/why-us";
import { MomentumLifestyleList } from "@pages/momentum/detail/lifestyle-events";
import { ResponsibleGamingDetail } from "@pages/casino/detail/responsible-gaming";
import { BasicPage } from "@pages/basic";
import { PdfViewer } from "@components/sub-page";
import Wonderland2024 from "@pages/events/Wonderland2024";
import { MyProfileStrivacity } from "@pages/momentum/my-profile";
import WinterWonderland from "@components/events/winter-wonderland/WinterWonderland";

const InspireRouter = () => {
  const pathname = useLocation().pathname;

  const { data: pdfFileList } = useGetPdfFileList();

  const supportedLanguages = ["en", "ko", "ja", "zh-hans", "zh-hant"];

  // const getLanguageFromUrl = () => {
  //   const pathSegments = pathname.split("/");
  //
  //   if (
  //     pathSegments.length > 1 &&
  //     supportedLanguages.includes(pathSegments[1])
  //   ) {
  //     return pathSegments[1]; // Return detected language if it's in the supported languages
  //   }
  //   return null; // Return null for unsupported languages
  // };
  //
  // const currentUrlLanguage = getLanguageFromUrl();

  const [mainAdBannerOpen, setMainAdBannerOpen] = useState(false);

  const { ipInformation } = useIpInformation();
  const { isFetching } = useGetPageInformation();

  const [pageLoading, setPageLoading] = useState(true);
  const [languageModal, setLanguageModal] = useState(false);

  const [headerFooterHide, setHeaderFooterHide] = useState(false);

  useEffect(() => {
    setPageLoading(true);

    if (localStorage.getItem(LOCAL_STORAGE_LANGUAGE) && !isFetching) {
      setPageLoading(false);
    }
  }, [isFetching]);

  const closeLangModal = useCallback(() => {
    setLanguageModal(false);
  }, []);

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || "en";

  const baseUrl = useMemo(() => {
    if (lang !== null) return `/${lang}`;
  }, [lang]);

  //redirect to a fallback language
  // if (!currentUrlLanguage && baseUrl) {
  // window.location.href = `${baseUrl}${pathname}`;
  // }

  const renderMultiRoutes = ({ element: Element, path, ...rest }) =>
    supportedLanguages.map((staticPath) => (
      <Route
        key={`${staticPath}/${path}`}
        path={`${staticPath}${path}`}
        {...rest}
        element={Element}
      />
    ));

  const HOME_VISITED = localStorage.getItem(LOCAL_STORAGE_VISITED);

  useEffect(() => {
    const today = new Date();
    const handleScroll = () => {
      if (!HOME_VISITED || HOME_VISITED < today) {
        if (window.scrollY > 1) {
          setMainAdBannerOpen(false);
        } else {
          setMainAdBannerOpen(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [HOME_VISITED, baseUrl, pathname]);

  useEffect(() => {
    const today = new Date();
    const handleMainPop = () => {
      if (HOME_VISITED && HOME_VISITED > today) {
        return;
      }

      if (!HOME_VISITED || HOME_VISITED < today) {
        setMainAdBannerOpen(true);
      }
    };

    window.setTimeout(handleMainPop, 1000);
  }, [HOME_VISITED, setMainAdBannerOpen]);

  useEffect(() => {
    if (pathname.includes("maintenance")) {
      setHeaderFooterHide(true);

      setPageLoading(false);
      return;
    }

    setHeaderFooterHide(false);
  }, [pathname]);

  const extendTokenExpire = useCallback(() => {
    // NOTE: when the user action occur, token expiration time extends.
    if (localStorage.getItem(LOCAL_STORAGE_EXPIRE_TOKEN)) {
      const expireTime = new Date().getTime() + EXPIRE_DURATION * 60 * 1000; // 25 minutes later
      localStorage.setItem(LOCAL_STORAGE_EXPIRE_TOKEN, expireTime.toString());
    }
  }, []);

  return (
    <div onClick={extendTokenExpire}>
      {pathname === baseUrl && !pageLoading && (
        <MainAdBanner
          mainAdBannerOpen={mainAdBannerOpen}
          setMainAdBannerOpen={setMainAdBannerOpen}
        />
      )}

      <InspireRouterContainer>
        {pageLoading && <LoadingScreen />}

        {!headerFooterHide && (
          <Header
            headerFooterHide={headerFooterHide}
            languageModal={languageModal}
            setLanguageModal={setLanguageModal}
          />
        )}

        <InspireRouterPageAll
          onClick={closeLangModal}
          noMargin={pathname === baseUrl}
          headerFooterHide={headerFooterHide}
        >
          <Routes>
            {pdfFileList?.map((pdf) => {
              return renderMultiRoutes({
                path: pdf?.url_alias,
                element: <PdfViewer link={pdf?.field_pdf_file} />,
              });
            })}

            {/* NOTE: events */}
            {renderMultiRoutes({
              path: "/events/winter_wonderland_2024",
              element: <Wonderland2024 />,
            })}

            {renderMultiRoutes({
              path: "/events/winter_wonderland_2025",
              element: <WinterWonderland />,
            })}

            {/* My Profile */}
            {renderMultiRoutes({
              path: "/my-profile",
              element: <MyProfileStrivacity />,
            })}

            {renderMultiRoutes({
              path: "/maintenance",
              element: <MaintenanceScreen />,
            })}

            {/*overwrites requested by INSPIRE 120723*/}

            {renderMultiRoutes({
              path: "/offers/detail/special-opening-package-luxury-breakfast",
              element: (
                <Navigate
                  to={`${baseUrl}/offers/detail/special-opening-package-breakfast`}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/offers/detail/special-opening-package-sunny-pools",
              element: (
                <Navigate
                  to={`${baseUrl}/offers/detail/special-opening-package-room-only`}
                />
              ),
            })}

            {/*overwrites requested by INSPIRE 120723*/}

            <Route
              path="/maintenance/*"
              element={<Navigate to={`${baseUrl}/maintenance`} />}
            />

            <Route path="/" element={<Navigate to={baseUrl} replace />} />

            <Route
              path="/momentum/*"
              element={<Navigate to={`${baseUrl}/momentum/sign-in`} />}
            />

            <Route
              path="/momentum/tiers-benefits"
              element={<Navigate to={`${baseUrl}/momentum/tiers-benefits`} />}
            />

            {/* Sign In */}
            {renderMultiRoutes({
              path: "/momentum/sign-in",
              element: (
                <LoginRouter login={false}>
                  <SignIn />
                </LoginRouter>
              ),
            })}

            {/* Sign Up*/}
            {renderMultiRoutes({
              path: "/momentum/join-momentum",
              element: <AgilisysSignUp />,
            })}

            {renderMultiRoutes({
              path: "/page/:pageAlias",
              element: <BasicPage />,
            })}

            {/* Home */}
            {renderMultiRoutes({
              path: "",
              element: <Home />,
            })}

            {/*{renderMultiRoutes({*/}
            {/*  path: "/components",*/}
            {/*  element: <ComponentsAll />,*/}
            {/*})}*/}

            {/* Sub Page : About Us */}
            {renderMultiRoutes({
              path: "/about-us",
              element: <AboutUs pageInfo={FilterPageData("about_us")} />,
            })}

            {renderMultiRoutes({
              path: "/about-us/inspire-resort",
              element: (
                <InspireResortDetail
                  pageInfo={FilterDetailPageData(
                    "INSPIRE Entertainment Resort",
                  )}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/about-us/careers",
              element: (
                <CareersDetail pageInfo={FilterDetailPageData("Careers")} />
              ),
            })}

            {renderMultiRoutes({
              path: "/about-us/media-center",
              element: (
                <MediaCenterDetail
                  pageInfo={FilterDetailPageData("Media Center")}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/about-us/media-center/:mediaAlias",
              element: <MediaCenterArticle />,
            })}

            {renderMultiRoutes({
              path: "/about-us/mohegan-resort",
              element: (
                <MoheganResortDetail
                  pageInfo={FilterDetailPageData("Mohegan Resort")}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/about-us/sitemap",
              element: <AboutUsSitemap />,
            })}

            {renderMultiRoutes({
              path: "/about-us/gallery",
              element: <AboutUsGalleryDetail />,
            })}

            {renderMultiRoutes({
              path: "/about-us/getting-here",
              element: (
                <GettingHereDetail
                  pageInfo={FilterDetailPageData("getting here")}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/about-us/privacy-policy",
              element: <PrivacyPolicyDetail />,
            })}

            {renderMultiRoutes({
              path: "/about-us/terms-conditions",
              element: <AboutUsTermsConditionsDetail />,
            })}

            {/* {renderMultiRoutes({
              path: "/about-us/health-safety",
              element: <AboutUsHealthSafety />,
            })} */}

            {renderMultiRoutes({
              path: "/about-us/customer-center",
              element: (
                <CustomerCenterDetail
                  pageInfo={FilterDetailPageData("customer center")}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/about-us/floor-guide",
              element: (
                <FloorGuide pageInfo={FilterDetailPageData("Floor Guide")} />
              ),
            })}

            {/* Sub Page : Offers */}
            {renderMultiRoutes({
              path: "/offers",
              element: <Offers pageInfo={FilterPageData("offer")} />,
            })}

            {renderMultiRoutes({
              path: "/offers/detail/:offersAlias",
              element: <OffersDetail />,
            })}

            {/* {renderMultiRoutes({
              path: "/offers/room-offers",
              element: <OfferTypedList />,
            })}

            {renderMultiRoutes({
              path: "/offers/dining-offers",
              element: <OfferTypedList />,
            })}

            {renderMultiRoutes({
              path: "/offers/splash-bay-offers",
              element: <OfferTypedList />,
            })}

            {renderMultiRoutes({
              path: "/offers/entertainment-offers",
              element: <OfferTypedList />,
            })}

            {renderMultiRoutes({
              path: "/offers/live-commerce-offers",
              element: <OfferTypedList />,
            })}

            {renderMultiRoutes({
              path: "/offers/special-events-offers",
              element: <OfferTypedList />,
            })} */}

            {/* Sub Page : Stay */}
            {renderMultiRoutes({
              path: "/stay",
              element: <Stay pageInfo={FilterPageData("stay")} />,
            })}

            {renderMultiRoutes({
              path: "/stay/room/:roomCategoryId/:roomAlias",
              element: <RoomDetail pageInfo={FilterDetailPageData("Rooms")} />,
            })}

            {renderMultiRoutes({
              path: "/stay/amenities/:amenityAlias",
              element: (
                <AmenityDetail pageInfo={FilterDetailPageData("Amenities")} />
              ),
            })}

            {/* Sub Page : Eat & Drink */}
            {renderMultiRoutes({
              path: "/eat-and-drink",
              element: <EatDrink pageInfo={FilterPageData("eat_drink")} />,
            })}

            {renderMultiRoutes({
              path: "/eat-and-drink/:restaurantCategoryId/:restaurantAlias",
              element: (
                <RestaurantDetail
                  pageInfo={FilterDetailPageData("Restaurants")}
                />
              ),
            })}

            {/* Sub Page : Entertainment */}
            {renderMultiRoutes({
              path: "/entertainment",
              element: (
                <Entertainment pageInfo={FilterPageData("entertainment")} />
              ),
            })}

            {renderMultiRoutes({
              path: "/entertainment/event/:eventsAlias",
              element: <EntertainmentEventList />,
            })}

            {renderMultiRoutes({
              path: "/entertainment/event/detail/:eventAlias",
              element: <EntertainmentEventItemDetail />,
            })}

            {renderMultiRoutes({
              path: "/entertainment/inspire-arena/:arenaAlias",
              element: <InspireArenaDetail />,
            })}

            {renderMultiRoutes({
              path: "/entertainment/:event",
              element: <EntertainmentDetail />,
            })}

            {renderMultiRoutes({
              path: "/entertainment/attraction-venue/:venueAlias",
              element: <EntertainmentVenueDetail />,
            })}

            {renderMultiRoutes({
              path: "/entertainment/performance/:performanceAlias",
              element: <PerformanceDetail />,
            })}

            {/* Sub Page : Shop */}
            {renderMultiRoutes({
              path: "/shop",
              element: <Shop pageInfo={FilterPageData("shop")} />,
            })}

            {renderMultiRoutes({
              path: "/shop/store/:categoryId/:storeAlias",
              element: <StoreDetail />,
            })}

            {/* Sub Page : Splash Bay */}
            {renderMultiRoutes({
              path: "/splash-bay",
              element: <SplashBay pageInfo={FilterPageData("splash")} />,
            })}

            {renderMultiRoutes({
              path: "/splash-bay/facilities/splash-bay-dome",
              element: (
                <SplashBayDomeDetail
                  pageInfo={FilterDetailPageData("Splash-Bay")}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/splash-bay/services/:serviceAlias",
              element: <SplashBayServicesDetail />,
            })}

            {renderMultiRoutes({
              path: "/splash-bay/reservation",
              element: (
                <SplashBayReserveDetail
                  pageInfo={FilterDetailPageData("Reserve Now")}
                />
              ),
            })}

            {/* Sub Page : Meet */}
            {renderMultiRoutes({
              path: "/meet",
              element: <Meet pageInfo={FilterPageData("meet")} />,
            })}

            {renderMultiRoutes({
              path: "/meet/meeting-success/:meetingSuccessAlias",
              element: (
                <MeetingSuccessDetail
                  pageInfo={FilterDetailPageData("Meeting")}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/meet/wedding-events/:meetTypes",
              element: <WeddingEventDetail />,
            })}

            {renderMultiRoutes({
              path: "/meet/services/:serviceAlias",
              element: (
                <MeetServicesDetail
                  pageInfo={FilterPageData("Meet Services")}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/meet/sightseeing/:sightseeingAlias",
              element: <MeetSightseeingDetail />,
            })}

            {renderMultiRoutes({
              path: "/meet/why-us/:whyUsName",
              element: <WhyUs />,
            })}
            {/* Sub Page : Momentum */}

            {renderMultiRoutes({
              path: "/momentum",
              element: <Momentum pageInfo={FilterPageData("momentum")} />,
            })}

            {renderMultiRoutes({
              path: "/momentum/tiers-benefits",
              element: (
                <MomentumTiersDetail
                  pageInfo={FilterDetailPageData("Tiers and Benefits")}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/momentum/lifestyle-events",
              element: (
                <MomentumLifestyleList
                  pageInfo={FilterDetailPageData("lifestyle events")}
                />
              ),
            })}

            {renderMultiRoutes({
              path: "/momentum/lifestyle-events/:eventAlias",
              element: <MomentumLifestyleDetail />,
            })}

            {/* Block China accessing Gaming Contents */}
            {renderMultiRoutes({
              path: "/casino/*",
              element: (
                <BlockRouter ipInformation={ipInformation}>
                  <Casino pageInfo={FilterPageData("casino")} />
                </BlockRouter>
              ),
            })}

            {renderMultiRoutes({
              path: "/casino/games",
              element: (
                <BlockRouter ipInformation={ipInformation}>
                  <CasinoAboutInspire
                    pageInfo={FilterDetailPageData("About Inspire Casino")}
                  />
                </BlockRouter>
              ),
            })}

            {renderMultiRoutes({
              path: "/casino/games/:gameAlias",
              element: (
                <BlockRouter ipInformation={ipInformation}>
                  <CasinoGameDetail />
                </BlockRouter>
              ),
            })}

            {renderMultiRoutes({
              path: "/casino/dining",
              element: (
                <BlockRouter ipInformation={ipInformation}>
                  <CasinoDiningDetail
                    pageInfo={FilterDetailPageData("Casino Dining")}
                  />
                </BlockRouter>
              ),
            })}

            {renderMultiRoutes({
              path: "/casino/momentum-rewards",
              element: (
                <BlockRouter ipInformation={ipInformation}>
                  <CasinoTiersDetail
                    pageInfo={FilterDetailPageData("Casino Tiers")}
                  />
                </BlockRouter>
              ),
            })}

            {renderMultiRoutes({
              path: "/casino/events-promotions",
              element: (
                <BlockRouter ipInformation={ipInformation}>
                  <CasinoEventList
                    pageInfo={FilterDetailPageData("Casino Events")}
                  />
                </BlockRouter>
              ),
            })}

            {renderMultiRoutes({
              path: "/casino/events-promotions/:eventAlias",
              element: (
                <BlockRouter ipInformation={ipInformation}>
                  <CasinoEventDetail />
                </BlockRouter>
              ),
            })}

            {renderMultiRoutes({
              path: "/casino/responsible-gaming",
              element: (
                <BlockRouter ipInformation={ipInformation}>
                  <ResponsibleGamingDetail
                    pageInfo={FilterDetailPageData("Responsible Gaming")}
                  />
                </BlockRouter>
              ),
            })}

            {/* other pages */}
            <Route path="*" element={<NotFound />} />

            {renderMultiRoutes({
              path: "/not-found",
              element: <NotFound />,
            })}

            {renderMultiRoutes({
              path: "/wrong-approach",
              element: <WrongAproach />,
            })}
          </Routes>

          {!headerFooterHide && <Footer />}
        </InspireRouterPageAll>
      </InspireRouterContainer>
    </div>
  );
};

export default InspireRouter;
