import React from "react";
import styled from "@emotion/styled";
import {
  AWS_BUCKET_URL,
  EventGridBox,
  SectionTitle,
  EventButton,
} from "@components/events/winter-wonderland/WinterWonderland";
import { mq } from "@utils/media-query/mediaQuery";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

const Container = styled.div(() => ({
  background: "#F3F3F3",
  width: "100%",
  padding: "2rem",

  [mq("desktop")]: {
    padding: "4rem 5rem 5rem 5rem",
  },
}));

const Title = styled.div(() => ({
  textAlign: "center",
  fontWeight: 300,
}));

const Description = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem",
  margin: "2rem 0",
  textAlign: "center",
  fontSize: isJapanese ? "1.2rem" : "1.4rem",
  whiteSpace: "pre-wrap",

  [mq("desktop")]: {
    fontSize: "1.8rem",
    gap: "2rem",
  },
}));

const ButtonWrapper = styled.div(() => ({
  width: "100%",
  textAlign: "center",
  marginTop: "2.5rem",
}));

const ThirdSection = ({ intlFormat }) => {
  const arr = [
    {
      id: 1,
      image: "4_1.jpg",
    },
    {
      id: 2,
      image: "4_2.jpg",
    },
    {
      id: 3,
      image: "4_3.jpg",
    },
  ];

  return (
    <Container>
      <Title>
        <SectionTitle>{intlFormat("winter-section4-title-1")}</SectionTitle>

        <b>{intlFormat("winter-section4-date")}</b>

        <Description>
          <p>{intlFormat("winter-section4-title-2")}</p>

          <p>{intlFormat("winter-section4-title-3")}</p>
        </Description>

        <EventGridBox column="3">
          {arr.map((item) => {
            return (
              <img
                key={`${item.image}${item.id}`}
                src={`${AWS_BUCKET_URL}/${item.image}`}
                alt={`${item.image}${item.id}`}
                width="100%"
              />
            );
          })}
        </EventGridBox>

        <ButtonWrapper>
          <EventButton link="/splash-bay/facilities/splash-bay-dome" />
        </ButtonWrapper>
      </Title>
    </Container>
  );
};

export default ThirdSection;
