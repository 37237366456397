import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";

import {
  LOCAL_STORAGE_EXPIRE_TOKEN,
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_USER_ID,
} from "@utils/api-requests/static-data";

const LoginRouter = ({ children, login }) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    if (lang !== null) return `/${lang}`;
  }, [lang]);

  // CASE #1 When user is not logged in
  if (
    !login &&
    (localStorage.getItem(LOCAL_STORAGE_USER_ID) === null ||
      localStorage.getItem(LOCAL_STORAGE_USER_ID) === undefined)
  ) {
    // retun sign in page
    return children;
  }

  // CASE #2 When user is logged in
  if (
    login &&
    localStorage.getItem(LOCAL_STORAGE_EXPIRE_TOKEN) !== null &&
    localStorage.getItem(LOCAL_STORAGE_EXPIRE_TOKEN)
  ) {
    // return join momentum (sign up) page
    return children;
  }

  // CASE #3 When user is logged in & try to approach to "sign in" page
  if (
    !login &&
    localStorage.getItem(LOCAL_STORAGE_EXPIRE_TOKEN) !== null &&
    localStorage.getItem(LOCAL_STORAGE_EXPIRE_TOKEN)
  ) {
    return <Navigate to={`${baseUrl}/momentum/my-profile`} />;
  }

  // CASE #4 When user is not logged in & try to approach to "my profile" page
  if (
    login &&
    (localStorage.getItem(LOCAL_STORAGE_USER_ID) === null ||
      localStorage.getItem(LOCAL_STORAGE_USER_ID) === undefined)
  ) {
    return <Navigate to={`${baseUrl}`} />;
  }
};

export default LoginRouter;
