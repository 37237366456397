import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactQueryDevtools } from "react-query/devtools";
import { useQueryClient } from "react-query";

// swiper styling
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "@assets/fonts/fonts.css";
import SwiperStyle from "@assets/styles/LibraryStyles";
import enUSMsg from "@utils/lanugauge-settings/languages/en-US.json";
import koMsg from "@utils/lanugauge-settings/languages/ko.json";
import jaMsg from "@utils/lanugauge-settings/languages/ja.json";
import chHansMsg from "@utils/lanugauge-settings/languages/ch-hans.json";
import chHantMsg from "@utils/lanugauge-settings/languages/ch-hant.json";
import { useDefaultLanguage } from "@utils/lanugauge-settings/LanguageOptions";
import {
  LOCAL_STORAGE_EXPIRE_TOKEN,
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_REFRESH_TOKEN,
  QUERY_KEY_USER_LOGGED_IN,
} from "@utils/api-requests/static-data";

import AppTheme from "./theme";
import { InspireRouter } from "./routes";
import { useGetRedirectUrls } from "@utils/api-requests/GlobalRequests";
import GlobalStyles from "@assets/styles/GlobalStyles";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "@routes/ErrorBoundaryFallback";
import { useLogoutUser } from "@utils/api-requests/userRequests";
import { useInterval } from "./hooks/useInterval";

function App() {
  const { data: redirectUrls } = useGetRedirectUrls();

  const logout = useLogoutUser({ noRoute: true });

  const location = useLocation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const customMuiTheme = createTheme(AppTheme);

  const defaultLanguage = useDefaultLanguage();

  const locale =
    localStorage.getItem(LOCAL_STORAGE_LANGUAGE) !== null
      ? localStorage.getItem(LOCAL_STORAGE_LANGUAGE)
      : defaultLanguage;

  const htmlElement = document.querySelector("html");

  useEffect(() => {
    // Redirect Urls
    if (redirectUrls) {
      redirectUrls?.forEach((url) => {
        if (
          window.location.href === url.field_request_url &&
          url.field_is_enabled === "1"
        ) {
          window.location.href = url.field_destination_url;
        }
      });
    }
  }, [redirectUrls, location]);

  // Change the 'lang' attribute to site locale
  htmlElement.setAttribute("lang", locale);
  const bodyElement = document.querySelector("body");

  if (locale === "ja") {
    bodyElement.className = "body-" + locale;
  } else {
    document.body.classList.remove("body-ja");
  }

  const messages = {
    en: enUSMsg,
    ko: koMsg,
    "zh-hans": chHansMsg,
    "zh-hant": chHantMsg,
    ja: jaMsg,
  }[locale];

  useEffect(() => {
    sessionStorage.setItem("signupAction", JSON.stringify(true));
  }, []);

  useEffect(() => {
    // NOTE: Check if refresh token is in the local storage, if not, log out
    if (
      localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) !== null ||
      localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN)
    ) {
      queryClient.setQueryData(QUERY_KEY_USER_LOGGED_IN, true);
    } else {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    if (localStorage.getItem(LOCAL_STORAGE_EXPIRE_TOKEN)) {
      // NOTE: log out user when there is no action for 25 minutes
      const currentTime = new Date().getTime();
      const expireTime = localStorage.getItem(LOCAL_STORAGE_EXPIRE_TOKEN);

      if (expireTime && currentTime > parseInt(expireTime, 10)) {
        logout();
      }
    }
  }, 1000);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === LOCAL_STORAGE_LANGUAGE) {
        // Only works when user changes language of the site with multiple tabs opend
        const changedValue = event.newValue;

        navigate(`${changedValue}`);

        window.location.reload(false);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  return (
    defaultLanguage && (
      <IntlProvider
        locale={locale}
        messages={messages}
        defaultLocale={defaultLanguage}
      >
        <GlobalStyles />

        {/* swiper custom style */}
        <SwiperStyle />

        <ReactQueryDevtools initialIsOpen={false} />

        <ThemeProvider theme={customMuiTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
              <InspireRouter />
            </ErrorBoundary>
          </LocalizationProvider>
        </ThemeProvider>
      </IntlProvider>
    )
  );
}

export default App;
