import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import Snowfall from "react-snowfall";

import {
  FourBoxContainer,
  FourBoxImage,
  FourBoxImageAndText,
  FourBoxItem,
  FourBoxItemMask,
  FourBoxTitleTextContainer,
  FourBoxTitleText,
  FourBoxText,
  KeyVisual,
  OffersImage,
  WonderlandSection,
  WonderlandContainer,
  WonderlandImage,
  OffersButton,
  BookOrView,
  WonderlandDescription,
  WonderlandCustomText,
  ThreeBoxContainer,
  ThreeBoxItem,
  EventButton,
  CulinaryGrid,
  CulinaryGridSection,
  GridItem,
  WideItem,
  WonderlandSnowContainer,
  WonderlandContentContainer,
  SwiperBox,
  WonderlandMainEventText,
  WonderlandMainEvenTitleText,
  WonderlandMainEvenDateText,
  ItemMask,
  WonderPhotoTitle,
  WonderlandEventText,
  WonderlandEventDate,
  EndLessJourneryText,
  SoldOutText,
} from "./WonderlandStyling";
import ArenaSwiper from "./ArenaSwiper";

import wonderlandJson from "./WonderlandArena.json";
import SplashSwiper from "./SplashSwiper";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";
import { Link } from "react-router-dom";
import { NormalOverlay } from "@components/item/Overlays";
import { NormalOverlayContainer } from "@components/item/ItemStyling";

const WonderlandFinal = () => {
  const intl = useIntl();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  let agilysysLang;

  switch (lang) {
    case "zh-hans":
      agilysysLang = "ZH-CN";
      break;
    case "zh-hant":
      agilysysLang = "ZH-TW";
      break;
    default:
      agilysysLang = lang;
      break;
  }

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  const { arenaEn, arenaKo, arenaJa, arenaZh } = wonderlandJson;

  let arenaList;

  switch (lang) {
    case "en":
      arenaList = arenaEn;
      break;
    case "ko":
      arenaList = arenaKo;
      break;
    case "ja":
      arenaList = arenaJa;
      break;
    default:
      arenaList = arenaZh;
      break;
  }

  const deviceWidth = useMemo(() => {
    return window.innerWidth;
  }, [window.innerWidth]);

  const intlFormat = (id) => {
    return intl.formatMessage({
      id: id,
    });
  };

  const bookOrView = (type, bColor, color) => {
    switch (type) {
      case "book":
        return (
          <BookOrView bColor={bColor} color={color}>
            {intlFormat("wonderBook")} &gt;
          </BookOrView>
        );
      case "view":
        return (
          <BookOrView bColor={bColor} color={color}>
            {intlFormat("wonderViewmore")} &gt;
          </BookOrView>
        );
    }
  };

  return (
    <WonderlandContainer>
      <KeyVisual>
        <img
          src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/1.jpg"
          alt="key visual"
          width={"100%"}
        />
      </KeyVisual>

      <WonderlandSnowContainer>
        <Snowfall color="white" snowflakeCount={1000} />
        <WonderlandContentContainer>
          {/* Welcome to Wonderland  */}
          <WonderlandSection>
            <WonderlandDescription>
              <WonderlandCustomText size={4.5} weight={100}>
                {intlFormat("welcomeTo")}
              </WonderlandCustomText>
              <WonderlandCustomText size={4.5} weight={800} padding="0 0 2rem">
                {intlFormat("wonderland")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={200}>
                {intlFormat("welcomeDesc1-1")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={200}>
                {intlFormat("welcomeDesc1-2")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={200}>
                {intlFormat("welcomeDesc1-3")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={800} padding="2rem 0">
                {intlFormat("welcomeDate")}
              </WonderlandCustomText>
            </WonderlandDescription>

            <FourBoxContainer>
              <FourBoxItem>
                <WonderlandImage
                  src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/2_1.jpg"
                  alt="event1"
                />
                <FourBoxItemMask mask={true}>
                  <WonderlandMainEventText>
                    {intlFormat("wonderMain1-1")}
                  </WonderlandMainEventText>
                  <WonderlandMainEvenTitleText>
                    {intlFormat("wonderMain1-2")}
                  </WonderlandMainEvenTitleText>
                  <WonderlandMainEvenDateText>
                    {intlFormat("wonderMain1-3")}
                  </WonderlandMainEvenDateText>
                </FourBoxItemMask>
              </FourBoxItem>
              <FourBoxItem>
                <WonderlandImage
                  src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/2_2.jpg"
                  alt="event2"
                />
                <FourBoxItemMask mask={true}>
                  <WonderlandMainEventText>
                    {intlFormat("wonderMain2-1")}
                  </WonderlandMainEventText>
                  <WonderlandMainEvenTitleText>
                    {intlFormat("wonderMain2-2")}
                  </WonderlandMainEvenTitleText>
                  <WonderlandMainEvenDateText>
                    {intlFormat("wonderMain2-3")}
                  </WonderlandMainEvenDateText>
                </FourBoxItemMask>
              </FourBoxItem>
              <FourBoxItem>
                <WonderlandImage
                  src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/2_3.jpg"
                  alt="event3"
                />
                <FourBoxItemMask mask={true}>
                  <WonderlandMainEventText>
                    {intlFormat("wonderMain3-1")}
                  </WonderlandMainEventText>
                  <WonderlandMainEvenTitleText>
                    {intlFormat("wonderMain3-2-1")} <br />{" "}
                    {intlFormat("wonderMain3-2-2")}
                  </WonderlandMainEvenTitleText>
                  <WonderlandMainEvenDateText>
                    {intlFormat("wonderMain3-3")}
                  </WonderlandMainEvenDateText>
                </FourBoxItemMask>
              </FourBoxItem>
              <FourBoxItem>
                <WonderlandImage
                  src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/2_4.jpg"
                  alt="event4"
                />
                <FourBoxItemMask mask={true}>
                  <WonderlandMainEventText>
                    {intlFormat("wonderMain4-1")}
                  </WonderlandMainEventText>
                  <WonderlandMainEvenTitleText>
                    {intlFormat("wonderMain4-2-1")} <br />{" "}
                    {intlFormat("wonderMain4-2-2")}
                  </WonderlandMainEvenTitleText>
                  <WonderlandMainEvenDateText>
                    {intlFormat("wonderMain4-3")}
                  </WonderlandMainEvenDateText>
                </FourBoxItemMask>
              </FourBoxItem>
            </FourBoxContainer>
          </WonderlandSection>

          {/* Special Offers */}
          <WonderlandSection>
            <WonderlandDescription>
              <WonderlandCustomText size={4.5} weight={100}>
                {intlFormat("wonderOffer")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={300} padding="1.5rem 0">
                {intlFormat("wonderOfferDesc")}
              </WonderlandCustomText>
            </WonderlandDescription>
            <FourBoxContainer>
              <FourBoxImageAndText>
                <FourBoxImage>
                  <OffersImage
                    src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/3_1.jpg"
                    alt="offer1"
                  />
                  <FourBoxTitleTextContainer>
                    <FourBoxTitleText>
                      <div>{intlFormat("wonderOfferTextOnImg1-1")}</div>
                      <div>{intlFormat("wonderOfferTextOnImg1-2")}</div>
                      <div>{intlFormat("wonderOfferTextOnImg1-3")}</div>
                      <div>{intlFormat("wonderOfferTextOnImg1-4")}</div>
                    </FourBoxTitleText>
                  </FourBoxTitleTextContainer>
                </FourBoxImage>
                <FourBoxText>
                  <div>
                    <WonderlandCustomText
                      size={2.8}
                      weight="bold"
                      mt={1}
                      id="winter-d-c-s"
                    >
                      {intlFormat("wonderOffer1")}
                    </WonderlandCustomText>

                    <WonderlandCustomText size={1.6} mt={1}>
                      {intlFormat("wonderOfferPack1")}
                    </WonderlandCustomText>
                  </div>

                  <OffersButton margin="3rem 0 0">
                    <Link
                      to={`https://book.rguest.sg/onecart/wbe/calendar/20044/InspireEntertainmentResort/15CDDB?langCode=${agilysysLang?.toUpperCase()}`}
                    >
                      {bookOrView("book", "#725F85")}
                    </Link>

                    <Link
                      to={`${baseUrl}/offers/detail/yearend-momentum-member-special-promotion`}
                    >
                      {bookOrView("view")}
                    </Link>
                  </OffersButton>
                </FourBoxText>
              </FourBoxImageAndText>

              <FourBoxImageAndText>
                <FourBoxImage>
                  <OffersImage
                    src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/3_2.jpg"
                    alt="offer2"
                  />
                  <FourBoxTitleTextContainer>
                    <FourBoxTitleText isBlack={true}>
                      <div>{intlFormat("wonderOfferTextOnImg2-1")}</div>
                      <div>{intlFormat("wonderOfferTextOnImg2-2")}</div>
                      <div>{intlFormat("wonderOfferTextOnImg2-3")}</div>
                    </FourBoxTitleText>
                  </FourBoxTitleTextContainer>
                </FourBoxImage>

                <FourBoxText>
                  <div>
                    <WonderlandCustomText
                      size={2.8}
                      weight="bold"
                      mt={1}
                      id="santas-s-s"
                    >
                      {intlFormat("wonderOffer2")}
                    </WonderlandCustomText>

                    <WonderlandCustomText size={1.6} mt={1}>
                      {intlFormat("wonderOfferPack2")}
                    </WonderlandCustomText>
                  </div>

                  <OffersButton margin="3rem 0 0">
                    <Link
                      to={`https://book.rguest.sg/onecart/wbe/calendar/20044/InspireEntertainmentResort/13SANTA?langCode=${agilysysLang?.toUpperCase()}`}
                    >
                      {bookOrView("book", "#725F85")}
                    </Link>
                    <Link to={`${baseUrl}/offers/detail/santas-surprise-suite`}>
                      {bookOrView("view")}
                    </Link>
                  </OffersButton>
                </FourBoxText>
              </FourBoxImageAndText>

              <FourBoxImageAndText>
                <FourBoxImage>
                  <div style={{ position: "relative" }}>
                    <OffersImage
                      src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/3_3.jpg"
                      alt="offer3"
                    />

                    <NormalOverlayContainer opacity={0.3} rgb={"0, 0, 0"}>
                      <SoldOutText>Sold Out</SoldOutText>
                    </NormalOverlayContainer>
                  </div>

                  <FourBoxTitleTextContainer>
                    <FourBoxTitleText isBlack={true}>
                      <div>{intlFormat("wonderOfferTextOnImg3-1")}</div>
                      <div>{intlFormat("wonderOfferTextOnImg3-2")}</div>
                      <div>{intlFormat("wonderOfferTextOnImg3-3")}</div>
                    </FourBoxTitleText>
                  </FourBoxTitleTextContainer>
                </FourBoxImage>

                <FourBoxText>
                  <div>
                    <WonderlandCustomText size={2.3} weight="bold" mt={1}>
                      <div>{intlFormat("wonderMomentumExclusive")}</div>
                    </WonderlandCustomText>

                    <WonderlandCustomText
                      size={2.8}
                      weight="bold"
                      color="#460369"
                      id="year-end-h-p"
                    >
                      {intlFormat("wonderOffer3")}
                    </WonderlandCustomText>

                    <WonderlandCustomText
                      size={1.6}
                      padding="0 2rem 0 0"
                      mt={1}
                    >
                      <div>{intlFormat("wonderOfferPack3")}</div>
                    </WonderlandCustomText>
                  </div>

                  <OffersButton margin="3rem 0 0">
                    <Link
                      style={{ pointerEvents: "none" }}
                      to={`https://book.rguest.sg/onecart/wbe/calendar/20044/InspireEntertainmentResort/15MMTVC?langCode=${agilysysLang?.toUpperCase()}`}
                    >
                      {bookOrView("book", "#5B5B5B")}
                    </Link>

                    <Link
                      style={{ pointerEvents: "none" }}
                      to={`${baseUrl}/offers/detail/momentum-members-only-yearend-holiday-package`}
                    >
                      {bookOrView("view", "#5B5B5B")}
                    </Link>
                  </OffersButton>
                </FourBoxText>
              </FourBoxImageAndText>

              <FourBoxImageAndText>
                <FourBoxImage>
                  <OffersImage
                    src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/3_4.jpg"
                    alt="offer4"
                  />
                  <FourBoxTitleTextContainer>
                    <FourBoxTitleText>
                      <div>{intlFormat("wonderOfferTextOnImg4-1")}</div>
                      <div>{intlFormat("wonderOfferTextOnImg4-2")}</div>
                      <div>{intlFormat("wonderOfferTextOnImg4-3")}</div>
                    </FourBoxTitleText>
                  </FourBoxTitleTextContainer>
                </FourBoxImage>

                <FourBoxText>
                  <div>
                    <WonderlandCustomText size={2.3} weight="bold" mt={1}>
                      {intlFormat("wonderMomentumExclusive")}
                    </WonderlandCustomText>

                    <WonderlandCustomText
                      size={2.8}
                      weight="bold"
                      color="#460369"
                      id="taste-the-n-y-p"
                    >
                      {intlFormat("wonderOffer4")}
                    </WonderlandCustomText>

                    <WonderlandCustomText size={1.6} mt={1}>
                      {intlFormat("wonderOfferPack4")}
                    </WonderlandCustomText>
                  </div>

                  <OffersButton margin="3rem 0 0">
                    <Link
                      to={`https://book.rguest.sg/onecart/wbe/calendar/20044/InspireEntertainmentResort/15MMTCD?langCode=${agilysysLang?.toUpperCase()}`}
                    >
                      {bookOrView("book", "#725F85")}
                    </Link>

                    <Link
                      to={`${baseUrl}/offers/detail/momentum-members-only-2025-taste-the-newyear-package`}
                    >
                      {bookOrView("view")}
                    </Link>
                  </OffersButton>
                </FourBoxText>
              </FourBoxImageAndText>
            </FourBoxContainer>
          </WonderlandSection>

          {/* Wonderland Event */}
          <WonderlandSection>
            <WonderlandDescription>
              <WonderlandCustomText size={4.5} weight={200}>
                {intlFormat("wonderEvent")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={300} mt={2}>
                {intlFormat("wonderEventDesc")}
              </WonderlandCustomText>
            </WonderlandDescription>
            <ThreeBoxContainer>
              <ThreeBoxItem>
                <WonderlandImage src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/4_1.jpg" />
                <ItemMask>
                  <div style={{ color: "#fff" }}>
                    <WonderlandEventText id="holiday-wish-w-s">
                      {intlFormat("wonderEventTitle1-1")}
                    </WonderlandEventText>
                    <WonderlandEventText>
                      {intlFormat("wonderEventTitle1-2")}
                    </WonderlandEventText>
                    <WonderlandEventDate>
                      {intlFormat("wonderEventTitle1-3")}
                    </WonderlandEventDate>
                  </div>
                  <EventButton>
                    <Link
                      to={`${baseUrl}/offers/detail/holiday-wishes-with-santa`}
                    >
                      {bookOrView("view", "#fff", "#000")}
                    </Link>
                  </EventButton>
                </ItemMask>
              </ThreeBoxItem>
              <ThreeBoxItem>
                <WonderlandImage src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/4_2.jpg" />
                <ItemMask>
                  <div style={{ color: "white" }}>
                    <WonderlandEventText id="auroras-c-m">
                      {intlFormat("wonderEventTitle2-1")}
                    </WonderlandEventText>
                    <WonderlandEventText>
                      {intlFormat("wonderEventTitle2-2")}
                    </WonderlandEventText>
                    <WonderlandEventDate>
                      {intlFormat("wonderEventTitle2-3")}
                    </WonderlandEventDate>
                  </div>
                  <EventButton>
                    <Link
                      to={`${baseUrl}/offers/detail/auroras-christmas-melodies`}
                    >
                      {bookOrView("view", "#fff", "#000")}
                    </Link>
                  </EventButton>
                </ItemMask>
              </ThreeBoxItem>
              <ThreeBoxItem>
                <WonderlandImage src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/4_3.jpg" />
                <ItemMask>
                  <div style={{ color: "white" }}>
                    <WonderlandEventText lang={lang} id="endless-m-c">
                      {intlFormat("wonderEventTitle3-1")}
                    </WonderlandEventText>
                    <WonderlandEventText lang={lang}>
                      {intlFormat("wonderEventTitle3-2")}
                    </WonderlandEventText>
                    <WonderlandEventDate>
                      {intlFormat("wonderEventTitle3-3")}
                    </WonderlandEventDate>
                  </div>
                  <EventButton>
                    <Link
                      to={`${baseUrl}/offers/detail/inspires-endless-midnight-countdown`}
                    >
                      {bookOrView("view", "#fff", "#000")}
                    </Link>
                  </EventButton>
                </ItemMask>
              </ThreeBoxItem>
            </ThreeBoxContainer>
          </WonderlandSection>

          {/*  SplashBay is Back! */}
          <WonderlandSection>
            <WonderlandDescription>
              <WonderlandCustomText size={4.5} weight={200}>
                {intlFormat("wonderSplash")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={300} padding="1.5rem 0 0">
                {intlFormat("wonderSplashDesc1")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={300}>
                {intlFormat("wonderSplashDesc2")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={500} mt={2}>
                {intlFormat("wonderSplashDesc3")}
              </WonderlandCustomText>
            </WonderlandDescription>
            <SwiperBox>
              <SplashSwiper list={["5_1", "5_2", "5_3"]} />
            </SwiperBox>
          </WonderlandSection>

          {/* Endless Culinary Journey */}
          <WonderlandSection>
            <WonderlandDescription>
              <WonderlandCustomText size={4.5} weight={200}>
                {intlFormat("wonderCulinary")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={300} mt={2}>
                {intlFormat("wonderCulinaryDesc")}
              </WonderlandCustomText>
            </WonderlandDescription>
            <CulinaryGrid>
              <CulinaryGridSection grid="a">
                <GridItem>
                  <WonderlandImage src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/6_1.jpg" />
                  <ItemMask>
                    <div>
                      <EndLessJourneryText
                        size={4}
                        weight="bold"
                        id="specialty-c-c"
                      >
                        {intlFormat("wonderCulinary1-1")}
                      </EndLessJourneryText>
                      <EndLessJourneryText size={4} weight="bold">
                        {intlFormat("wonderCulinary1-2")}
                      </EndLessJourneryText>
                    </div>
                    <EventButton>
                      <Link
                        to={`${baseUrl}/offers/detail/inspires-specialty-christmas-cakes`}
                      >
                        {bookOrView("view", "#fff", "#000")}
                      </Link>
                    </EventButton>
                  </ItemMask>
                </GridItem>
              </CulinaryGridSection>
              <CulinaryGridSection grid="b">
                <GridItem>
                  <WonderlandImage src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/6_2.jpg" />
                  <ItemMask>
                    <div>
                      <EndLessJourneryText
                        size={4}
                        weight="bold"
                        id="afternoon-t-s-b"
                      >
                        {intlFormat("wonderCulinary2-1")}
                      </EndLessJourneryText>
                      <EndLessJourneryText size={4} weight="bold">
                        {intlFormat("wonderCulinary2-2")}
                      </EndLessJourneryText>
                    </div>
                    <EventButton>
                      <Link
                        to={`${baseUrl}/offers/detail/inspire-afternoon-tea-strawberry-bliss`}
                      >
                        {bookOrView("view", "#fff", "#000")}
                      </Link>
                    </EventButton>
                  </ItemMask>
                </GridItem>
              </CulinaryGridSection>
              <CulinaryGridSection grid="c">
                <GridItem>
                  <WonderlandImage src="https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/6_3.jpg" />
                  <ItemMask>
                    <div>
                      <EndLessJourneryText
                        size={4}
                        weight="bold"
                        id="holiday-d-p"
                      >
                        {intlFormat("wonderCulinary3-1")}
                      </EndLessJourneryText>
                      <EndLessJourneryText size={4} weight="bold">
                        {intlFormat("wonderCulinary3-2")}
                      </EndLessJourneryText>
                    </div>
                    <EventButton>
                      <Link
                        to={`${baseUrl}/offers/detail/inspires-holiday-promotions`}
                      >
                        {bookOrView("view", "#fff", "#000")}
                      </Link>
                    </EventButton>
                  </ItemMask>
                </GridItem>
              </CulinaryGridSection>
              <CulinaryGridSection grid="d">
                <WideItem>
                  <WonderlandImage
                    src={`https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/6_${deviceWidth > 480 ? 4 : 5}.jpg`}
                  />
                  <ItemMask isCenter={true}>
                    <div>
                      <WonderPhotoTitle>
                        <EndLessJourneryText
                          size={4}
                          weight="bold"
                          id="momentum-b"
                        >
                          {intlFormat("wonderCulinary4-1")}
                        </EndLessJourneryText>
                        <EndLessJourneryText size={4} weight="bold">
                          {intlFormat("wonderCulinary4-2")}
                        </EndLessJourneryText>
                      </WonderPhotoTitle>
                      <WonderlandCustomText size={2} mt={2}>
                        {intlFormat("wonderCulinary4-3")}
                      </WonderlandCustomText>
                    </div>
                    <EventButton>
                      <Link
                        to={`${baseUrl}/offers/detail/inspire-winter-events-momentum-special-benefits`}
                      >
                        {bookOrView("view", "#fff", "#000")}
                      </Link>
                    </EventButton>
                  </ItemMask>
                </WideItem>
              </CulinaryGridSection>
            </CulinaryGrid>
          </WonderlandSection>

          {/* Arena Performance */}
          <WonderlandSection>
            <WonderlandDescription>
              <WonderlandCustomText size={4.5} weight={200}>
                {intlFormat("wonderArena")}
              </WonderlandCustomText>
              <WonderlandCustomText size={2} weight={300} mt={1}>
                {intlFormat("wonderArenaDesc")}
              </WonderlandCustomText>
            </WonderlandDescription>
            <SwiperBox>
              <ArenaSwiper view={3} list={arenaList} bookOrView={bookOrView} />
            </SwiperBox>
          </WonderlandSection>

          {/* Photo Contest */}
          {(lang === "ko" || lang === "en") && (
            <WonderlandSection>
              <WonderlandDescription>
                <WonderPhotoTitle>
                  <WonderlandCustomText size={4.5} weight={200}>
                    {intlFormat("wonderInspireWinter")}
                  </WonderlandCustomText>
                  <WonderlandCustomText size={4.5} weight={200}>
                    {intlFormat("wonderPhotoContest")}
                  </WonderlandCustomText>
                </WonderPhotoTitle>
                <WonderlandCustomText size={2} weight={300} mt={2}>
                  {intlFormat("wonderPhotoDesc1")}
                </WonderlandCustomText>
                <WonderlandCustomText size={2} weight={300}>
                  {intlFormat("wonderPhotoDesc2")}
                </WonderlandCustomText>
                <WonderlandCustomText size={2} weight={500} mt={2}>
                  {intlFormat("wonderPhotoDesc3")}
                </WonderlandCustomText>
              </WonderlandDescription>
              <WideItem mt="2rem">
                <WonderlandImage
                  src={`https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/${deviceWidth > 480 ? 8 : 89}.jpg`}
                />
                <ItemMask style={{ background: "none", textAlign: "center" }}>
                  <div>
                    <WonderlandCustomText size={2.3} weight={800}>
                      {intlFormat("wonderPhotoRequired")}
                    </WonderlandCustomText>
                    <WonderlandCustomText size={2.3} weight={800}>
                      {intlFormat("wonderPhotoHashtag")}
                    </WonderlandCustomText>
                  </div>
                  <EventButton>
                    <Link
                      to={`https://www.instagram.com/inspirekorea/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {bookOrView("view", "#fff", "#725F85")}
                    </Link>
                  </EventButton>
                </ItemMask>
              </WideItem>
            </WonderlandSection>
          )}
        </WonderlandContentContainer>
      </WonderlandSnowContainer>
    </WonderlandContainer>
  );
};

export default WonderlandFinal;
