export const MOMENTUM_BENEFIT_CATEGORY = [
  { name: "Hotel", field: "hotel_benefits" },
  { name: "Food and Beverage", field: "dining_benefits" },
  { name: "Shopping", field: "shoppng_benefits" },
  { name: "Attractions & Entertainment", field: "entertainment_benfits" },
  { name: "Transportation", field: "parking_benefits" },
  { name: "Loyalty", field: "personal_privileges" },
];

export const MAIN_AD_BANNER_HEIGHT = "20rem";

export const SCROLL_INTO_VIEW_TIMER = 2000;

export const QUERY_KEY_USER_LOGGED_IN = "userLoggedIn";
export const LOCAL_STORAGE_LANGUAGE = "INSPIRE-language";
export const LOCAL_STORAGE_USER_ID = "INSPIRE-user";
export const LOCAL_STORAGE_VISITED = "INSPIRE-home-visited";
export const LOCAL_STORAGE_USER = "INSPIRE-user-data";

// NOTE: Strivacity Local storage
export const LOCAL_STORAGE_REFRESH_TOKEN = "INSPIRE-refresh-token";
export const LOCAL_STORAGE_EXPIRE_TOKEN = "INSPIRE-token-expire-at";
export const LOCAL_STORAGE_STRIVACITY_ACCESS_TOKEN =
  "INSPIRE-strivacity-AccessToken";

// NOTE: Strivacity Variables
export const STRIVACITY_BRAND_DOMAIN =
  process.env.REACT_APP_STRIVACITY_ENDPOINT;
export const STRIVACITY_CLIENT_ID = process.env.REACT_APP_STRIVACITY_CLIENT_ID;
export const STRIVACITY_API_KEY = process.env.REACT_APP_STRIVACITY_API_KEY;

export const STRIVACITY_ADMIN_Base_64 = btoa(
  `${process.env.REACT_APP_STRIVACITY_ADMIN_CLIENT_ID}:${process.env.REACT_APP_STRIVACITY_ADMIN_CLIENT_SECRET}`,
);

export const STRIVACITY_Base_64 = btoa(
  `${process.env.REACT_APP_STRIVACITY_CLIENT_ID}:${process.env.REACT_APP_STRIVACITY_CLIENT_SECRET}`,
);

export const STRIVACITY_RESPONSE_TYPE = "code";

export const STRIVACITY_SCOPE =
  "openid offline_access family_name given_name date_of_birth country email phone_number lifestyle_id address streetAddressLine2";

// If there is no lang code on the url, return "en"
const urlLang =
  window.location.pathname.split("/")?.[1] === ""
    ? "en"
    : window.location.pathname.split("/")?.[1];

export const STRIVACITY_STATE = "randomString";

export const STRIVACITY_NONCE = "nonce-for-inspire";
export const STRIVACITY_REDIRECT = `${new URL(window.location.href).origin}/${urlLang}/my-profile`;

export const EXPIRE_DURATION = 25; // token expired after 25 minute

// NOTE: consent
export const MARKETING_CONSENT_ID = "1001";
export const PERSONAL_CONSENT_ID =
  process.env.REACT_APP_STRIVACITY_PERSONAL_CONSENT_ID;
