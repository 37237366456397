import React from "react";
import styled from "@emotion/styled";
import {
  AWS_BUCKET_URL,
  EventGridBox,
  SectionTitle,
  SectionWrapper,
  EventButton,
} from "@components/events/winter-wonderland/WinterWonderland";
import { mq } from "@utils/media-query/mediaQuery";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

const Title = styled.div(() => ({
  marginBottom: "2.5rem",
  textAlign: "center",
  fontWeight: 300,
  fontSize: isJapanese ? "1.2rem" : "1.4rem",

  [mq("desktop")]: {
    fontSize: "1.8rem",
  },
}));

const EventItem = styled.div(({ theme }) => ({
  padding: "1.5rem",
  background: theme.color.white,

  [mq("desktop")]: {
    padding: "2.5rem",
  },
}));

const Description = styled.div(({ isChinese }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.4rem 0",
  fontWeight: 700,
  paddingTop: "1.6rem",

  header: {
    fontSize: "1.6rem",
  },

  p: {
    fontSize: "1.2rem",
    fontWeight: 400,
  },

  span: {
    color: "#460369",
  },

  [mq("tablet")]: {
    header: {
      minHeight: isChinese ? "" : "4rem",
    },
  },

  [mq("desktop")]: {
    paddingTop: "2.4rem",

    header: {
      minHeight: isChinese ? "" : "4rem",
      fontSize: "2rem",
    },

    p: {
      fontSize: "1.6rem",
      fontWeight: 400,
    },
  },
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1.4rem",
  width: "100%",
  marginTop: "1.6rem",
  color: "#68507C",
}));

const SecondSection = ({ intlFormat }) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const IS_CHINESE = lang === "zh-hans" || lang === "zh-hant";

  const arr = [
    // TODO: uncomment when splashbay is ready
    // {
    //   id: 1,
    //   image: "3_1.jpg",
    //   bookLink: "18INCK2",
    //   viewLink: "/offers/detail/splashbay-and-breakfast-package-session2",
    // },
    // {
    //   id: 2,
    //   image: "3_2.jpg",
    //   title: "Splash Bay Room Package",
    //   date: "Stay Period | Jan 15, 2025 - Apr 30, 2025",
    //   price: "256,000 KRW ~",
    //   bookLink: "17INSP1",
    //   viewLink: "/offers/detail/new-splash-bay-room-package",
    // },
    {
      id: 3,
      image: "3_3.jpg",
      title: "Chef’s Kitchen Breakfast Package (Session 2)",
      date: "Stay Period | - Apr 30, 2025",
      price: "316,000 KRW ~ ",
      bookLink: "12INCK2",
      viewLink: "/offers/detail/chefs-kitchen-breakfast-package-session2",
    },
    {
      id: 4,
      image: IS_CHINESE ? "3_4_chinese.jpg" : "3_4.jpg",
      title: "2025 Lunar New Year : In-Room Dining Special",
      date: "Stay Period | Jan 13, 2024 - Jan 31, 2025",
      price: "303,000 KRW ~",
      bookLink: "14INIRD",
      customLink: IS_CHINESE
        ? `https://book.rguest.sg/onecart/wbe/offers/20044/InspireEntertainmentResort?offerCode=14inhp`
        : undefined,
      viewLink: IS_CHINESE
        ? "/offers/detail/2025-lunar-new-year-hongpan-special"
        : "/offers/detail/2025-lunar-new-year-IRD-special",
    },
  ];

  return (
    <SectionWrapper>
      <Title>
        <SectionTitle>{intlFormat("winter-section3-title-1")}</SectionTitle>

        <p>{intlFormat("winter-section3-title-2")}</p>
      </Title>

      <EventGridBox>
        {arr.map((item) => {
          return (
            <EventItem key={item.id}>
              <img
                src={`${AWS_BUCKET_URL}/${item.image}`}
                alt={item.title}
                width="100%"
              />

              <Description isChinese={IS_CHINESE}>
                <header>
                  {intlFormat(`winter-section3-content${item.id}-1`)}
                </header>

                <p>{intlFormat(`winter-section3-content${item.id}-2`)}</p>

                <span>{intlFormat(`winter-section3-content${item.id}-3`)}</span>
              </Description>

              <ButtonWrapper>
                <EventButton
                  primary
                  rguestLink={item.bookLink}
                  buttonName={intlFormat("lang-book-now")}
                  customLink={item.customLink ? item.customLink : undefined}
                />

                <EventButton link={item.viewLink} />
              </ButtonWrapper>
            </EventItem>
          );
        })}
      </EventGridBox>
    </SectionWrapper>
  );
};

export default SecondSection;
