import React, { useCallback } from "react";

import { useIntl } from "react-intl";
import {
  MomentumModalButton,
  MomentumModalDesc,
  MomentumModalTitle,
  RoomReservationModalContainer,
  RoomReservationModalRight,
} from "@components/modal/ModalStyling";

import { DangerouslyHtml } from "@components/item";
import { SecondaryButton } from "@components/buttons";
import { CloseText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { CommonModal } from "..";
import { useGetContent } from "@utils/api-requests/GlobalRequests";
import ChangePersonalForm from "./ChangePersonalForm";

const ChangePersonalModal = ({
  openModal,
  setOpenModal,
  complete,
  setComplete,
  userData,
  hideClose,
  profileConsent,
}) => {
  const intl = useIntl();

  const { data: marketingModal } = useGetContent("marketingModal");

  const handleClose = useCallback(() => {
    setOpenModal(false);

    setComplete(false);
  }, [setComplete, setOpenModal]);

  return (
    <CommonModal
      modalName={intl.formatMessage({
        id: "lang-mail-opt-label",
      })}
      openModal={openModal}
      setOpenModal={setOpenModal}
      hideClose={hideClose}
      noPadding
      noHeader
    >
      <RoomReservationModalContainer>
        <RoomReservationModalRight>
          <MomentumModalTitle>
            {complete
              ? marketingModal?.field_thank_you_title
              : marketingModal?.field_modal_title}
          </MomentumModalTitle>

          {complete && (
            <MomentumModalDesc>
              <DangerouslyHtml
                value={marketingModal?.field_thank_you_description}
              />
            </MomentumModalDesc>
          )}

          {complete ? (
            <>
              <SecondaryButton
                buttonEvent={handleClose}
                customstyle={MomentumModalButton}
                buttonColor="white"
              >
                <CloseText />
              </SecondaryButton>
            </>
          ) : (
            <>
              <ChangePersonalForm
                complete={complete}
                setComplete={setComplete}
                modalContent={marketingModal}
                userData={userData}
                profileConsent={profileConsent}
                successMessage={marketingModal?.field_thank_you_description}
              />
            </>
          )}
        </RoomReservationModalRight>
      </RoomReservationModalContainer>
    </CommonModal>
  );
};

export default ChangePersonalModal;
