import styled from "@emotion/styled";
import { mq, mqMax, mqx } from "@utils/media-query/mediaQuery";
import { Link } from "react-router-dom";
import {
  isJapanese,
  isKorean,
} from "@utils/lanugauge-settings/LanguageOptions";

// STYLE: BorderButton.jsx
export const borderButtonStyles = (
  theme,
  { small, isDesktop, buttonColor, customstyle },
) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1rem",
  width: "100%",
  maxWidth: isDesktop ? "40rem" : "36rem",
  height: small ? "4.2rem" : isDesktop ? "7.2rem" : "5.6rem",
  minHeight: small ? "4.2rem" : isDesktop ? "7.2rem" : "5.6rem",
  padding: "0 1.6rem",
  textAlign: "center",

  borderRadius: "1.5rem",
  letterSpacing: "0.3rem",
  fontSize: small ? "inherit" : "1.6rem",
  fontWeight: theme.fontWeight.bold,

  textTransform: "uppercase",
  wordSpacing: "0.2rem",
  fontFamily: theme.fontFamily.primaryFont,

  [mqx("desktop")]: {
    padding: "0 2rem",
    fontSize: small ? "inherit" : "1.8rem",
  },

  ...customstyle,
});

export const StyledBorderButton = styled.button(({ theme, buttoncolor }) => ({
  color:
    buttoncolor === "primary" ? theme.color.primary_color : theme.color.white,
  border:
    buttoncolor === "primary"
      ? `1px solid ${theme.color.primary_color}`
      : `1px solid ${theme.color.white}`,

  ":hover": {
    background: theme.color.primary_color,
    color: theme.color.white,
    border: `1px solid ${theme.color.primary_color}`,
  },
}));

export const StyledBorderLink = styled(Link)(({ theme, buttoncolor }) => ({
  color:
    buttoncolor === "primary" ? theme.color.primary_color : theme.color.white,
  border:
    buttoncolor === "primary"
      ? `1px solid ${theme.color.primary_color}`
      : `1px solid ${theme.color.white}`,

  ":hover": {
    background: theme.color.primary_color,
    color: theme.color.white,
    border: `1px solid ${theme.color.primary_color}`,
  },
}));

// STYLE: PrimaryButton.jsx
export const primaryButtonStyles = (
  theme,
  { customstyle, isDesktop, buttonColor, detailButton, disabled },
) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1rem",
  width: "100%",
  maxWidth: isDesktop ? "40rem" : "36rem",
  height: detailButton ? "6rem" : isDesktop ? "7.2rem" : "5.6rem",
  minHeight: detailButton ? "6rem" : isDesktop ? "7.2rem" : "5.6rem",
  padding: "0 1.6rem",
  borderRadius: "1.5rem",
  background: disabled
    ? theme.color.medium_grey
    : buttonColor
      ? buttonColor
      : theme.color.primary_color,
  textTransform: "uppercase",
  fontSize: "1.6rem",
  fontWeight: theme.fontWeight.bold,
  letterSpacing: "0.3rem",
  fontFamily: theme.fontFamily.primaryFont,
  color: theme.color.white,
  whiteSpace: "nowrap",
  transition: "all 0.3s",

  cursor: disabled ? "default" : "pointer",

  [mqx("desktop")]: {
    padding: "0 2rem",
    fontSize: "1.8rem",
  },

  ...customstyle,
});

export const PrimaryButtonArrow = styled.img(({ hover }) => ({
  height: "1.8rem",
  transition: "all 0.3s",
  marginLeft: hover ? "1.2rem" : "0",
}));

// STYLE: SecondaryButton.jsx
export const secondaryButtonStyles = (
  theme,
  { customstyle, buttonColor, isDesktop },
) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1rem",
  width: "100%",
  maxWidth: isDesktop ? "40rem" : "36rem",
  height: isDesktop ? "7.2rem" : "5.6rem",
  padding: "0 1.6rem",
  background:
    buttonColor === "white" || buttonColor === "blue"
      ? theme.color.white
      : theme.color.secondary_color,
  color:
    buttonColor === "white"
      ? theme.color.primary_color
      : buttonColor === "blue"
        ? theme.color.tertiary_color
        : theme.color.white,
  borderRadius: "1.5rem",
  fontFamily: theme.fontFamily.primaryFont,
  fontWeight: theme.fontWeight.bold,
  fontSize: "1.6rem",
  letterSpacing: "0.3rem",
  textTransform: "uppercase",
  transition: "all 0.3s",
  whiteSpace: "nowrap",

  [mqx("desktop")]: {
    padding: "0 2rem",
    fontSize: "1.8rem",
  },

  ...customstyle,
});

export const ButtonArrows = styled.img(({ hover }) => ({
  width: "2.2rem",
  height: "2rem",
  transition: "all 0.3s",
  marginLeft: hover ? "1.6rem" : "0",
}));

// STYLE: SwiperArrowButton.jsx
export const ArrowButton = styled.button(({ theme, design }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "5.6rem",
  height: "5.6rem",
  background: design === "border" ? "transparent" : theme.color.light_grey,
  color: theme.color.white,
  border: design === "border" ? "1px solid white" : "none",
  borderRadius: "50%",
  zIndex: 2,

  ":disabled": {
    cursor: "default",
  },

  ":hover:not(:disabled)": {
    background: theme.color.secondary_color,
    border:
      design === "border" ? `1px solid ${theme.color.secondary_color}` : "none",
  },

  [mqx("desktop")]: {
    width: "7.2rem",
    height: "7.2rem",
  },
}));

export const ArrowImage = styled.img(({ direction }) => ({
  width: "1.5rem",
  height: "1.5rem",
  transform: direction === "left" && "rotate(180deg)",

  [mq("tablet")]: {
    width: "2.6rem",
    height: "2.6rem",
  },

  [mqx("desktop")]: {
    width: "3.4rem",
    height: "3.4rem",
  },
}));

export const ArrowImageButtons = styled.div(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: "2rem",
  justifyContent: "space-between",
  gap: "0 1.6rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",

  [mqMax("mobileSmall")]: {
    width: "100%",
    top: "40%",
    marginTop: "10px",
    pointerEvents: "none",

    "& button": {
      pointerEvents: "all",
    },
  },

  [mq("desktop")]: {
    display: "none",
  },
}));

export const MobileScrollArrowText = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 2rem",
  flex: 1,
  fontSize: "1.4rem",
  textTransform: "uppercase",
  fontWeight: theme.fontWeight.bold,

  hr: {
    width: "100%",
    height: "0.2rem",
    background: theme.color.grey_white,
  },
}));

export const breadcrumbButtonStyles = (theme, { isDesktop }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1rem",
  width: isDesktop ? "fit-content" : "100%",
  height: "7.2rem",
  minHeight: isDesktop ? "7.2rem" : "5.6rem",
  padding: "0 1.6rem",
  borderRadius: isDesktop ? "1.5rem" : "0",
  background: theme.color.primary_color,
  textTransform: "uppercase",
  fontSize: "1.6rem",
  fontWeight: theme.fontWeight.bold,
  letterSpacing: "0.3rem",
  fontFamily: theme.fontFamily.primaryFont,
  color: theme.color.white,
  whiteSpace: "nowrap",

  position: isDesktop ? "absolute" : "fixed",
  bottom: isDesktop ? "-3.6rem" : "0",
  zIndex: 99,
  left: "50%",
  transform: "translateX(-50%)",
});
