import React from "react";
import {
  EventGridBox,
  SectionTitle,
  EventButton,
  AWS_BUCKET_URL,
} from "@components/events/winter-wonderland/WinterWonderland";
import styled from "@emotion/styled";
import { ImageWrapperImage } from "@components/item/ItemStyling";
import { mq } from "@utils/media-query/mediaQuery";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

const Container = styled.div(() => ({
  background: "#F3F3F3",
  width: "100%",
  padding: "1.5rem 2rem",

  [mq("desktop")]: {
    padding: "4rem 5rem 5rem 5rem",
  },
}));

const Title = styled.div(() => ({
  marginBottom: "3rem",
  textAlign: "center",
  fontWeight: 300,

  p: {
    fontSize: isJapanese ? "1.2rem" : "1.4rem",
  },

  [mq("desktop")]: {
    p: {
      fontSize: "1.8rem",
    },
  },
}));

const Item = styled.div(({ theme }) => ({
  padding: "1.5rem",
  background: theme.color.white,

  [mq("desktop")]: {
    padding: "2.5rem",
  },
}));

const Figure = styled.figure(() => ({
  position: "relative",
  aspectRatio: "1 / 1",
}));

const Description = styled.div(() => ({
  marginTop: "1.2rem",

  header: {
    fontSize: "1.6rem",
    fontWeight: 700,
  },

  [mq("tablet")]: {
    header: {
      minHeight: "4.8rem",
    },

    span: {
      display: "inline-block",
      minHeight: "3.6rem",
    },
  },

  [mq("desktop")]: {
    header: {
      minHeight: "5.4rem",
      fontSize: "2rem",
    },
  },
}));

const ButtonWrapper = styled.div(() => ({
  textAlign: "center",
  marginTop: "2rem",
}));

const SixthSection = ({ intlFormat }) => {
  const arr = [
    {
      id: 1,
      image: "7_1.jpg",
      link: "/entertainment/performance/naniwa-danshi-asiatour-20242025-alpha",
    },
    {
      id: 2,
      image: "7_2.jpg",
      link: "/entertainment/performance/onerepublic-the-artificial-paradise-tour-in-korea",
    },
    {
      id: 3,
      image: "7_3.jpg",
      link: "/entertainment/performance/2025-LeeJunHo-FanCon-MidnightSun-in-Incheon",
    },
    {
      id: 4,
      image: "7_4.jpg",
      link: "/entertainment/performance/inspire-concert-series-05-kygo",
    },
    {
      id: 5,
      image: "7_5.jpg",
      link: "/entertainment/performance/zedd-telos-tour-in-korea",
    },
    {
      id: 6,
      image: "7_6.jpg",
      link: "/entertainment/performance/inspire-arena-backstage-tour-v2",
    },
  ];

  return (
    <Container>
      <Title>
        <SectionTitle>{intlFormat("winter-section7-title-1")}</SectionTitle>

        <p>{intlFormat("winter-section7-title-2")}</p>
      </Title>

      <EventGridBox column="3">
        {arr.map((item) => {
          return (
            <Item key={item.id}>
              <Figure>
                <ImageWrapperImage
                  src={`${AWS_BUCKET_URL}/${item.image}`}
                  alt={item.title}
                  width="100%"
                />
              </Figure>

              <Description>
                <header>
                  {intlFormat(`winter-section7-content${item.id}-1`)}
                </header>

                <span>{intlFormat(`winter-section7-content${item.id}-2`)}</span>
              </Description>

              <ButtonWrapper>
                <EventButton link={item.link} />
              </ButtonWrapper>
            </Item>
          );
        })}
      </EventGridBox>
    </Container>
  );
};

export default SixthSection;
