import React from "react";
import styled from "@emotion/styled";
import { ImageWrapperImage } from "@components/item/ItemStyling";
import { mq } from "@utils/media-query/mediaQuery";
import {
  AWS_BUCKET_URL,
  EventGridBox,
} from "@components/events/winter-wonderland/WinterWonderland";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

const Container = styled.div(({ theme }) => ({
  background: theme.color.white,
  width: "100%",
  padding: "2rem",

  [mq("tablet")]: {
    padding: "3rem",
  },

  [mq("desktop")]: {
    padding: "4rem 5rem 5rem 5rem",
  },
}));

const TitleWrapper = styled.div(() => ({
  marginBottom: "2.5rem",
  textAlign: "center",
  fontSize: "1.6rem",
}));

const WelcomeText = styled.div(() => ({
  marginBottom: "2rem",
  fontSize: isJapanese ? "2rem" : "2.4rem",
  fontWeight: 300,

  span: {
    fontWeight: 700,
  },

  [mq("desktop")]: {
    fontSize: "3.6rem",
  },
}));

const DateText = styled.div(() => ({
  marginBottom: "1.2rem",
  fontWeight: 700,
  fontSize: "1.4rem",

  [mq("desktop")]: {
    marginBottom: "2.5rem",
    fontSize: "1.6rem",
  },
}));

const Description = styled.div(() => ({
  fontWeight: 300,
  lineHeight: 1.5,
  fontSize: isJapanese ? "1.2rem" : "1.4rem",
  wordBreak: "break-spaces",
  whiteSpace: "pre-wrap",

  [mq("desktop")]: {
    fontSize: "1.8rem",
  },
}));

const Figure = styled.figure(() => ({
  position: "relative",
  aspectRatio: "3 / 2",
}));

const EventTexts = styled.div(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  left: 20,
  bottom: 20,
  color: theme.color.white,
  fontWeight: 700,
  fontSize: "1.4rem",

  header: {
    fontSize: "2rem",
  },

  [mq("desktop")]: {
    fontSize: "1.6rem",

    header: { fontSize: "2.8rem" },
  },
}));

const FirstSection = ({ intlFormat }) => {
  const arr = [
    {
      id: 1,
      image: "2_1.jpg",
    },
    {
      id: 2,
      image: "2_2.jpg",
    },
    // TODO: uncomment when splashbay is ready
    // {
    //   id: 3,
    //   image: "2_3.jpg",
    // },
    // {
    //   id: 4,
    //   image: "2_4.jpg",
    // },
  ];

  return (
    <Container>
      <TitleWrapper>
        <WelcomeText>
          <p>{intlFormat("winter-section1-title-1")}</p>

          <span>{intlFormat("winter-section1-title-2")}</span>
        </WelcomeText>

        <DateText>{intlFormat("winter-section1-date")}</DateText>

        <Description>{intlFormat("winter-section1-description")}</Description>
      </TitleWrapper>

      <EventGridBox gap="0">
        {arr.map((item) => {
          return (
            <Figure key={item.id}>
              <ImageWrapperImage
                src={`${AWS_BUCKET_URL}/${item.image}`}
                alt={intlFormat(`winter-section2-content${item.id}-1`)}
              />

              <EventTexts>
                <header>
                  {intlFormat(`winter-section2-content${item.id}-1`)}
                </header>

                <span>{intlFormat(`winter-section2-content${item.id}-2`)}</span>
              </EventTexts>
            </Figure>
          );
        })}
      </EventGridBox>
    </Container>
  );
};

export default FirstSection;
