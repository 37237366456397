import React from "react";
import { FormattedMessage } from "react-intl";

export const ForgotPasswordText = () => {
  // Forgot Password
  return <FormattedMessage id="lang-forgot-password" />;
};

export const RememberMeText = () => {
  // Remeber Me
  return <FormattedMessage id="lang-remember-me" />;
};

export const JoinOurNewsletterText = () => {
  // Join Our Newsletter
  return <FormattedMessage id="lang-join-our-newsletter" />;
};

export const SuttleBusTakesText = () => {
  // *The shuttle bus takes about 15 minutes for each route.
  return <FormattedMessage id="lang-shuttle-bus-takes" />;
};

export const UrlCopiedText = () => {
  // Url Copied
  return <FormattedMessage id="lang-url-copied" />;
};

export const ViewDetailsText = () => {
  // View Details
  return <FormattedMessage id="lang-view-details" />;
};

export const ViewAllText = () => {
  // View All
  return <FormattedMessage id="lang-view-all" />;
};

export const ViewFullVideoText = () => {
  // View Full Video
  return <FormattedMessage id="lang-view-full-video" />;
};

export const PleaseSendEmailText = () => {
  // Please send email for Arena reservation inquiry.
  return <FormattedMessage id="lang-please-send-email" />;
};

export const ApplyText = () => {
  // Apply
  return <FormattedMessage id="lang-apply" />;
};

export const InquiryText = () => {
  // Inquiry
  return <FormattedMessage id="lang-inquiry" />;
};

export const TermsConditionsApplyText = () => {
  // Terms and Conditions apply
  return <FormattedMessage id="lang-terms-conditions-apply" />;
};

export const UnauthorizedCollectionOfEmailText = () => {
  // Unauthorized Collection of Email Address
  return <FormattedMessage id="lang-unauthorized-collection-of-email" />;
};

export const LearnMoreText = () => {
  // Learn More
  return <FormattedMessage id="lang-learn-more" />;
};

export const JoinMomentumMembershipText = () => {
  // Join the Momentum Membership to enjoy exclusive benefits
  return <FormattedMessage id="lang-join-momentum-membership" />;
};

export const LoadMoreText = () => {
  // Load More
  return <FormattedMessage id="lang-load-more" />;
};

export const PrivacyPolicyText = () => {
  // Privacy Policy
  return <FormattedMessage id="lang-privacy-policy" />;
};

export const TermsConditionsText = () => {
  // Privacy Policy
  return <FormattedMessage id="lang-terms-conditions" />;
};

export const MemberText = () => {
  // Member
  return <FormattedMessage id="lang-member" />;
};

export const ViewRulesText = () => {
  // View Rules
  return <FormattedMessage id="lang-view-rules" />;
};

export const NumberofSeatsbyFloorText = () => {
  // Number of Seats by Floor
  return <FormattedMessage id="lang-number-of-seats-by-floor" />;
};

export const TheSeatsAboveAreSubjectToSomeVariationsText = () => {
  // The seats above are subject to some variations.
  return (
    <FormattedMessage id="lang-the-seats-above-are-subject-to-some-variations" />
  );
};

export const EventsText = () => {
  // Events
  return <FormattedMessage id="lang-events" />;
};

export const NonMemberText = () => {
  // Non-Member
  return <FormattedMessage id="lang-non-member" />;
};

export const ReservationsText = () => {
  // Reservations
  return <FormattedMessage id="lang-reservations" />;
};

export const LoginText = () => {
  // Login
  return <FormattedMessage id="lang-login" />;
};

export const KeepMeLoggedInText = () => {
  // Keep me logged in
  return <FormattedMessage id="lang-keep-me-logged-in" />;
};

export const FindMyIDandPasswordText = () => {
  // Find my ID and Password
  return <FormattedMessage id="lang-find-my-id-and-password" />;
};

export const SignInText = () => {
  // Sign In
  return <FormattedMessage id="lang-sign-in" />;
};

export const BackToMediaCenterText = () => {
  // Back To Media Center
  return <FormattedMessage id="lang-back-to-media-center" />;
};

export const BookYourMeetingText = () => {
  // Book Your Meeting
  return <FormattedMessage id="lang-book-your-meeting" />;
};

export const EventInfoText = () => {
  // Event Info
  return <FormattedMessage id="lang-event-info" />;
};

export const ConnectToLinkedinText = () => {
  // Connect To Linkedin
  return <FormattedMessage id="lang-connect-to-linkedin" />;
};

export const HotelReservationsText = () => {
  // Hotel Reservations
  return <FormattedMessage id="lang-hotel-reservations" />;
};

export const PhoneText = () => {
  // Phone
  return <FormattedMessage id="lang-phone" />;
};

export const ConnectWithUsText = () => {
  // Connect With Us
  return <FormattedMessage id="lang-connect-with-us" />;
};

export const MapText = () => {
  // Map
  return <FormattedMessage id="lang-map" />;
};

export const FaxText = () => {
  // Fax
  return <FormattedMessage id="lang-fax" />;
};

export const EmailText = () => {
  // Email
  return <FormattedMessage id="lang-email" />;
};

export const SignUpText = () => {
  // Sign Up
  return <FormattedMessage id="lang-sign-up" />;
};

export const NoticeText = () => {
  // Notice
  return <FormattedMessage id="lang-notice" />;
};

export const CareersText = () => {
  // AboutUsCareers
  return <FormattedMessage id="lang-careers" />;
};

export const SignUpNowText = () => {
  // Sign Up Now
  return <FormattedMessage id="lang-sign-up-now" />;
};

export const SubmitText = () => {
  // Submit
  return <FormattedMessage id="lang-submit" />;
};

export const SubmitLoadingText = () => {
  return <FormattedMessage id="lang-submit-loading" />;
};

export const OkText = () => {
  //Ok
  return <FormattedMessage id="lang-ok" />;
};

export const TopText = () => {
  // Top
  return <FormattedMessage id="lang-top" />;
};

export const AllText = () => {
  // All
  return <FormattedMessage id="lang-all" />;
};

export const BookNowText = () => {
  // Book Now
  return <FormattedMessage id="lang-book-now" />;
};

export const ReserveText = () => {
  // Reserve
  return <FormattedMessage id="lang-reserve" />;
};

export const BookYourTicketText = () => {
  // Book Your Ticket
  return <FormattedMessage id="lang-book-your-ticket" />;
};

export const BookYourRoomText = () => {
  // Book Your Room
  return <FormattedMessage id="lang-book-your-room" />;
};

export const BookYourEventText = () => {
  // Book Your Event
  return <FormattedMessage id="lang-book-your-event" />;
};

export const BookYourTableText = () => {
  // Book Your Table
  return <FormattedMessage id="lang-book-your-table" />;
};

export const BookSplashBayText = () => {
  // Book Splash Bay
  return <FormattedMessage id="lang-book-splash-bay" />;
};

export const PdfDownloadText = () => {
  // PDF Download
  return <FormattedMessage id="lang-pdf-download" />;
};

export const GoBackToHomepageText = () => {
  // Go Back to the Home Page
  return <FormattedMessage id="lang-go-back-to-homepage" />;
};

export const SeeTheMenuText = () => {
  // See the Menu
  return <FormattedMessage id="lang-see-the-menu" />;
};

export const RoomConfigurationText = () => {
  // Room Configuration
  return <FormattedMessage id="lang-room-configuration" />;
};

export const RoomSizeText = () => {
  // Room Size
  return <FormattedMessage id="lang-room-size" />;
};

export const RoomTypeText = () => {
  // Room Type
  return <FormattedMessage id="lang-room-type" />;
};

export const ViewTypeText = () => {
  // View Type
  return <FormattedMessage id="lang-view-type" />;
};

export const VisitPageText = () => {
  // Visit Page
  return <FormattedMessage id="lang-visit-page" />;
};

export const ExtraChargeText = () => {
  // Extra Charge
  return <FormattedMessage id="lang-extra-charge" />;
};

export const HomeText = () => {
  // Home
  return <FormattedMessage id="lang-home" />;
};

export const OffersText = () => {
  // Offers
  return <FormattedMessage id="lang-offers" />;
};

export const StayText = () => {
  // Stay
  return <FormattedMessage id="lang-stay" />;
};

export const ShopText = () => {
  // Shop
  return <FormattedMessage id="lang-shop" />;
};

export const MeetText = () => {
  // Meet
  return <FormattedMessage id="lang-meet" />;
};

export const CasinoText = () => {
  // Casino
  return <FormattedMessage id="lang-casino" />;
};

export const EventsPromotionsText = () => {
  // Casino
  return <FormattedMessage id="lang-events-promotions" />;
};

export const MomentumText = () => {
  // Momentum
  return <FormattedMessage id="lang-momentum" />;
};

export const MomentumLifestyleText = () => {
  //  Momentum Lifestyle
  return <FormattedMessage id="lang-momentum-lifestyle" />;
};

export const AboutUsText = () => {
  // About Us
  return <FormattedMessage id="lang-about-us" />;
};
export const EatAndDrinkText = () => {
  // Eat & Drink
  return <FormattedMessage id="lang-eat-and-drink" />;
};

export const WeddingAndEventText = () => {
  // Wedding & Event
  return <FormattedMessage id="lang-wedding-and-event" />;
};

export const RecommendationText = () => {
  // recommendation
  return <FormattedMessage id="lang-recommendation" />;
};

export const SightseeingText = () => {
  // Sightseeing
  return <FormattedMessage id="lang-sightseeing" />;
};

export const EvaluationPeriodText = () => {
  // Evaluation Period
  return <FormattedMessage id="lang-evaluation-period" />;
};

export const StartDateText = () => {
  // Start Date
  return <FormattedMessage id="lang-start-date" />;
};

export const MaintenancePeriodText = () => {
  // Maintenance Period
  return <FormattedMessage id="lang-maintenance-period" />;
};

export const RedemptionOfPointsText = () => {
  // Redemption of Points
  return <FormattedMessage id="lang-redemption-of-points" />;
};

export const ExpirationOfPointsText = () => {
  // Expiration of Points
  return <FormattedMessage id="lang-expiration-of-points" />;
};

export const GalleryPreviewText = () => {
  // Gallery Preview
  return <FormattedMessage id="lang-gallery-preview" />;
};

export const FacilityServicesText = () => {
  // Facility & Services
  return <FormattedMessage id="lang-facility-services" />;
};

export const SplashBayText = () => {
  // Splash Bay
  return <FormattedMessage id="lang-splash-bay" />;
};

export const EntertainmentText = () => {
  // Entertainment
  return <FormattedMessage id="lang-entertainment" />;
};

export const MeetingText = () => {
  // Meeting
  return <FormattedMessage id="lang-meeting" />;
};

export const MeetingRoomText = () => {
  // Meeting Room
  return <FormattedMessage id="lang-meeting-room" />;
};

export const AttractionVenuesText = () => {
  // Attraction Venues
  return <FormattedMessage id="lang-attraction-venues" />;
};

export const InspireArenaText = () => {
  // Inspire Arena
  return <FormattedMessage id="lang-inspire-arena" />;
};

export const FloorGuideText = () => {
  // Floor Guide
  return <FormattedMessage id="lang-floor-guide" />;
};

export const FacilitiesText = () => {
  // Facilities
  return <FormattedMessage id="lang-facilities" />;
};

export const AmenitiesText = () => {
  // Amenities
  return <FormattedMessage id="lang-amenities" />;
};

export const WhyUsText = () => {
  // Why Us
  return <FormattedMessage id="lang-why-us" />;
};

export const ServicesText = () => {
  // Services
  return <FormattedMessage id="lang-services" />;
};

export const LocationText = () => {
  // Location
  return <FormattedMessage id="lang-location" />;
};

export const CapacityText = () => {
  // Capacity
  return <FormattedMessage id="lang-capacity" />;
};

export const RestaurantTypeText = () => {
  // Restaurant Type
  return <FormattedMessage id="lang-restaurant-type" />;
};

export const RestaurantInformationText = () => {
  // Restaurant Information
  return <FormattedMessage id="lang-restaurant-information" />;
};

export const InformationText = () => {
  //  Information
  return <FormattedMessage id="lang-information" />;
};

export const CapacitySizeText = () => {
  // Capacity/Size
  return <FormattedMessage id="lang-capacity-size" />;
};

export const EntryPolicyText = () => {
  // Entry Policy
  return <FormattedMessage id="lang-entry-policy" />;
};

export const CategoryText = () => {
  // Category
  return <FormattedMessage id="lang-category" />;
};

export const WeekdaysText = () => {
  // Weekdays
  return <FormattedMessage id="lang-weekdays" />;
};

export const WeekendsHolidaysText = () => {
  // Weekends / Holidays
  return <FormattedMessage id="lang-weekends-holidays" />;
};

export const GeneralText = () => {
  // General
  return <FormattedMessage id="lang-general" />;
};

export const KRWText = () => {
  // KRW
  return <FormattedMessage id="lang-krw" />;
};

export const SummerSeasonText = () => {
  // Summer Season
  return <FormattedMessage id="lang-summer-season" />;
};

export const AdultsText = () => {
  // Adults
  return <FormattedMessage id="lang-adults" />;
};

export const ChildrenText = () => {
  // Children
  return <FormattedMessage id="lang-children" />;
};

export const DesignerText = () => {
  // Designer
  return <FormattedMessage id="lang-designer" />;
};

export const ShopTypeText = () => {
  // Shop Type
  return <FormattedMessage id="lang-shop-type" />;
};

export const OperatingHoursText = () => {
  // Operating Hours
  return <FormattedMessage id="lang-operating-hours" />;
};

export const OpenHoursText = () => {
  // Open Hours
  return <FormattedMessage id="lang-open-hours" />;
};

export const FacilityInformationText = () => {
  // Facility Information
  return <FormattedMessage id="lang-facility-information" />;
};

export const OperationInformationCautionText = () => {
  // Operation Information and Caution
  return <FormattedMessage id="lang-operation-information-caution" />;
};

export const PrivateRoomsText = () => {
  // Private Rooms
  return <FormattedMessage id="lang-private-rooms" />;
};

export const EntranceTicketPriceText = () => {
  // Entrance Ticket Price
  return <FormattedMessage id="lang-entrance-ticket-price" />;
};

export const ViewMenuText = () => {
  // View Menu
  return <FormattedMessage id="lang-view-menu" />;
};

export const CheckInCheckOutText = () => {
  // Check in - Check out
  return <FormattedMessage id="lang-check-in-check-out" />;
};

export const SelectDatesText = () => {
  // Select Dates
  return <FormattedMessage id="lang-select-dates" />;
};

export const GuestsText = () => {
  // Guests
  return <FormattedMessage id="lang-guests" />;
};

export const NumberofGuestsText = () => {
  // Number of Guests
  return <FormattedMessage id="lang-number-of-guests" />;
};

export const OfferCodeText = () => {
  // Offer Code
  return <FormattedMessage id="lang-offer-code" />;
};

export const EnterCodeHereText = () => {
  // Enter Code Here
  return <FormattedMessage id="lang-enter-code-here" />;
};

export const RoomReservationText = () => {
  // ROOM RESERVATION
  return <FormattedMessage id="lang-room-reservation" />;
};

export const CheckAvailabilityText = () => {
  // Check Availability
  return <FormattedMessage id="lang-check-availability" />;
};

export const InformationAlertText = () => {
  // Information Alert
  return <FormattedMessage id="lang-information-alert" />;
};

export const ApplicableEndDateText = () => {
  // Applicable End Date
  return <FormattedMessage id="lang-applicable-end-date" />;
};

export const BookableEndDateText = () => {
  // Bookable End Date
  return <FormattedMessage id="lang-bookable-end-date" />;
};

export const BookBetweenText = () => {
  // Book Between
  return <FormattedMessage id="lang-book-between" />;
};

export const StayBetweenText = () => {
  // Stay Between
  return <FormattedMessage id="lang-stay-between" />;
};

export const DeleteAccountText = () => {
  // Delete Account
  return <FormattedMessage id="lang-delete-account" />;
};

export const EditText = () => {
  // Edit
  return <FormattedMessage id="lang-edit" />;
};

export const PreferencesText = () => {
  // Preferences
  return <FormattedMessage id="lang-preferences" />;
};

export const UsernamePasswordText = () => {
  // Username & Password
  return <FormattedMessage id="lang-username-password" />;
};

export const BasicInfomationText = () => {
  // Basic Infomation
  return <FormattedMessage id="lang-basic-information" />;
};

export const AddressText = () => {
  // Address
  return <FormattedMessage id="lang-address" />;
};

export const SwitchLanguageText = () => {
  // Switch Lanugage
  return <FormattedMessage id="switch-language" />;
};

export const NotFoundInstruction1 = () => {
  // OOPS! Looks Like You're Lost
  return <FormattedMessage id="lang-not-found-instruction-1" />;
};

export const NotFoundInstruction2 = () => {
  // Uh-oh! It seems you've stumbled upon a page that doesn't exist.
  return <FormattedMessage id="lang-not-found-instruction-2" />;
};

export const NotFoundInstruction3 = () => {
  // Don't worry, though. We're here to help you find your way back on
  // track.
  return <FormattedMessage id="lang-not-found-instruction-3" />;
};

export const InspireResortText = () => {
  // INSPIRE Resort
  return <FormattedMessage id="lang-inspire-resort" />;
};

export const IncheonAirportTerminal2Text = () => {
  // Incheon Airport Terminal 2
  return <FormattedMessage id="lang-incheon-airport-terminal-2" />;
};

export const IncheonAirportTerminal1Text = () => {
  // Incheon Airport Terminal 1
  return <FormattedMessage id="lang-incheon-airport-terminal-1" />;
};

export const ArrivalText = () => {
  // Departure
  return <FormattedMessage id="lang-arrival" />;
};

export const ArrivalTimeText = () => {
  // Departure
  return <FormattedMessage id="lang-arrival-time" />;
};

export const DepartureText = () => {
  // Departure
  return <FormattedMessage id="lang-departure" />;
};

export const AreYouAMomentumMemberAlreadyText = () => {
  // Are you a MOMENTUM member already?
  return <FormattedMessage id="lang-momentum-member-already" />;
};

export const MoreOffersComingSoonText = () => {
  // More Offers Coming Soon
  return <FormattedMessage id="lang-more-offers-coming-soon" />;
};

export const NoOffersDescriptionText = () => {
  // Hi there! No offers at the moment, but don't miss out - sign up for
  // our newsletter to stay updated on upcoming deals!
  return <FormattedMessage id="lang-no-offers-description" />;
};

export const CloseText = () => {
  // Close
  return <FormattedMessage id="lang-close" />;
};

export const BeInformedText = () => {
  // Be Informed
  return <FormattedMessage id="lang-be-informed" />;
};

export const ResendVerificationCodeText = () => {
  // Resend the Verifiaction Code
  return <FormattedMessage id="lang-resend-verification-code" />;
};

export const ComingSoonText = () => {
  // Coming Soon
  return <FormattedMessage id="lang-coming-soon" />;
};

export const StartsFromText = () => {
  // Starts From
  return <FormattedMessage id="lang-starts-from" />;
};

export const FreeShuttleBusServiceText = () => {
  // Free Shuttle Bus Service
  return <FormattedMessage id="lang-free-shuttle-bus-service" />;
};

export const ResendTokenText = () => {
  // Resend Token
  return <FormattedMessage id="lang-resend-token" />;
};

export const SignInWithNewPasswordText = () => {
  // Please sign in with new password
  return <FormattedMessage id="lang-sign-in-with-new-password" />;
};

export const DashboardText = () => {
  // Dashboard
  return <FormattedMessage id="lang-dashboard" />;
};

export const AccountText = () => {
  // Account
  return <FormattedMessage id="lang-account" />;
};

export const RoomNameText = () => {
  // Room Name
  return <FormattedMessage id="lang-room-name" />;
};

export const RoomDimensionsText = () => {
  // Room Dimensions
  return <FormattedMessage id="lang-room-dimension" />;
};

export const AreaText = () => {
  // Area
  return <FormattedMessage id="lang-area" />;
};

export const LengthText = () => {
  // Length
  return <FormattedMessage id="lang-length" />;
};

export const WidthText = () => {
  // Width
  return <FormattedMessage id="lang-width" />;
};

export const HeightText = () => {
  // Height
  return <FormattedMessage id="lang-height" />;
};

export const ForgotSubmitText = () => {
  // Forgot Submit
  return <FormattedMessage id="lang-forgot-submit" />;
};

export const ChangeSubmitText = () => {
  // Change Submit
  return <FormattedMessage id="lang-change-submit" />;
};

export const ResetSubmitText = () => {
  // Reset Submit
  return <FormattedMessage id="lang-reset-submit" />;
};

export const MainLobby = () => {
  // Main Lobby
  return <FormattedMessage id="lang-main-lobby" />;
};

export const HongDaeLine = () => {
  // HONG-DAE LINE
  return <FormattedMessage id="lang-hong-dae-line" />;
};

export const HongDae = () => {
  // Hong-dae
  return <FormattedMessage id="lang-hong-dae" />;
};

export const MyungDongLine = () => {
  // MYUNG-DONG LINE
  return <FormattedMessage id="lang-myung-dong-line" />;
};

export const MyungDong = () => {
  // Myung-dong
  return <FormattedMessage id="lang-myung-dong" />;
};

export const DaerimLine = () => {
  // DAERIM LINE
  return <FormattedMessage id="lang-daerim-line" />;
};

export const Daerim = () => {
  // Daerim
  return <FormattedMessage id="lang-daerim" />;
};

export const GimpoLine = () => {
  // GIMPO LINE
  return <FormattedMessage id="lang-gimpo-line" />;
};

export const GimpoAirport = () => {
  // Gimpo Airport
  return <FormattedMessage id="lang-gimpo-airport" />;
};

export const Exit4 = () => {
  // (Exit 4)
  return <FormattedMessage id="lang-exit-4" />;
};

export const Exit5 = () => {
  // (Exit 5)
  return <FormattedMessage id="lang-exit-5" />;
};

export const Exit8 = () => {
  // (Exit 8)
  return <FormattedMessage id="lang-exit-8" />;
};

export const LotteMall = () => {
  // (Lotte Mall)
  return <FormattedMessage id="lang-lotte-mall" />;
};

export const LimitedCapacity = () => {
  // *Limited Capacity: 45 seats
  return <FormattedMessage id="lang-limited-capacity" />;
};

export const SideText = () => {
  // *Limited Capacity: 45 seats
  return <FormattedMessage id="lang-side" />;
};

export const GameTypeText = () => {
  // Game Type
  return <FormattedMessage id="lang-game-type" />;
};

export const GameNameText = () => {
  // Game Name
  return <FormattedMessage id="lang-game-name" />;
};

export const NumberOfTablesText = () => {
  // # of Tables
  return <FormattedMessage id="lang-number-of-tables" />;
};

export const VariationTypesText = () => {
  // variation types
  return <FormattedMessage id="lang-variation-types" />;
};

export const Exit1Text = () => {
  return <FormattedMessage id="lang-exit-1" />;
};

export const Exit2Text = () => {
  return <FormattedMessage id="lang-exit-2" />;
};

export const SongDoText = () => {
  return <FormattedMessage id="lang-song-do" />;
};

export const SongDoLongText = () => {
  return <FormattedMessage id="lang-song-do-long" />;
};

export const AnsanText = () => {
  return <FormattedMessage id="lang-ansan" />;
};

export const AnsanLongText = () => {
  return <FormattedMessage id="lang-ansan-long" />;
};

export const OceanTowerText = () => {
  return <FormattedMessage id="lang-ocean-tower" />;
};

export const CubeAuroraText = () => {
  return <FormattedMessage id="lang-cube-aurora" />;
};

export const CommuterBusParkingText = () => {
  return <FormattedMessage id="lang-commuter-bus-parking" />;
};

export const SiteTitleText = () => {
  return <FormattedMessage id="lang-title" />;
};

export const SiteDescriptionText = () => {
  return <FormattedMessage id="lang-description" />;
};

export const IAgreeText = () => {
  return <FormattedMessage id="lang-agree" />;
};

export const IDisagreeText = () => {
  return <FormattedMessage id="lang-disagree" />;
};

export const InvalidOldPasswordText = () => {
  return <FormattedMessage id="lang-invalid-old-password" />;
};

export const CurrentNewPasswordMatches = () => {
  return <FormattedMessage id="lang-current-new-password-same" />;
};

export const InvalidFormatPasswordText = () => {
  return (
    <FormattedMessage
      id="lang-invalid-format-password"
      values={{ nbsp: <br></br> }}
    />
  );
};

export const InvalidNewPasswordText = () => {
  return <FormattedMessage id="lang-invalid-new-password" />;
};

export const ServiceErrorText = () => {
  return <FormattedMessage id="lang-service-error" />;
};

export const PasswordServiceErrorText = () => {
  return <FormattedMessage id="lang-password-service-error" />;
};
