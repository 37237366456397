import React from "react";
import { ImageBox, Image } from "./WonderlandStyling";

export default function SplashSwiper({ list }) {

  return (
    <ImageBox>
      {list.map((item, idx) => (
        <Image
          key={`new_splash_${idx}`}
          src={`https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/${item}.jpg`}
          alt=""
        />
      ))}
    </ImageBox>
  );
}
