import React from "react";
import styled from "@emotion/styled";
import {
  AWS_BUCKET_URL,
  EventButton,
} from "@components/events/winter-wonderland/WinterWonderland";
import { mq } from "@utils/media-query/mediaQuery";

const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",

  gap: "1.2rem 12rem",
  padding: "3rem 2.5rem",
  background: "#f3f3f3",

  [mq("tablet")]: {
    flexDirection: "row",
    gap: "1.2rem 3rem",
    padding: "1.8rem 4rem 1.8rem 2rem",
  },

  [mq("desktop")]: {
    flexDirection: "row",
    padding: "1.8rem 5rem 1.8rem 8rem",
  },
}));

const Texts = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "2rem 0",
  fontSize: "1.4rem",
  lineHeight: 1.3,
  fontWeight: 400,

  header: {
    color: "#68507C",
    fontSize: "2.4rem",
    fontWeight: 700,
  },

  [mq("desktop")]: {
    fontSize: "1.8rem",

    header: {
      fontWeight: 500,
      fontSize: "3.6rem",
    },
  },
}));

const Image = styled.img(() => ({
  width: "100%",

  [mq("tablet")]: {
    maxWidth: "50%",
  },

  [mq("desktop")]: {
    maxWidth: "50%",
  },
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1.4rem",
  width: "100%",
  marginTop: 0,

  [mq("tablet")]: {
    marginTop: "3rem",
    justifyContent: "flex-start",
  },

  [mq("desktop")]: {
    marginTop: "3rem",
    justifyContent: "flex-start",
  },
}));

const FifthSection = ({ intlFormat }) => {
  return (
    <Container>
      <Image
        src={`${AWS_BUCKET_URL}/6_Momentum.png`}
        alt="Take your rewards experience to the next level with Momentum Membership. Join for free to start enjoying exclusive benefits today."
      />

      <Texts>
        <header>{intlFormat("winter-section6-title-1")}</header>

        <p>{intlFormat("winter-section6-title-2")}</p>

        <ButtonWrapper>
          <EventButton
            buttonName={intlFormat("lang-sign-up-now")}
            primary
            link="/momentum/sign-in"
          />

          <EventButton link="/momentum/tiers-benefits" />
        </ButtonWrapper>
      </Texts>
    </Container>
  );
};

export default FifthSection;
