import React from "react";

import QRCodeComponent from "./MyAccountQRCode";

import { CommonDescFive, CommonTitleOne } from "@assets/styles/CommonStyles";

import {
  WelcomeMessageCard,
  WelcomeMessageContainer,
  WelcomeMessageInformation,
  WelcomeMessageItem,
} from "./MyProfileStyling";
import { useMediaQuery } from "react-responsive";
import {
  addCommaToNumber,
  changeEmptyValue,
} from "@utils/modify-data/modifyData";

const WelcomeMessage = ({ data, userData, currentIndex, profile }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const accountInformation = [
    {
      id: 1,
      label: data?.field_information_labels[0],
      value: changeEmptyValue(userData?.identifiers?.email),
    },
    {
      id: 2,
      label: data?.field_information_labels[1],
      value: changeEmptyValue(userData?.country),
    },
    {
      id: 3,
      label: data?.field_information_labels[2],
      value: changeEmptyValue(userData?.date_of_birth),
    },
    {
      // Find the bucket where BucketName is "Loyalty Points"
      id: 4,
      label: data?.field_information_labels[3],
      value: profile
        ? addCommaToNumber(
            profile?.BucketBalances?.find((el) =>
              el.BucketName.includes(
                process.env.REACT_APP_ENGAGE_MOMENTUM_POINTS,
              ),
            )?.BucketBalanceInPoints,
          )
        : "-",
    },
  ];

  return (
    <WelcomeMessageContainer>
      <div>
        <CommonTitleOne lowercase>
          {data?.field_welcome_message?.replace(
            "{{name}}",
            changeEmptyValue(userData?.given_name),
          )}
        </CommonTitleOne>

        <WelcomeMessageInformation>
          {accountInformation.map((item) => {
            return (
              <WelcomeMessageItem key={item.id}>
                <CommonDescFive>{item.label}</CommonDescFive>

                <p>{item.value}</p>
              </WelcomeMessageItem>
            );
          })}
        </WelcomeMessageInformation>
      </div>

      {data && (
        <WelcomeMessageCard
          url={`${process.env.REACT_APP_SERVER_URL}${
            data?.field_tier[currentIndex]?.field_icon.src
          }`}
        >
          {isMobile && (
            <QRCodeComponent
              currentData={userData?.lifestyle_id?.replace(
                /(\d{4})(?=\d)/g,
                "$1 ",
              )}
            />
          )}

          <div>
            {userData?.given_name} {userData?.family_name} <br />
            {userData?.lifestyle_id?.replace(/(\d{4})(?=\d)/g, "$1 ")}
          </div>
        </WelcomeMessageCard>
      )}
    </WelcomeMessageContainer>
  );
};

export default WelcomeMessage;
