import { Dialog } from "@mui/material";
import styled from "@emotion/styled";

import { color } from "src/theme";
import {
  CommonDescFive,
  CommonDescOne,
  CommonDescTwo,
  CommonTitleFour,
  CommonTitleOne,
  CommonTitleThree,
  CommonTitleTwo,
  headerMenuModalZIndex,
} from "@assets/styles/CommonStyles";
import { mq } from "@utils/media-query/mediaQuery";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

// STYLE: CommonModal.jsx
export const CommonModalContainer = styled.div(({ noPadding, width }) => ({
  width: "100%",
  padding: noPadding ? 0 : "1.6rem",
  background: "white",
  borderRadius: "1.5rem",

  [mq("desktop")]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    maxHeight: "80vh",
    minWidth: "40rem",
    maxWidth: "90%",
    width: width ? width : "fit-content",
    padding: noPadding ? 0 : "4.8rem",
  },
}));

export const CommonModalWrapper = styled.div(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",

  [mq("desktop")]: {
    width: "fit-content",
    minWidth: "50rem",
  },
}));

export const MovableModalTitle = styled(CommonTitleThree)(({ theme }) => ({
  fontWeight: theme.fontWeight.Black,
}));

export const CommonModalContents = styled.div(() => ({
  position: "relative",
  height: "100%",
}));

export const CommonModalTitle = styled(CommonTitleTwo)(() => ({
  textTransform: "initial",
}));

export const CommonModalTitleWrapper = styled.div(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "2rem",

  [mq("desktop")]: {
    alignItems: "flex-start",
    marginBottom: "4rem",
  },
}));

export const CommonModalCloseButton = styled.div(() => ({
  position: "absolute",
  width: "fit-content",
  top: "2rem",
  right: "3.2rem",
  zIndex: 80,
  padding: ".25rem",

  img: {
    cursor: "pointer",
  },
}));

// STYLE: CenteredModal.jsx
export const CenteredModalWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "2rem",

  [mq("desktop")]: {
    marginBottom: "4rem",
  },
}));

export const CenteredModalCloseButton = styled.button(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const CenteredModalTitle = styled(CommonTitleOne)(() => ({
  textAlign: "center",
  margin: "0 auto",
}));

export const CenteredModalSubTitle = styled.div(({ theme }) => ({
  marginTop: "1rem",
  textAlign: "center",
  fontSize: "1.2rem",
  textTransform: "uppercase",
  fontWeight: theme.fontWeight.extraBold,
  color: theme.color.dark_grey,
}));

// MovableModal.jsx
export const StyeldDialog = styled(Dialog)(() => ({
  position: "absolute",
  width: "fit-content",
  minWidth: "30rem",
  height: "fit-content",
  left: "50%",
  top: "8rem",
  transform: "translate(-50%)",

  "& .MuiDialog-paper": {
    borderRadius: "1.5rem",
    margin: 0,
    boxShadow: "none",
  },

  "& .MuiDialog-container": {
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },

  [mq("desktop")]: {
    top: "14rem",
    right: 0,
    left: "auto",
    transform: "none",
  },
}));

export const MovableModalContainer = styled.div(({ theme }) => ({
  position: "relative",
  padding: "1.6rem",
  zIndex: headerMenuModalZIndex,
  background: theme.color.white,
  boxShadow: theme.shadow.secondary,
  borderRadius: "1.5rem",

  [mq("desktop")]: {
    padding: "4.8rem",
    borderRadius: "2.5rem",
  },
}));

// STYLE: ForgotPasswordModal.jsx
export const ForgotPasswordModalForm = styled.form(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "44rem",
  margin: "0 auto",
}));

export const ForgotPasswordFormDesc = styled(CommonDescOne)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",
  marginBottom: "3.2rem",
  textAlign: "center",
}));

export const ForgotPasswordRemainDesc = styled(CommonDescOne)(({ theme }) => ({
  color: theme.color.point_primary_color,
}));

export const ForgotPasswordFormResend = styled(CommonDescFive)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  marginBottom: "1rem",
  cursor: "pointer",
  color: theme.color.secondary_grey,
  textDecoration: "underline",
}));

// STYLE: RoomReservationModal.jsx
export const RoomReservationModalContainer = styled.div(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",

  [mq("desktop")]: {
    flexDirection: "row",

    "&>*:first-of-type": {
      minWidth: "50rem",
    },
  },
}));

export const RoomReservationModalRight = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  height: "auto",
  padding: "1.6rem",
  justifyContent: "flex-start",

  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${theme.image.roomReservationBg})`,
  backgroundSize: "100% 100%",
  backgroundPosition: "right bottom",
  paddingRight: "20px",

  [mq("desktop")]: {
    padding: "7rem 6rem 7rem 3rem",
    minWidth: "50rem",
  },
}));

export const MomentumModalTitle = styled(CommonTitleOne)(() => ({
  [mq("desktop")]: {
    fontSize: isJapanese ? "3.2rem" : "4.8rem",
  },
}));

export const ModalCloseButton = styled.button(() => ({
  position: "absolute",
  top: "1rem",
  right: "1.6rem",
  minWidth: "0 !important",
  padding: "10px",

  [mq("desktop")]: {
    top: "3rem",
    right: "3rem",
  },
}));

// STYLE: KeepInTouchForm.jsx
export const KeepInTouchFormRecaptcha = styled.div(() => ({
  minHeight: "10rem",
}));

export const KeepInTouchFormFlex = styled.div(() => ({
  display: "flex",
  gap: "0 0.8rem",

  [mq("desktop")]: {
    gap: "0 3.2rem",
  },
}));

export const KeepInTouchFormCheckBoxContainer = styled.div(() => ({
  margin: "1.6rem 0 0",
}));

export const KeepInTouchFormCheckBoxWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: theme.color.primary_grey,

  "> div": {
    display: "flex",
    alignItems: "center",
  },
}));

export const KeepInTouchFormTermsOfUse = styled.button(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.primary_grey}`,
}));

export const MarketingOptInWrapper = styled.div(({ theme }) => ({
  ".MuiFormGroup-root": {
    display: "flex",
    alignItems: "center",
    flexFlow: "row wrap",
    marginTop: "8px",
  },

  ".MuiFormControlLabel-root": {
    display: "flex",
  },
}));

export const MarketingModalOptInWrapper = styled.div(({ theme }) => ({
  ".MuiFormGroup-root": {
    display: "flex",
    alignItems: "center",
    flexFlow: "row wrap",
    margin: "8px 0 32px",
  },

  ".MuiFormControlLabel-root": {
    display: "flex",
  },
}));

export const MarketingOptInLabel = styled.div(({ theme }) => ({
  display: "flex",
  gap: "4px",
  alignItems: "center",
  fontSize: "1.8rem",
  fontWeight: 600,
  marginTop: "32px",
}));

// STYLE: MomentumModal.jsx
export const MomentumModalDesc = styled(CommonDescTwo)(() => ({
  margin: "1rem 0",
}));

export const MomentumModalButton = {
  border: `1px solid ${color.primary_color}`,
  maxWidth: "fit-content",
  padding: "0 6rem",
};

// STYLE: UnauthorizedEmailModal.jsx
export const UnauthorizedEmailModalContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "3rem 0",
  maxWidth: "80rem",
  textAlign: "center",
}));

// STYLE: ArenaInquiryModal.jsx
export const ModalBorderButton = styled.a(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1rem",
  minWidth: "20rem",
  width: "fit-content",
  padding: "2rem",
  borderRadius: "1.5rem",
  border: `1px solid ${theme.color.primary_color}`,
  color: theme.color.primary_color,
  letterSpacing: "0.2rem",
  fontWeight: theme.fontWeight.bold,
  textTransform: "uppercase",

  ":hover": {
    img: {
      transition: "all 0.3s",
      marginLeft: "2rem",
    },
  },
}));

// STYLE: SingleMomentumBenefitModal.jsx
export const SingleMomentumBenefitImageWrapper = styled.div(() => ({
  top: 0,
  height: "fit-content",

  [mq("desktop")]: {
    position: "sticky",
  },
}));

export const SingleMomentumBenefitTitle = styled(CommonTitleFour)(
  ({ theme }) => ({
    color: theme.color.primary_color,
    marginBottom: "0.4rem",
  }),
);

export const SingleMomentumBenefitItem = styled.ul(() => ({
  paddingLeft: "3rem",

  li: {
    listStyle: "initial",
  },
}));

export const SingleMomentumBenefitWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "3.2rem 0",
}));

export const SingleMomentumBenefitList = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",
}));
