import React, { useMemo } from "react";

import {
  ArenaContainer,
  ArenaImage,
  ArenaTitleText,
  BookOrView,
  FourBoxText,
  OffersButton,
  WonderlandCustomText,
} from "./WonderlandStyling";
import { Link } from "react-router-dom";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

const ArenaPerformance = ({ id, imgNum, title, date, url, bookOrView }) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return (
    <ArenaContainer id={`winter_arena_${id + 1}`}>
      <div>
        <ArenaImage
          src={`https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/${imgNum}.jpg`}
        />
        <FourBoxText>
          <ArenaTitleText>
            {title}
          </ArenaTitleText>
          <WonderlandCustomText size={2.4} mt={1}>
            {date}
          </WonderlandCustomText>
        </FourBoxText>
      </div>
      <OffersButton margin="3rem 0 0">
        <Link to={`${baseUrl}/entertainment/performance/${url}`}>
          {bookOrView("view", "#725F85", "#fff")}
        </Link>
      </OffersButton>
    </ArenaContainer>
  );
};

export default ArenaPerformance;
