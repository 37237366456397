import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import {
  amenityListUrl,
  stayOfferRecommendationUrl,
  stayPreviewUrl,
  stayRoomsDetailUrl,
  stayRoomsUrl,
} from "./apiUrls";
import { useIpInformation } from "./GlobalRequests";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// Stay.jsx
export function useGetStayOfferRecommendation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getStayOfferRecommendation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${stayOfferRecommendationUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["stayOfferRecommendation", language],
    getStayOfferRecommendation,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// TowerPreview.jsx
export function useGetStayTowerPreview() {
  const { ipInformation } = useIpInformation();

  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getStayTowerPreivew = useCallback(async () => {
    const { status, data } = await axios.get(
      `${stayPreviewUrl}&language=${language}&code=${ipInformation?.countryCode}`,
    );

    if (status === 200) {
      return data.data;
    }
  }, [language, ipInformation]);

  return useQuery(
    ["stayTowerPreview", language, ipInformation],
    getStayTowerPreivew,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!ipInformation?.countryCode,
    },
  );
}

// TowerPreview.jsx
export function useGetTowerRooms(categoryId) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getTowerRooms = useCallback(async () => {
    const { status, data } = await axios.get(
      `${stayRoomsUrl}&language=${language}&category_name=${encodeURIComponent(
        categoryId,
      )}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language, categoryId]);

  return useQuery(["towerRooms", language, categoryId], getTowerRooms, {
    notifyOnChangeProps: "tracked",
    enabled: !!categoryId,
  });
}

// RoomDetail.jsx
export function useGetRoomDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getRoomDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${stayRoomsDetailUrl}?language=${language}&url_alias=/${alias}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(["roomDetail", language, alias], getRoomDetail, {
    notifyOnChangeProps: "tracked",
  });
}

// RoomDetail.jsx for left and right arrow
export function useGetRoomAliasGroup(categoryId) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getRoomAliasGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${stayRoomsUrl}&language=${language}&category_name=${encodeURIComponent(
        categoryId,
      )}`,
    );

    if (status === 200) {
      return data
        ?.filter((el) => el?.field_display_details === "True")
        .map((item) => {
          return item.url_alias;
        });
    }
  }, [language, categoryId]);

  return useQuery(["roomUuidGroup", language, categoryId], getRoomAliasGroup, {
    notifyOnChangeProps: "tracked",
  });
}

// Amenities.jsx
export function useGetAmenityList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAmenityList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${amenityListUrl}&language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["amenityList", language], getAmenityList, {
    notifyOnChangeProps: "tracked",
  });
}

// AmenityDetail.jsx for left and right arrow
export function useGetAmenityAliasGroup() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAmenityAliasGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${amenityListUrl}&language=${language}`,
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language]);

  return useQuery(["amenityUuidGroup", language], getAmenityAliasGroup, {
    notifyOnChangeProps: "tracked",
  });
}

// AmenityDetail.jsx
export function useGetAmenityDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAmenityDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${amenityListUrl}&language=${language}&url_alias=/${alias}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(["amenityDetail", language, alias], getAmenityDetail, {
    notifyOnChangeProps: "tracked",
  });
}
