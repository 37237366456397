import { calendarModalZIndex } from "@assets/styles/CommonStyles";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";
import { mq, mqMax } from "@utils/media-query/mediaQuery";
import {
  changePrimaryFontFamily,
  changeSecondaryFontFamily,
} from "@utils/modify-data/modifyData";

export const image = {
  // NOTE: images for all pages
  basicLogo: require("@assets/images/inspire-logo-basic.svg"),
  whiteLogo: require("@assets/images/inspire-logo-white.svg"),
  logoWhiteFull: require("@assets/images/logo-white-full.svg"),
  ogMedia: require("@assets/images/og-image.png"),
  sliderArrow: require("@assets/images/slider-arrow.svg"),
  sliderArrowBlack: require("@assets/images/slider-arrow-black.svg"),
  downArrow: require("@assets/images/down-arrow.svg"),
  angleDown: require("@assets/images/angle-down.svg"),
  downArrowWhite: require("@assets/images/down-arrow-white.svg"),
  bookNowIcon: require("@assets/images/book-now-icon.svg"),
  bookNowIconWhite: require("@assets/images/book-now-icon-white.svg"),
  linkArrow: require("@assets/images/link-arrow.svg"),
  linkArrowBlue: require("@assets/images/link-arrow-blue.svg"),
  linkArrowBlack: require("@assets/images/link-arrow-black.svg"),
  linkArrowPurple: require("@assets/images/link-arrow-purple.svg"),
  phoneIcon: require("@assets/images/phone-icon.svg"),
  emailIcon: require("@assets/images/email-icon.svg"),
  checkIcon: require("@assets/images/check-icon.svg"),
  downloadIcon: require("@assets/images/download-icon.svg"),
  modalCloseIcon: require("@assets/images/modal-close-icon.svg"),
  modalCloseWhiteIcon: require("@assets/images/modal-close-white.svg"),
  footerBackground: require("@assets/images/footer-background.png"),
  footerBackgroundMobile: require("@assets/images/footer-background-mobile.png"),
  faqAngleDown: require("@assets/images/faq-angle-down.svg"),
  videoPlayButton: require("@assets/images/video-play-button.svg"),
  calendarArrow: require("@assets/images/calendar-arrow.svg"),
  timeIcon: require("@assets/images/time-icon.svg"),
  plusIcon: require("@assets/images/plus-icon.svg"),
  performanceBg: require("@assets/images/performance-background.jpg"),
  notificationIcon: require("@assets/images/notification-icon.svg"),
  notificationArrow: require("@assets/images/notification-arrow.svg"),
  learnMoreArrow: require("@assets/images/learn-more-arrow.svg"),
  mapPing: require("@assets/images/map-ping.svg"),
  mapPingYellow: require("@assets/images/map-ping-yellow.svg"),
  articleLogo: require("@assets/images/article-logo.svg"),
  viewDetailArrow: require("@assets/images/view-detail-arrow.svg"),
  notFoundHero: require("@assets/images/not-found-hero.jpg"),
  roomReservationBg: require("@assets/images/room-reservation-bg.png"),
  shareIcon: require("@assets/images/share-icon.svg"),
  flagIcon: require("@assets/images/flag-icon.svg"),
  momentumThumbnail: require("@assets/images/momentum-thumbnail.jpeg"),
  datepickerIcon: require("@assets/images/datepicker-icon.svg"),
  playIcon: require("@assets/images/play-icon.svg"),
  logoWhiteMini: require("@assets/images/logo-white-mini.svg"),
  showPasswordIcon: require("@assets/images/show-password-icon.svg"),
  linkedInLogo: require("@assets/images/linked-in-logo.svg"),
  toggleDownPurple: require("@assets/images/toggle-button-purple.svg"),
  dividerLogo: require("@assets/images/divider-logo.svg"),

  // dummy
  offerEmptyThumbnail: require("@assets/images/offer-empty-thumbnail.jpg"),
  resThumb01: require("@assets/images/cafe-thumbnail.jpg"),
  myProfileDemoHero: require("@assets/images/my-profile-demo-hero.jpg"),
  inspireLifeMomentum: require("@assets/images/inspire-life-momentum.png"),
  inspireLife: require("@assets/images/inspire-life.png"),
  inspireElite: require("@assets/images/inspire-elite.jpg"),
  inspirePrestige: require("@assets/images/inspire-prestige.jpg"),
  singleMomentumBenefit: require("@assets/images/single-momentum-benefit.jpg"),
  myProfileAccount: require("@assets/images/my-profile-account.jpg"),
  demoCards: require("@assets/images/demo-cards.png"),
};

export const color = {
  white: "#ffffff",
  base_black: "#111",
  text_color: "#524d56",
  red_color: "#B01A35",
  primary_color: "#6F5C80",
  primary_dark_color: "#22033D",
  primary_light_color: "#9C7BB7",
  primary_blur_color: "#F3E6FF",
  primary_dimmed_color: "#FBF6FE",
  point_primary_color: "#8A47C1",
  secondary_color: "#E7AF1B",
  tertiary_color: "#7C8FAC",
  blue_point_color: "#1766E5",
  blue_light_color: "#F2F7FF",
  light_blue: "#C0C9D9",
  brown_color: "#C98852",
  primary_grey: "#454545",
  dark_grey: "#27222A",
  secondary_grey: "#676767",
  pale_grey: "#5B5B5B",
  medium_grey: "#979797",
  tertiary_grey: "#B9B9B9",
  light_grey: "#CDCCCC",
  grey_white: "#E9E9E9",
  light_white: "#F5F5F5",
};

export const AppTheme = {
  image,
  color,
  opacity: {
    overlay02: "rgba(0, 0, 0, 0.4)",
    colored_overlay: "rgba(104, 80, 124, 0.8)",
    colored_overlay_06: "rgba(104, 80, 124, 0.6)",
  },
  fontFamily: {
    primaryFont: changePrimaryFontFamily(),
    secondaryFont: changeSecondaryFontFamily(),
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 600,
    extraBold: 700,
    Black: 800,
  },
  shadow: {
    primary: "0 3px 10px rgba(0, 0, 0, 0.2)",
    secondary: "0 1rem 5rem rgba(0,0,0,0.2)",
    tertiary: "-2rem 0 2rem rgba(0,0,0,0.2)",
  },
  // ----------------------- customizing MUI
  typography: {
    fontFamily: changePrimaryFontFamily(),
    htmlFontSize: 10,

    [mq("mobile")]: {
      fontSize: 14,
    },

    [mq("desktop")]: {
      fontSize: 16,
    },
  },
  palette: {
    primary: {
      main: "#68507C",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // contained button style
          ...(ownerState.variant === "contained" && {
            color: "white",
            textTransform: "uppercase",
            fontWeight: 500,
            fontSize: "1.4rem",
            textAlign: "center",
            backgroundColor: theme.color.primary_color,
            borderRadius: "1rem",
            fontFamily: theme.fontFamily.primaryFont,

            ":hover": {
              backgroundColor: theme.color.secondary_color,
            },
          }),
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // contained button style
          padding: {
            top: ".8rem",
            bottom: ".8rem",
          },
          ":hover": {
            backgroundColor: "#F1E5FD",
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: () => ({
          width: "100%",
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          alignItems: "flex-start",

          "& .MuiCheckbox-root": {
            padding: "0.4rem 0.9rem",
          },

          "& .MuiFormControlLabel-label": {
            overflowWrap: isJapanese ? "anywhere" : "normal",
            fontFamily: theme.fontFamily.secondaryFont,
            fontSize: "1.8rem",
            color: theme.color.primary_grey,
          },

          "& .MuiSvgIcon-root": { fontSize: 20 },

          [mq("desktop")]: {
            alignItems: "center",
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "0.8rem",
          justifyContent: "space-between",
          fontFamily: theme.fontFamily.secondaryFont,
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: () => ({
          margin: 0,
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.color.primary_grey,
          fontFamily: theme.fontFamily.primaryFont,
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: () => ({
          fontSize: "1.4rem",

          ".MuiBreadcrumbs-separator": {
            marginInline: "1rem",
          },

          ol: {
            justifyContent: "center",
          },

          [mq("desktop")]: {
            fontSize: "1.8rem",

            ".MuiBreadcrumbs-separator": {
              marginInline: "1.2rem",
            },
          },
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "rounded" && {
            borderRadius: "2.5rem",
          }),
          ...(ownerState.variant === "rectangular" && {
            borderRadius: "1.5rem",
          }),
          ...(ownerState.variant === "edge" && {
            borderRadius: "0.3rem",
          }),
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: () => ({
          "&:last-of-type": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },

          "&:before": {
            height: 0,
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "2.5rem",
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: () => ({
          zIndex: calendarModalZIndex,
        }),
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          display: "none",
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: "32rem",
          maxHeight: "none",
          paddingBottom: "2rem",

          ".MuiDateCalendar-viewTransitionContainer": {
            display: "flex",
            justifyContent: "center",
          },

          ".MuiDayCalendar-slideTransition": {
            minHeight: "24rem",
          },

          [mq("desktop")]: {
            width: "36rem",
            borderRadius: "2.5rem",
            boxShadow: theme.shadow.primary,
          },
        }),
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: theme.fontWeight.bold,
          borderRadius: 5,
          borderWidth: 0,
          border: "0",

          "&:hover": {
            background: theme.color.primary_color,
            color: theme.color.white,
          },

          "&.Mui-selected": {
            fontWeight: theme.fontWeight.bold,
          },

          "&.MuiPickersDay-today": {
            position: "relative",
            border: 0,
            background: "transparent",

            ":after": {
              position: "absolute",
              content: '""',
              bottom: "0.4rem",
              left: "50%",
              transform: "translate(-50%, 0)",
              width: "1.8rem",
              height: "0.2rem",
              borderRadius: "0.2rem",
              background: theme.color.primary_color,
            },
          },
        }),

        today: {
          borderRadius: 5,
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontWeight: 700,
          color: "#222222",
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          justifyContent: "center",
          textAlign: "center",
          marginBottom: "2.4rem",
          marginTop: "2.4rem",

          ".MuiPickersCalendarHeader-labelContainer": {
            marginRight: 0,
          },

          ".MuiPickersArrowSwitcher-root": {
            // display: 'none',

            ".MuiIconButton-edgeEnd": {
              left: 0,
              top: "45%",
              position: "absolute",
              zIndex: "999",
              color: theme.color.base_black,
            },

            ".MuiIconButton-edgeStart": {
              position: "absolute",
              right: 0,
              top: "45%",
              zIndex: "999",
              color: theme.color.base_black,
            },
          },
        }),

        label: {
          color: "#222",
          fontWeight: 700,
          textTransform: "uppercase",
          fontSize: isJapanese ? "1.8rem" : "2rem",
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.severity === "error" && {
            fontFamily: theme.fontFamily.primaryFont,
            fontSize: "1.8rem",
            fontWeight: theme.fontWeight.medium,
            marginBottom: "3rem",

            ".MuiAlert-icon": {
              alignItems: "center",
              justifyContent: "center",
            },
          }),
          ...(ownerState.variant === "success" && {
            backgroundColor: theme.color.primary_blur_color,
            color: theme.color.primary_dark_color,
            fontFamily: theme.fontFamily.primaryFont,
            fontSize: "1.8rem",
            fontWeight: theme.fontWeight.medium,

            ".MuiAlert-icon": {
              alignItems: "center",
              justifyContent: "center",
            },

            svg: {
              fill: theme.color.primary_color,
            },
          }),
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "2.5rem",
          boxShadow: theme.shadow.primary,
          fontFamily: theme.fontFamily.primaryFont,

          ".MuiCardMedia-root": {
            position: "absolute",
            top: 0,
            width: "100%",
            height: "36rem",
            borderRadius: "0 0 5rem 5rem",
            aspectRatio: "1 / 1",
            transition: "all 0.3s",
          },

          ":hover .MuiCardMedia-root": {
            height: "32rem",
          },

          ".MuiCardContent-root": {
            position: "absolute",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            padding: "1.6rem",
            bottom: "0",
            height: "20rem",
            background: "white",
            transition: "all 0.3s",

            [mq("desktop")]: {
              padding: "3.2rem",
            },
          },

          ":hover .MuiCardContent-root": {
            height: "24rem",
          },
        }),
      },
    },
    MuiModal: {
      styleOverrides: {
        root: ({ theme }) => ({
          overflow: "auto",
          padding: "1.6rem",
        }),
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          [mqMax("desktop")]: {
            height: "auto",
          },

          ".MuiDialog-container": {
            height: "auto",
          },
        }),
      },
    },
  },
};

export default AppTheme;
