import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import { offerCategoryListUrl, offerListUrl, offerTypedUrl } from "./apiUrls";
import { useIpInformation } from "./GlobalRequests";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// Offers.jsx
export function useGetOfferCategoryList() {
  const { ipInformation } = useIpInformation();

  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getOfferCategoryList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${offerCategoryListUrl}&language=${language}&code=${ipInformation?.countryCode}`,
    );

    if (status === 200) {
      return data.data;
    }
  }, [language, ipInformation]);

  return useQuery(
    ["offerCategoryList", language, ipInformation],
    getOfferCategoryList,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!ipInformation?.countryCode,
    },
  );
}

export function useGetOfferList(filterType, page, search) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getOfferList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${offerListUrl}?language=${language}${
        filterType !== ""
          ? `&category_name=${encodeURIComponent(filterType)}`
          : ""
      }&page=${page - 1}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language, filterType, page]);

  return useQuery(
    ["offerList", language, filterType, page, search],
    getOfferList,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!language && !!filterType,
      refetchInterval: 60 * 1000 * 60, // 10 minutes
      staleTime: 60 * 1000 * 60, // 10 minutes
      cacheTime: 60 * 1000 * 60, // 10 minutes
    },
  );
}

export function useGetOfferDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getOfferDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${offerListUrl}?language=${language}&url_alias=/${alias}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(["offerDetail", language, alias], getOfferDetail, {
    notifyOnChangeProps: "tracked",
  });
}

export function useGetOfferDetailWithId(id) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getOfferDetailWithId = useCallback(async () => {
    const { status, data } = await axios.get(
      `${offerListUrl}?language=${language}&id=${id}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language, id]);

  return useQuery(["offerDetailWithId", language, id], getOfferDetailWithId, {
    notifyOnChangeProps: "tracked",
    enabled: !!id,
  });
}

// OfferTypedList.jsx
export function useGetTypedOffer(offerType) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getTypedOffer = useCallback(async () => {
    const { status, data } = await axios.get(
      `${offerTypedUrl}?language=${language}&category_name=${encodeURIComponent(
        offerType,
      )}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, offerType]);

  return useQuery(["typedOffer", language, offerType], getTypedOffer, {
    notifyOnChangeProps: "tracked",
    enabled: !!offerType && !!language,
  });
}
