import React from "react";

import PageTabButtons from "@components/item/PageTabButtons";

const PageWithTabs = ({ children, tabArr, currentTab, setCurrentTab }) => {
  return (
    <>
      <PageTabButtons
        tabArr={tabArr}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      {children}
    </>
  );
};

export default PageWithTabs;
