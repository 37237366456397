import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import {
  engageProfileUrl,
  engageTokenUrl,
  strivacityAdminUrl,
  strivacityTokenUrl,
} from "@utils/api-requests/apiUrls";
import {
  EXPIRE_DURATION,
  LOCAL_STORAGE_EXPIRE_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_STRIVACITY_ACCESS_TOKEN,
  STRIVACITY_API_KEY,
  STRIVACITY_NONCE,
  STRIVACITY_REDIRECT,
  STRIVACITY_Base_64,
} from "@utils/api-requests/static-data";
import { useLogoutUser } from "@utils/api-requests/userRequests";
import {
  ENGAGE_ACCESS_TOKEN_QUERY_KEY,
  ENGAGE_USER_INFORMATION_QUERY_KEY,
  STRIVACITY_ACCESS_TOKEN_QUERY_KEY,
  STRIVACITY_CONSENT_LIST_QUERY_KEY,
  STRIVACITY_REFRESH_TOKEN_QUERY_KEY,
} from "@utils/react-query/queryKey";

// ----------------------------------- A. Strivacity Admin apis
// NOTE: 1. get token
// export function useGetStrivacityAdminToken() {
//   const getStrivacityAdminToken = useCallback(async () => {
//     try {
//       const { status, data } = await axios.post(
//         `${strivacityAdminUrl}/oauth2/token`,
//         new URLSearchParams({
//           grant_type: "client_credentials",
//           audience: process.env.REACT_APP_STRIVACITY_ENDPOINT,
//           scope: "read:account write:account write:account_authenticator",
//         }),
//         {
//           headers: {
//             apikey: STRIVACITY_API_KEY,
//             "Content-Type": "application/x-www-form-urlencoded",
//             Accept: "application/json",
//             Authorization: `Basic ${STRIVACITY_ADMIN_Base_64}`,
//           },
//         },
//       );
//
//       if (status === 200) {
//         return data.access_token;
//       }
//     } catch (err) {}
//   }, []);
//
//   return useQuery(["strivacityAdminToken"], getStrivacityAdminToken, {
//     notifyOnChangeProps: "tracked",
//   });
// }

// NOTE: 2. get user data
// export function useGetStrivacityUserData(userId) {
//   const { data: adminToken } = useGetStrivacityAdminToken();
//
//   const getStrivacityUserData = useCallback(async () => {
//     try {
//       const { status, data } = await axios.get(
//         `${strivacityAdminUrl}/admin/api/v1/identityStores/Inspire/accounts/${userId}`,
//         {
//           headers: {
//             accept: "*/*",
//             apikey: STRIVACITY_API_KEY,
//             Authorization: `Bearer ${adminToken}`,
//           },
//         },
//       );
//
//       if (status === 200) {
//         return data;
//       }
//     } catch (err) {
//       if (err) {
//       }
//     }
//   }, [adminToken, userId]);
//
//   return useQuery(["strivacityAdminUserData"], getStrivacityUserData, {
//     notifyOnChangeProps: "tracked",
//     enabled: !!userId && !!adminToken,
//   });
// }

// NOTE: 3. Update user data
// export function useUpdateStrivacityUserData(userId, body) {
//   const updateStrivacityUserData = useCallback(async () => {
//     try {
//       const { status, data } = await axios.put(
//         `${strivacityAdminUrl}/admin/api/v1/identityStores/Inspire/accounts/${userId}/personal`,
//         body,
//         {
//           headers: {
//             accept: "*/*",
//             authorization: `Bearer ${adminToken}`,
//             "content-type": "application/json",
//             apikey: STRIVACITY_API_KEY,
//           },
//         },
//       );
//
//       if (status === 200) {
//         return data;
//       }
//     } catch (err) {}
//   }, [body, userId]);
//
//   return useQuery(
//     ["strivacityAdminUserData", userId, body],
//     updateStrivacityUserData,
//     {
//       notifyOnChangeProps: "tracked",
//     },
//   );
// }

// NOTE: 4. check current password
// export const useCheckCurrentPassword = async (data) => {
//   try {
//     return await axios.post(
//       `${strivacityAdminUrl}/simplelogin/api/v1/token`,
//       {
//         response_type: "token",
//         scope: "openid",
//         identifier: data.email,
//         password: data.password,
//       },
//       {
//         headers: {
//           accept: "application/json",
//           authorization: `Basic ${process.env.REACT_APP_STRIVACITY_BASIC}`,
//           "content-type": "application/json",
//           apikey: STRIVACITY_API_KEY,
//         },
//       },
//     );
//   } catch (error) {
//     return error;
//   }
// };

// NOTE: 5. update password
export const useUpdateStrivacityUserPassword = async ({ body }) => {
  try {
    return await axios.post(
      `${strivacityAdminUrl}/myaccount/api/v1/password`,
      body,
      {
        headers: {
          accept: "*/*",
          authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_STRIVACITY_ACCESS_TOKEN)}`,
          "content-type": "application/json",
          apikey: STRIVACITY_API_KEY,
        },
      },
    );
  } catch (error) {
    return error;
  }
};

// NOTE: 6. OPT-In
export const useOptInStrivacityUserConsent = async ({ consentId, body }) => {
  try {
    return await axios.post(
      `${strivacityAdminUrl}/myaccount/api/v1/consents/${consentId}/optIn`,
      body,
      {
        headers: {
          accept: "*/*",
          authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_STRIVACITY_ACCESS_TOKEN)}`,
          "content-type": "application/json",
          apikey: STRIVACITY_API_KEY,
        },
      },
    );
  } catch (error) {
    return error;
  }
};

// NOTE: 7. OPT-OUT
export const useOptOutStrivacityUserConsent = async ({ consentId, body }) => {
  try {
    return await axios.post(
      `${strivacityAdminUrl}/myaccount/api/v1/consents/${consentId}/optOut`,
      body,
      {
        headers: {
          accept: "*/*",
          authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_STRIVACITY_ACCESS_TOKEN)}`,
          "content-type": "application/json",
          apikey: STRIVACITY_API_KEY,
        },
      },
    );
  } catch (error) {
    return error;
  }
};

// NOTE: 8. consents list
export function useGetStrivacityConsent() {
  const getStrivacityConsent = useCallback(async () => {
    try {
      const { status, data } = await axios.get(
        `${strivacityAdminUrl}/myaccount/api/v1/consents`,
        {
          headers: {
            accept: "*/*",
            authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_STRIVACITY_ACCESS_TOKEN)}`,
            "content-type": "application/json",
            apikey: STRIVACITY_API_KEY,
          },
        },
      );

      if (status === 200) {
        return data;
      }
    } catch (err) {}
  }, []);

  return useQuery([STRIVACITY_CONSENT_LIST_QUERY_KEY], getStrivacityConsent, {
    notifyOnChangeProps: "tracked",
  });
}

// ----------------------------------- B. Strivacity apis
// NOTE: 1. get token
export function useGetStrivacityToken(code) {
  const logout = useLogoutUser();

  const getStrivacityUser = useCallback(async () => {
    if (localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) !== null) {
      return;
    }

    try {
      const { status, data } = await axios.post(
        strivacityTokenUrl, // * "https://signup-test.inspirekorea.com/oauth2/token"
        {
          grant_type: "authorization_code",
          code: code, //! code parameter value from url
          redirect_uri: STRIVACITY_REDIRECT,
          nonce: STRIVACITY_NONCE,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${STRIVACITY_Base_64}`,
          },
        },
      );

      if (status === 200) {
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, data?.refresh_token);

        const expireTime = new Date().getTime() + EXPIRE_DURATION * 60 * 1000; // 25 minutes later
        localStorage.setItem(LOCAL_STORAGE_EXPIRE_TOKEN, expireTime.toString());

        return data;
      }
    } catch (err) {
      if (err.response.status === 400) {
        logout();
      }
    }
  }, [code, logout]);

  return useQuery(
    [
      STRIVACITY_ACCESS_TOKEN_QUERY_KEY,
      code,
      localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN),
    ],
    getStrivacityUser,
    {
      notifyOnChangeProps: "tracked",
      enabled:
        !localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) ||
        !localStorage.getItem(LOCAL_STORAGE_EXPIRE_TOKEN),
    },
  );
}

// NOTE: 2. refresh token
export function usePostStrivacityRefreshToken() {
  const logout = useLogoutUser();

  const postRefreshToken = useCallback(async () => {
    const currentTime = new Date().getTime();
    const expireTime = localStorage.getItem(LOCAL_STORAGE_EXPIRE_TOKEN);

    if (currentTime > parseInt(expireTime, 10)) {
      // If expired, logout
      logout();

      return;
    }

    try {
      const { status, data } = await axios.post(
        strivacityTokenUrl, // * "https://signup-test.inspirekorea.com/oauth2/token"
        {
          grant_type: "refresh_token",
          refresh_token: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN), //! refresh token from localStorage.
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${STRIVACITY_Base_64}`,
          },
        },
      );

      if (status === 200) {
        // ! Reset refresh token in local storage
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, data?.refresh_token);

        localStorage.setItem(
          LOCAL_STORAGE_STRIVACITY_ACCESS_TOKEN,
          data?.access_token,
        );

        return data;
      }
    } catch (err) {
      if (err.response.status === 400) {
        logout();
      }
    }
  }, [logout]);

  return useQuery([STRIVACITY_REFRESH_TOKEN_QUERY_KEY], postRefreshToken, {
    notifyOnChangeProps: "tracked",
    enabled: !!localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN),
  });
}

// ----------------------------------- C. Engage apis
// NOTE: 1. token
export function useGetTokenEngage() {
  const getEngageToken = useCallback(async () => {
    const { status, data } = await axios.post(
      engageTokenUrl,
      process.env.REACT_APP_ENGAGE_TOKEN,
      {
        headers: {
          "x-gateway-key": process.env.REACT_APP_ENGAGE_X_GATEWAY_KEY,
          "Content-Type": "text/plain",
          apikey: STRIVACITY_API_KEY,
        },
      },
    );

    if (status === 200) {
      return data?.access_token;
    }
  }, []);

  return useQuery([ENGAGE_ACCESS_TOKEN_QUERY_KEY], getEngageToken, {
    notifyOnChangeProps: "tracked",
  });
}

// NOTE: 2. get profile
export function useGetMomentumPointsEngage(profileId) {
  const { data: accessToken } = useGetTokenEngage();
  const getMomentumPoints = useCallback(async () => {
    const { status, data } = await axios.get(
      `${engageProfileUrl}/${profileId}?corpcode=Inspire&sitecode=Inspire&systemcode=PMS&Fields=3`,
      {
        headers: {
          "x-gateway-key": process.env.REACT_APP_ENGAGE_X_GATEWAY_KEY,
          "Content-Type": "application/json",
          apikey: STRIVACITY_API_KEY,
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (status === 200) {
      return data;
    }
  }, [profileId, accessToken]);

  return useQuery(
    [ENGAGE_USER_INFORMATION_QUERY_KEY, profileId, accessToken],
    getMomentumPoints,
    {
      enabled: !!profileId && !!accessToken,
      notifyOnChangeProps: "tracked",
    },
  );
}
