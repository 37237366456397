import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Pagination,
} from "@mui/material";

import { color } from "src/theme";
import {
  CommonDescOne,
  CommonDescTwo,
  CommonTitleFive,
  CommonTitleFour,
  CommonTitleOne,
  CommonTitleSeven,
  CommonTitleSix,
  CommonTitleTwo,
  infoZIndex,
  overlayZIndex,
} from "@assets/styles/CommonStyles";
import { mq, mqMax } from "@utils/media-query/mediaQuery";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

const { default: styled } = require("@emotion/styled");

// STYLE: OfferRecommendation.jsx
export const OfferRecommendationContainer = styled.div(() => ({
  paddingTop: "6rem",

  [mq("desktop")]: {
    paddingTop: "12rem",
  },
}));

// STYLE: MainAdBanner.jsx
export const MainAdBannerContainer = styled.div(({ open }) => ({
  position: "relative",
  zIndex: 900,
  overflow: "hidden",
  width: "100%",
  height: open ? "auto" : 0,
  transition: "height 0.3s",

  ".main-banner-close": {
    position: "absolute",
    top: 0,
    right: 16,
    zIndex: 903,
    cursor: "pointer",
  },
}));

export const MainAdBannerImage = styled.img(() => ({
  display: "block",
}));

export const MainAdBannerButtons = styled(Link)(() => ({
  position: "relative",
  height: "3.6rem",
  width: "100%",
  cursor: "pointer",
  display: "block",
  right: 0,
  bottom: 1,
  zIndex: 902,

  [mq("desktop")]: {
    height: "100%",
    position: "absolute",
  },
}));

export const MainAdBannerWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "flex-end",
  justifyContent: "space-between",
  padding: "1rem 1.6rem",
  background: theme.color.light_blue,
  textTransform: "uppercase",
  color: theme.color.primary_color,
  fontWeight: theme.fontWeight.bold,

  [mq("desktop")]: {
    height: "100%",
    flexDirection: "column",
    textTransform: "initial",
    background: "transparent",
    padding: "1.6rem 0",
    color: theme.color.white,
  },

  button: {
    textTransform: "uppercase",
  },
}));

export const MainAdBannerCloseDesc = styled.button(({ theme }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  textTransform: "uppercase",
  fontWeight: theme.fontWeight.bold,
  fontFamily: theme.fontFamily.primary,
  fontSize: "1.4rem",
  cursor: "pointer",
  position: "absolute",
  zIndex: 903,

  [mqMax("desktop")]: {
    color: theme.color.primary_color,
    marginTop: 8,
    left: 16,
  },

  [mq("desktop")]: {
    bottom: 8,
    right: 16,
    color: theme.color.white,
    textTransform: "initial",
    fontFamily: theme.fontFamily.secondaryFont,
    fontWeight: theme.fontWeight.regular,
    borderBottom: `1px solid ${theme.color.white}`,
    textDecoration: "underline",
  },
}));

// STYLE: NotificationToggle.jsx
export const NotificationToggleContainer = styled.div(() => ({
  marginTop: "5rem",
}));

export const NotificationToggleAccordion = styled(Accordion)(({ theme }) => ({
  position: "relative",
  background: theme.color.primary_blur_color,
  boxShadow: "none",
  color: theme.color.primary_dark_color,
  padding: "1rem",

  "&.MuiPaper-rounded": {
    borderRadius: "1.5rem",
  },
}));

export const NotificationToggleHeader = styled(AccordionSummary)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "0 3rem",
    fontWeight: theme.fontWeight.bold,
  }),
);

export const NotificationToggleDetails = styled(AccordionDetails)(() => ({
  lineHeight: 1.5,
}));

export const NotificationToggleWrapper = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  "& > div": {
    display: "flex",
    gap: "0 0.6rem",
  },
}));

// STYLE: BoxInformationOne.jsx
export const BoxInformationOneContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "5rem",
  gap: "6rem 3.2rem",
  justifyContent: "space-between",

  "&>*": {
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const BoxInformationOneChildren = styled.div(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",

  "&>*": {
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },

  [mq("desktop")]: {
    gap: "5rem 0",
  },
}));

export const BoxInformationOneWrapper = styled.div(({ theme }) => ({
  height: "fit-content",
  padding: "3.2rem",
  borderRadius: "1.5rem",
  background: theme.color.primary_color,
  boxShadow: theme.shadow.primary,
  color: theme.color.white,

  [mq("desktop")]: {
    minWidth: "40rem",
    padding: "6rem 4rem",
    borderRadius: "2.5rem",
  },
}));

export const BoxInformationOneTitle = styled(CommonTitleTwo)(({ theme }) => ({
  textTransform: "uppercase",
  marginBottom: "1.6rem",

  [mq("desktop")]: {
    fontSize: "4rem",
  },
}));

export const BoxInformationOneList = styled.ul(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 0",
  maxWidth: "32rem",
}));

export const BoxInformationOneHeader = styled(CommonTitleFour)(({ theme }) => ({
  marginBottom: "0.8rem",

  [mq("desktop")]: {
    marginBottom: "2rem",
  },
}));

export const BoxInformationOneSubTitle = styled(CommonTitleFive)(() => ({
  marginBottom: "2rem",
}));

// STYLE: DangerouslyHtml.jsx
export const DangerouslyHtmlContainer = styled.div(({ custom }) => ({
  "& img": {
    maxWidth: "100%",
    height: "auto",
  },

  li: {
    marginBottom: "1rem",
  },

  "& ul": {
    paddingLeft: "3rem",

    "& li": {
      listStyle: "initial",
    },
  },

  "& ol": {
    paddingLeft: "3rem",

    li: {
      listStyle: "decimal",
    },
  },

  ...custom,
}));

// STYLE: BookingModal.jsx
export const BookingModalContainer = styled.div(() => ({
  padding: "1rem 0 2rem",
  maxHeight: "50vh",
  overflowY: "auto",
  minWidth: "32rem",

  [mq("desktop")]: {
    minWidth: "40rem",
  },
}));

export const BookingModalGuestsWrapper = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: theme.fontFamily.secondaryFont,
}));

export const BookingModalGuestsButtons = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0 1rem",
  fontWeight: theme.fontWeight.medium,
}));

export const BookingModalGuestsIn = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  cursor: "pointer",
}));

export const BookingModalGuestsButton = styled.button(
  ({ theme, inActive }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.6rem",
    width: "3.2rem",
    height: "3.2rem",
    border: `1px solid ${theme.color.light_grey}`,
    borderRadius: "0.5rem",
    color: theme.opacity.colored_overlay_06,
    pointerEvents: inActive ? "none" : "initial",
    cursor: inActive ? "default" : "pointer",

    "&:hover": {
      border: `1px solid ${theme.color.secondary_grey}`,
      color: theme.color.primary_color,
    },
  }),
);

export const BookingModalArrow = styled.img(({ active }) => ({
  transform: active ? "rotate(180deg)" : "",
}));

export const BookingModalWrapper = styled.div(({ theme }) => ({
  position: "relative",
  minWidth: "24rem",
}));

export const BookingSelectPeopleModalContainer = styled.div(({ theme }) => ({
  position: "absolute",
  top: "7rem",
  background: theme.color.white,
  width: "100%",
  zIndex: 1400,
  padding: "1.6rem",
  borderRadius: "1.5rem",
  boxShadow: theme.shadow.primary,

  ul: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem 0",

    li: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
}));

// export const BookingModalWrapper = styled.div(() => ({}));

// STYLE: BookYourInspire.jsx
export const BookYourInpireContainer = styled.div(({ theme, noMargin }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 0",
  minHeight: "15.2rem",
  padding: "1.6rem",
  borderRadius: "2.5rem",
  overflow: "hidden",
  background: theme.color.primary_color,
  marginTop: noMargin ? 0 : "6rem",

  [mq("desktop")]: {
    marginTop: noMargin ? 0 : "12rem",
    padding: "4rem",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "0 1.6rem",
  },
}));

export const BookYourInpireInformation = styled(CommonTitleFive)(
  ({ theme }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "1rem 0",
    color: theme.color.white,
    fontWeight: theme.fontWeight.medium,
    maxWidth: "100%",
  }),
);

export const BookYourInspireMenu = styled(CommonTitleSix)(({ theme }) => ({
  color: theme.color.secondary_color,
  fontSize: "1.4rem",
}));

// STYLE: ImageRelated.jsx
export const ImageWrapperFigure = styled.figure(
  ({ width, height, ratio, minHeight, radius, custom }) => ({
    position: "relative",
    width: width ? width : "100%",
    height: height ? height : "100%",
    minHeight: minHeight ? minHeight : "auto",
    aspectRatio: ratio ? ratio : "1/ 1",
    borderRadius: radius ? radius : "0",
    overflow: "hidden",

    iframe: {
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 2,
      aspectRatio: "16 / 9",

      [mq("desktop")]: {
        width: "135vw",
        aspectRatio: "5 / 2",
      },
    },
    ...custom,
  }),
);

export const ImageWrapperImage = styled.img(({ scale, radius, custom }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: radius ? radius : "0",
  inset: 0,
  transform: scale ? scale : "none",
  transition: "all 0.6s",
  transformOrigin: "bottom left",
  transitionTimingFunction: "ease-in-out",

  ...custom,
}));

export const VideoContainer = styled.div(() => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  inset: 0,
}));

export const ReactPlayerWrapper = styled(ReactPlayer)(() => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  inset: 0,

  video: {
    position: "absolute",
    width: "100%",
    height: "100% !important",
    objectFit: "cover",
    inset: 0,
  },
}));

// STYLE: Overlays.jsx
export const NormalOverlayContainer = styled.div(({ opacity, rgb }) => ({
  position: "absolute",
  inset: 0,
  width: "100%",
  height: "100%",
  background: `rgba(${rgb}, ${opacity})`,
  zIndex: overlayZIndex,
  transition: "all 0.3s",
}));

export const GradientOverlayContainer = styled.div(
  ({ opacity, direction }) => ({
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(${direction}, rgba(0, 0, 0, ${opacity}), rgba(0, 0, 0, 0))`,
    zIndex: overlayZIndex,
    transition: "all 0.3s",
  }),
);

export const CustomOverlayContainer = styled.div(
  ({ opacity1, opacity2, direction }) => ({
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(${direction}, rgba(0, 0, 0, ${opacity1}), rgba(0, 0, 0, ${opacity2}))`,
    zIndex: overlayZIndex,
    transition: "all 0.3s",
  }),
);

// STYLE: TabButtons.jsx
export const TabButtonsMobileContainer = styled.div(({ theme }) => ({
  position: "relative",
  fontWeight: theme.fontWeight.bold,
  fontSize: "1.6rem",
  marginBottom: "2rem",
}));

export const TabButtonsMobileActive = styled.div(() => ({
  display: "flex",
  gap: "0 0.5rem",
  alignItems: "center",
  cursor: "pointer",
}));

export const TabButtonsMobileWrapper = styled.ul(({ theme }) => ({
  position: "absolute",
  zIndex: 900,
  background: theme.color.white,
  borderRadius: "1.5rem",
  boxShadow: theme.shadow.primary,
  padding: "1.6rem",
}));

export const TabButtonItem = styled.li(({ active, theme }) => ({
  cursor: active ? "default" : "pointer",
  pointerEvents: active ? "none" : "initial",
  paddingTop: "1.2rem",
  color: active ? theme.color.point_primary_color : theme.color.base_black,

  ":not(:last-of-type)": {
    borderBottom: `1px solid ${theme.color.light_grey}`,
    paddingBottom: "1.2rem",
  },
}));

export const TabButtonsContainer = styled.div(() => ({
  margin: "0 1.6rem 2rem",
  maxWidth: "100%",

  [mq("desktop")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 1.6rem 6rem",
  },
}));

export const TabButtonsWrapper = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
  overflowY: "hidden",
  padding: "0 0 2rem",
  margin: "0 auto",

  [mq("desktop")]: {
    padding: 0,
  },
}));

export const TabButton = styled(CommonTitleSeven)(
  ({ theme, active, color }) => ({
    padding: "0 1rem",
    cursor: "pointer",
    textAlign: "center",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    borderRight: `3px solid ${theme.color.grey_white}`,
    color: active ? theme.color.point_primary_color : theme.color.base_black,
    letterSpacing: "0.2rem",
    transition: "all 0.3s",

    "&:last-of-type": {
      borderRight: "none",
    },

    "&:hover": {
      color: theme.color.point_primary_color,
    },

    [mq("desktop")]: {
      padding: "0 4rem",
    },

    ...color,
  }),
);

export const TabButtonBlacked = (active) => ({
  color: active ? color.secondary_color : color.white,
  borderRight: `3px solid ${color.primary_color}`,

  ":hover": {
    color: color.secondary_color,
  },
});

// STYLE: PageTabButtons.jsx
export const PageTabButtonsContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: "0 3.2rem",
  fontSize: "2rem",
  fontWeight: theme.fontWeight.bold,
}));

export const PageTabButtonsItem = styled.button(({ theme, active }) => ({
  height: "4rem",
  borderBottom: active
    ? `0.4rem solid ${theme.color.primary_color}`
    : "0.4rem solid transparent",

  ":hover": {
    borderBottom: `0.4rem solid ${theme.color.primary_color}`,
  },
}));

// STYLE: MuiBreadcrumbs.jsx
export const BreadcrumbContainer = styled.div(({ theme, marginBottom }) => ({
  position: "relative",
  margin: "3rem 0",
  padding: "3.2rem 0",
  textTransform: "uppercase",
  textAlign: "center",
  borderRadius: "1.5rem",

  [mq("desktop")]: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "5rem 1.2rem 12rem",
    padding: "6.4rem 7rem",
    borderRadius: "2.5rem",
    boxShadow: theme.shadow.secondary,
  },
}));

export const StyledBreadcrumbs = styled(Breadcrumbs)(() => ({
  svg: {
    width: "6px",
    height: "10px",
  },
}));

export const BreadcrumbPageArrow = styled(Link)(({ theme, direction }) => ({
  position: "absolute",
  top: "7rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "3.2rem",
  height: "3.2rem",
  borderRadius: "50%",
  background: theme.color.light_grey,
  left: direction === "left" ? "-1.2rem" : "initial",
  right: direction === "left" ? "initial" : "-1.2rem",
  transition: "all 0.3s",

  "&:hover": {
    background: theme.color.secondary_color,
  },

  [mq("tablet")]: {
    width: "4.2rem",
    height: "4.2rem",
  },

  [mq("desktop")]: {
    width: "7.2rem",
    height: "7.2rem",
    top: "9rem",
    left: direction === "left" ? "-3.6rem" : "initial",
    right: direction === "left" ? "initial" : "-3.6rem",
  },
}));

export const BreadcrumbInfo = styled(CommonTitleSix)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1.4rem",

  [mq("desktop")]: {
    maxWidth: "90%",
  },
}));

export const BreadcrumbBefore = styled.span(({ theme }) => ({
  color: theme.color.secondary_grey,
}));

export const BreadcrumbNow = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeight.bold,
  color: theme.color.primary_color,
}));

export const PageTitle = styled(CommonTitleOne)(({ theme, noMargin }) => ({
  marginBottom: noMargin ? 0 : "1.6rem",
  marginTop: "0.4rem",
  color: theme.color.primary_color,
  maxWidth: "80%",
  fontSize: "2.5rem",

  [mq("desktop")]: {
    marginTop: 0,
    maxWidth: "100%",
    fontSize: isJapanese ? "3.6rem" : "4.8rem",
    marginBottom: noMargin ? 0 : "2.4rem",
  },
}));

export const SubTitle = styled(CommonTitleFour)(({ theme }) => ({
  color: theme.color.primary_grey,
  marginTop: "1rem",
}));

export const BreadcrumbDesc = styled(CommonDescTwo)(
  ({ textAlign, coverImage }) => ({
    display: "flex",
    gap: "0 3.2rem",
    lineHeight: 1.8,
    textTransform: "initial",
    fontSize: "1.4rem",
    textAlign: coverImage ? "left" : textAlign,
    flexDirection: "column",

    [mq("desktop")]: {
      width: "100%",
      maxWidth: "100%",
      padding: 0,
      flexDirection: "row",
    },
  }),
);

export const CoverImageWrapper = styled.div(() => ({
  width: "100%",

  img: {
    borderRadius: "1.5rem",
  },

  [mq("desktop")]: {
    maxWidth: "50%",
  },
}));

export const BreadcrumbButtonCustom = {
  position: "absolute",
  bottom: "-3.6rem",
  left: "50%",
  transform: "translateX(-50%)",
  width: "fit-content",
  maxWidth: "none",
};

export const BreadcrumbButton = styled.button(() => ({}));

// STYLE: MuiPagination.jsx
export const PaginationContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "5.6rem",
}));

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  ".MuiPagination-ul": {
    gap: "0 1rem",
  },

  ".Mui-selected": {
    color: theme.color.point_primary_color,
  },
}));

// STYLE: PdfPreview.jsx
export const PdfPreviewContainer = styled.div(({ ratio }) => ({
  position: "relative",
  aspectRatio: ratio,
  overflow: "hidden",

  "&>div": {
    position: "absolute",
    objectFit: "cover",
    inset: 0,
    width: "100%",
    height: "100%",

    canvas: {
      width: "100% !important",
      height: "100% !important",
    },
  },
}));

export const PdfPreviewIcon = styled.button(({ theme }) => ({
  bottom: 0,
  right: 0,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: theme.shadow.secondary,
  width: "5.6rem",
  height: "5.6rem",
  borderRadius: "50%",
  background: theme.color.secondary_color,
  cursor: "pointer",
  zIndex: infoZIndex,

  [mq("desktop")]: {
    width: "7.2rem",
    height: "7.2rem",
  },
}));

export const PdfPreviewMapIcon = styled.button(({ theme }) => ({
  bottom: "1.6rem",
  right: "1.6rem",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: theme.shadow.secondary,
  width: "5.6rem",
  height: "5.6rem",
  borderRadius: "50%",
  background: theme.color.secondary_color,
  cursor: "pointer",
  zIndex: infoZIndex,

  [mq("desktop")]: {
    bottom: "5rem",
    right: "5rem",
    width: "7.2rem",
    height: "7.2rem",
  },
}));

export const PdfPreviewMapText = styled.p(({ theme }) => ({
  position: "absolute",
  bottom: "1.6rem",
  left: "1.6rem",
  zIndex: infoZIndex,
  color: theme.color.white,
  fontSize: "3.6rem",
  fontWeight: theme.fontWeight.Black,

  [mq("desktop")]: {
    left: "5rem",
    bottom: "5rem",
    fontSize: "5.2rem",
  },
}));

// STYLE: MapWithPings.jsx
export const MapAll = styled.div(() => ({
  position: "relative",
  width: "100%",
}));

export const PingWrapper = styled.div(() => ({
  position: "absolute",
  top: 0,
  zIndex: infoZIndex,

  "&:first-of-type": {
    left: "50%",
    top: "10%",
  },

  "&:last-of-type": {
    left: "30%",
    top: "2rem",
  },
}));

export const PingIn = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const PingImage = styled.div(() => ({
  position: "relative",
}));

export const PingThumbnail = styled.div(() => ({
  position: "absolute",
  top: "1.6rem",
  left: "0.6rem",
  borderRadius: "50%",
  width: "3.6rem",
  height: "3.6rem",
  overflow: "hidden",

  [mq("desktop")]: {
    top: "0.4rem",
    left: "0.85rem",
    width: "5rem",
    height: "5rem",
  },
}));

export const PingInformation = styled.div(({ theme }) => ({
  position: "absolute",
  top: "85%",
  maxWidth: "30rem",
  minWidth: "20rem",
  background: theme.color.white,
  padding: "1rem",
  borderRadius: "0.8rem",
  fontSize: "1.2rem",

  "&>header": {
    marginBottom: "0.4rem",
    fontSize: "1.4rem",
    fontWeight: theme.fontWeight.bold,
  },

  "&>p": {
    marginTop: "0.8rem",
    fontFamily: theme.fontFamily.secondaryFont,
  },

  [mq("desktop")]: {
    top: "100%",
    padding: "1.5rem 2rem",
    borderRadius: "1.5rem",
  },
}));

export const MapAllDesc = styled(CommonDescOne)(() => ({
  marginTop: "4rem",

  [mq("desktop")]: {
    marginTop: "8rem",
  },
}));

export const SectionDividerLogoContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 2rem",
  marginTop: "8rem",

  hr: {
    width: "12rem",
    height: "0.2rem",
    background: theme.color.grey_white,
  },
}));
