import React, { memo, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";

import { CommonDescFour, CommonTitleOne } from "@assets/styles/CommonStyles";
import { SecondaryButton, SwiperArrowButton } from "@components/buttons";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { GradientOverlay, NormalOverlay } from "@components/item/Overlays";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { MobileSlideText } from "@components/item/MobileScrollArrow";
import { DangerouslyHtml } from "@components/item";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { doubleData } from "@utils/modify-data/modifyData";
import {
  SliderFiveActiveItemWrapper,
  SliderFiveArrowWrapper,
  SliderFiveDesc,
  SliderFiveInformation,
  SliderFiveInformationWrapper,
  SliderFiveName,
  SliderFiveSliderList,
  SliderFiveSummary,
  SliderFiveWrapper,
} from "./SlidersStyling";
import { IsMobile } from "@utils/media-query/mediaQuery";

import he from "he";

const SliderFive = ({
  noMargin,
  data,
  nameKey,
  supTitle,
  subTitle,
  slideIndex,
  setSlideIndex,
  desc,
  descKey,
  desc2Key,
  linkTo,
  imageKey = "field_thumbnail",
  performance,
  linkEach,
  splashbay,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [changedData, setChangedData] = useState(data);
  const [slidesPerView, setSlidesPerView] = useState(2);

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const custom = {
    ...commonSwiperOptions,
    spaceBetween: 30,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
    loopedSlides: slidesPerView,
    breakpoints: {
      300: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 2,
      },
    },
  };

  useEffect(() => {
    setSlideIndex(data?.length - 1);
  }, [data?.length, setSlideIndex]);

  useEffect(() => {
    doubleData(slidesPerView, data, setChangedData, "repeat");
  }, [data, slidesPerView]);

  return (
    data?.length > 0 && (
      <SliderFiveWrapper noMargin={noMargin}>
        {!isMobile && (
          <SliderFiveActiveItemWrapper>
            <ImageWrapper radius="2.5rem">
              <GradientOverlay />

              <Image
                src={changedData?.[slideIndex]?.[imageKey][0]?.src}
                alt={changedData?.[slideIndex]?.[imageKey][0]?.alt}
              />
            </ImageWrapper>

            <SliderFiveInformation>
              <CommonDescFour uppercase>{supTitle}</CommonDescFour>

              <CommonTitleOne>
                {changedData?.[slideIndex]?.[nameKey] && (
                  <DangerouslyHtml
                    value={he.decode(changedData?.[slideIndex]?.[nameKey])}
                  />
                )}
              </CommonTitleOne>

              <SliderFiveDesc>
                {performance ? (
                  <DangerouslyHtml
                    value={`${changedData?.[slideIndex]?.[descKey]} ${changedData?.[slideIndex]?.[desc2Key] && "~"} ${
                      changedData?.[slideIndex]?.[desc2Key]
                    }`}
                  />
                ) : desc ? (
                  <DangerouslyHtml value={desc} />
                ) : (
                  <DangerouslyHtml
                    value={changedData?.[slideIndex]?.[descKey]}
                  />
                )}
              </SliderFiveDesc>

              <SecondaryButton
                linkTo={`${
                  splashbay
                    ? `${changedData?.[slideIndex]?.url_alias}`
                    : `${linkEach}${changedData?.[slideIndex]?.url_alias}`
                }`}
                buttonColor="white"
              >
                <ViewDetailsText />
              </SecondaryButton>
            </SliderFiveInformation>
          </SliderFiveActiveItemWrapper>
        )}

        <SliderFiveSliderList>
          <SliderFiveArrowWrapper>
            <SwiperArrowButton ref={navPrevRef} direction="left" />

            <MobileSlideText />

            <SwiperArrowButton ref={navNextRef} direction="right" />
          </SliderFiveArrowWrapper>

          <div>
            <Swiper
              {...custom}
              onResize={(swiper) => {
                setSlidesPerView(swiper.params.slidesPerView);
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              onSlideChange={(swiper) => {
                if (swiper.previousRealIndex < data?.length) {
                  setSlideIndex(swiper.previousRealIndex);

                  return;
                }

                setSlideIndex(swiper.previousRealIndex - data?.length);
              }}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
            >
              {changedData?.map((image, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    style={{
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <ImageWrapper>
                      <Image
                        src={image?.[imageKey][0]?.src}
                        alt={image?.[imageKey][0]?.alt}
                        radius={25}
                      />
                    </ImageWrapper>

                    <SliderFiveInformationWrapper>
                      <SliderFiveSummary>
                        {performance
                          ? `${image[descKey]} ${image[desc2Key] && "~"} ${
                              image[desc2Key]
                            }`
                          : subTitle}
                      </SliderFiveSummary>

                      <SliderFiveName className="ellipsis-2">
                        {image?.[nameKey] && (
                          <DangerouslyHtml
                            value={he.decode(image?.[nameKey])}
                          />
                        )}
                      </SliderFiveName>

                      <IsMobile>
                        <SecondaryButton
                          buttonColor="white"
                          linkTo={`${
                            splashbay
                              ? `${changedData?.[index]?.url_alias}`
                              : `${linkEach}${changedData?.[index]?.url_alias}`
                          }`}
                        >
                          <ViewDetailsText />
                        </SecondaryButton>
                      </IsMobile>
                    </SliderFiveInformationWrapper>

                    <IsMobile>
                      <NormalOverlay opacity="0.4" />
                    </IsMobile>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </SliderFiveSliderList>
      </SliderFiveWrapper>
    )
  );
};

export default memo(SliderFive);
