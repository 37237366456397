import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import {
  aboutUsCareersUrl,
  aboutUsCustomerCenterCategoryUrl,
  aboutUsCustomerCenterListUrl,
  aboutUsCustomerCenterPageUrl,
  aboutUsFloorGuideUrl,
  aboutUsFloorMapCategoryUrl,
  aboutUsGalleryCategoryUrl,
  aboutUsGalleryUrl,
  aboutUsGettingHereUrl,
  aboutUsHealthSafetyUrl,
  aboutUsHowtoGetHereCategoryUrl,
  aboutUsInspireResortUrl,
  aboutUsKnowMoreUrl,
  aboutUsMediaCenterListUrl,
  aboutUsMediaCenterPageUrl,
  aboutUsMoheganUrl,
  aboutUsPrivacyPolicyUrl,
  aboutUsRecentGalleryUrl,
  aboutUsSitemapUrl,
  aboutUsTermsConditionsUrl,
} from "./apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// AboutUsInspireResort.jsx
export function useGetAboutUsInspireResort() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsInspireResort = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsInspireResortUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsInspireResort", language], getAboutUsInspireResort, {
    notifyOnChangeProps: "tracked",
  });
}

// AboutUsMore.jsx
export function useGetAboutUsKnowMore() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsKnowMore = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsKnowMoreUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsKnowMore", language], getAboutUsKnowMore, {
    notifyOnChangeProps: "tracked",
  });
}

// AboutUsCareers.jsx
export function useGetAboutUsCareers() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsCareers = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsCareersUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsCareers", language], getAboutUsCareers, {
    notifyOnChangeProps: "tracked",
  });
}

// MoheganResortDetail.jsx
export function useGetAboutUsMohegan() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsMohegan = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsMoheganUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsMohegan", language], getAboutUsMohegan, {
    notifyOnChangeProps: "tracked",
  });
}

// FloorGuide.jsx
export function useGetAboutUsFloorGuide() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsFloorGuide = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsFloorGuideUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsFloorGuide", language], getAboutUsFloorGuide, {
    notifyOnChangeProps: "tracked",
  });
}

// FloorMap.jsx
export function useGetAboutUsFloorMapCategory() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsFloorMapCategory = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsFloorMapCategoryUrl}&language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["aboutUsFloorMapCategory", language],
    getAboutUsFloorMapCategory,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// AboutUsPrivacyPolicy.jsx
export function useGetAboutUsPrivacyPolicy() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsPrivacyPolicy = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsPrivacyPolicyUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsPrivacyPolicy", language], getAboutUsPrivacyPolicy, {
    notifyOnChangeProps: "tracked",
  });
}

// AboutUsTermsConditionsDetail.jsx
export function useGetAboutUsTermsConditions() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsTermsConditions = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsTermsConditionsUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["aboutUsTermsConditions", language],
    getAboutUsTermsConditions,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// AboutUsHealthSafety.jsx
export function useGetAboutUsHealthSafety() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsHealthSafety = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsHealthSafetyUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsHealthSafety", language], getAboutUsHealthSafety, {
    notifyOnChangeProps: "tracked",
  });
}

// AboutUsSitemap.jsx
export function useGetAboutUsSitemap() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsSitemap = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsSitemapUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsSitemap", language], getAboutUsSitemap, {
    notifyOnChangeProps: "tracked",
  });
}

// CustomerCenterDetail.jsx
export function useGetAboutUsCustomerCenterPage() {
  // Add a response interceptor
  // axios.interceptors.response.use(
  //   function (response) {
  //     // Check if response data is an empty object
  //     if (response.data && Object.keys(response.data).length === 0) {
  //       // If response data is empty, modify the response status to 500
  //       const modifiedResponse = {
  //         ...response,
  //         status: 500,
  //         statusText: 'Internal Server Error: Empty Response',
  //       };
  //       return Promise.reject(modifiedResponse);
  //     }
  //     return response;
  //   },
  //   function (error) {
  //     // Handle errors
  //     return Promise.reject(error);
  //   }
  // );

  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsCustomerCenterPage = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsCustomerCenterPageUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["aboutUsCustomerCenterPage", language],
    getAboutUsCustomerCenterPage,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// CustomerCenterDetail.jsx
export function useGetAboutUsCustomerCenterList(filterType, page) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsCustomerCenterList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsCustomerCenterListUrl}?language=${language}${
        filterType !== "all" ? `&type=${filterType}` : ""
      }&page=${page - 1}`,
    );

    if (status === 200) {
      return data;
    }
  }, [filterType, language, page]);

  return useQuery(
    ["aboutUsCustomerCenterList", language, filterType, page],
    getAboutUsCustomerCenterList,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

export function useGetAboutUsCustomerCenterCategory() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsCustomerCenterCategory = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsCustomerCenterCategoryUrl}&language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["aboutUsCustomerCenterCategory", language],
    getAboutUsCustomerCenterCategory,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// AboutUsGettingHere.jsx
export function useGetAboutUsGettingHere() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsGettingHere = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsGettingHereUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsGettingHere", language], getAboutUsGettingHere, {
    notifyOnChangeProps: "tracked",
  });
}

export function useGetAboutUsHowtoGetHere() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsHowtoGetHere = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsHowtoGetHereCategoryUrl}&language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["aboutUsHowtoGetHere", language], getAboutUsHowtoGetHere, {
    notifyOnChangeProps: "tracked",
  });
}

// AboutUsGallery.jsx
export function useGetAboutUsGallery() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsGallery = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsRecentGalleryUrl}?language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["aboutUsGallery", language], getAboutUsGallery, {
    notifyOnChangeProps: "tracked",
  });
}

// AboutUsGalleryDetail.jsx
export function useGetAboutUsGalleryDetail() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsGalleryDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsGalleryUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsGalleryDetail", language], getAboutUsGalleryDetail, {
    notifyOnChangeProps: "tracked",
  });
}

// AboutUsGalleryList.jsx
export function useGetAboutUsGalleryList(filterType) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsGalleryList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsGalleryUrl}?language=${language}`,
    );

    if (status === 200) {
      if (filterType !== "all") {
        return data[0].field_gallery_images.filter(
          (el) => el.field_notification_contents.id === filterType,
        )[0]?.field_multiple_images;
      }

      let concatenatedImages = [];

      data[0].field_gallery_images.forEach((item) => {
        concatenatedImages = concatenatedImages.concat(
          item.field_multiple_images,
        );
      });

      return concatenatedImages;
    }
  }, [filterType, language]);

  return useQuery(
    ["aboutUsGalleryList", language, filterType],
    getAboutUsGalleryList,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// AboutUsGalleryList.jsx
export function useGetAboutUsGalleryCategory() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsGalleryCategory = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsGalleryCategoryUrl}&language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["aboutUsGalleryCategory", language],
    getAboutUsGalleryCategory,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// AboutUsMediaCenter.jsx
export function useGetAboutUsMediaCenter() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsMediaCenter = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsMediaCenterPageUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["aboutUsMediaCenter", language], getAboutUsMediaCenter, {
    notifyOnChangeProps: "tracked",
  });
}

// MediaCenterList.jsx
export function useGetAboutUsMediaCenterList(page) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsMediaCenterList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsMediaCenterListUrl}?language=${language}&page=${page - 1}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language, page]);

  return useQuery(
    ["aboutUsMediaCenterList", language, page],
    getAboutUsMediaCenterList,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// MediaCenterArticle.jsx
export function useGetAboutUsMediaCenterArticle(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAboutUsMediaCenterArticle = useCallback(async () => {
    const { status, data } = await axios.get(
      `${aboutUsMediaCenterListUrl}?language=${language}&url_alias=/${alias}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["aboutUsMediaCenterArticle", language, alias],
    getAboutUsMediaCenterArticle,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}
