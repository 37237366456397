import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import {
  LOCAL_STORAGE_EXPIRE_TOKEN,
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_STRIVACITY_ACCESS_TOKEN,
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_USER_ID,
  QUERY_KEY_USER_LOGGED_IN,
} from "./static-data";
export const useLogoutUser = (noRoute) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return () => {
    if (!noRoute) {
      navigate(`${baseUrl}`);
    }

    queryClient.setQueryData(QUERY_KEY_USER_LOGGED_IN, false);

    queryClient.removeQueries("userProfile");

    localStorage.removeItem(LOCAL_STORAGE_USER_ID);

    localStorage.removeItem(LOCAL_STORAGE_USER);

    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);

    localStorage.removeItem(LOCAL_STORAGE_EXPIRE_TOKEN);

    localStorage.removeItem(LOCAL_STORAGE_STRIVACITY_ACCESS_TOKEN);
  };
};
