import React, { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { yupChangePasswordProfile } from "@components/inputs/yupValidation";
import { TextInput } from "@components/inputs";
import { PrimaryButton } from "@components/buttons";
import { ForgotPasswordModalForm } from "@components/modal/ModalStyling";
import {
  CurrentNewPasswordMatches,
  InvalidFormatPasswordText,
  InvalidNewPasswordText,
  InvalidOldPasswordText,
  PasswordServiceErrorText,
  ResetSubmitText,
  SubmitLoadingText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  ConfirmPasswordLabelText,
  NewPasswordLabelText,
  OldPasswordLabelText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";
import { useUpdateStrivacityUserPassword } from "@utils/api-requests/StrivacityReqeusts";
import { SignInPasswordIcon } from "@pages/momentum/sign-in/SignInStyling";
import { image } from "../../../../theme";

const ResetPasswordForm = ({ userId, setPasswordStep }) => {
  const [invalid, setInvalid] = useState("");

  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    newConfirm: false,
  });

  const { control, handleSubmit, setError } = useForm({
    resolver: yupResolver(yupChangePasswordProfile),
    mode: "onSubmit",
    defaultValues: {
      password: "",
      old_password: "",
      confirm_pw: "",
    },
  });

  const { mutate: resetPassMutate, isLoading: resetPassIsLoading } =
    useMutation(useUpdateStrivacityUserPassword, {
      onSuccess: (data) => {
        if (data.status === 204) {
          // when password change successfully done.
          setPasswordStep(1);

          return;
        }

        if (data?.response?.status === 400) {
          if (
            data?.response?.data?.fieldErrors[0].message ===
            "PasswordValidation"
          ) {
            // when user entered same password with current password
            setInvalid(<InvalidOldPasswordText />);

            setError("old_password");

            return;
          }

          if (
            data?.response?.data?.fieldErrors[0].message ===
            "PasswordHistoryViolation"
          ) {
            // when user entered same password with current password
            setInvalid(<CurrentNewPasswordMatches />);

            setError("password");

            return;
          }

          if (
            data?.response?.data?.fieldErrors[0].message ===
            "PasswordPolicyViolation"
          ) {
            // when user didn't include
            // Lowercase character (a-z)
            // Uppercase character (A-Z)
            // Number (0-9)
            // Special character (#$%&?!'()-.@^_`'{}~)
            setInvalid(<InvalidFormatPasswordText />);
            setError("password");

            return;
          }

          if (
            data?.response?.data?.fieldErrors[0].message ===
            "PasswordExclusionViolation"
          ) {
            // when user entered name in new password
            setInvalid(<InvalidNewPasswordText />);
            setError("password");

            return;
          }

          // when the password change is not working - 400 error
          setInvalid(<PasswordServiceErrorText />);
        }
      },
    });

  const handleShowPassword = useCallback((type) => {
    setShowPassword((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  }, []);

  const changePasswordFormSubmit = useCallback(
    (data) => {
      if (data) {
        resetPassMutate({
          body: {
            currentPassword: data.old_password,
            newPassword: data.password,
            skipPasswordPolicy: false,
          },
        });
      }
    },
    [resetPassMutate],
  );

  return (
    <ForgotPasswordModalForm onSubmit={handleSubmit(changePasswordFormSubmit)}>
      {invalid !== "" && (
        <Alert severity="error" sx={{ width: "100%", marginBottom: "16px" }}>
          {invalid}
        </Alert>
      )}

      <TextInput
        control={control}
        name="old_password"
        type={showPassword["old"] ? "text" : "password"}
        label={<OldPasswordLabelText />}
        endAdornment={
          <SignInPasswordIcon
            src={image.showPasswordIcon.default}
            alt="show passowrd"
            onClick={() => handleShowPassword("old")}
          />
        }
      />

      <TextInput
        control={control}
        name="password"
        type={showPassword["new"] ? "text" : "password"}
        label={<NewPasswordLabelText />}
        endAdornment={
          <SignInPasswordIcon
            src={image.showPasswordIcon.default}
            alt="show passowrd"
            onClick={() => handleShowPassword("new")}
          />
        }
      />

      <TextInput
        control={control}
        name="confirm_pw"
        type={showPassword["newConfirm"] ? "text" : "password"}
        label={<ConfirmPasswordLabelText />}
        endAdornment={
          <SignInPasswordIcon
            src={image.showPasswordIcon.default}
            alt="show passowrd"
            onClick={() => handleShowPassword("newConfirm")}
          />
        }
      />

      <PrimaryButton buttonType="submit" noArrow disable={resetPassIsLoading}>
        {resetPassIsLoading ? <SubmitLoadingText /> : <ResetSubmitText />}
      </PrimaryButton>
    </ForgotPasswordModalForm>
  );
};

export default ResetPasswordForm;
