import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import { ListWithTabs } from "@components/list";
import { BoxListSix } from "@components/box-list";
import { ErrorManage } from "@screens/index";
import {
  checkIncludes,
  concatAllValue,
  getSearchValue,
} from "@utils/modify-data/modifyData";
import {
  useGetRestaurantList,
  useGetRestaurantsCategory,
} from "@utils/api-requests/EatDrinkRequests";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import { SCROLL_INTO_VIEW_TIMER } from "@utils/api-requests/static-data";

const Restaurants = () => {
  const restaurantRef = useRef();

  const search = useLocation().search;
  const [isShop, setIsShop] = useState(false);

  const [filterType, setFilterType] = useState("all");

  const {
    data: restaurantsCategory,
    isLoading: categoryIsLoading,
    isError: categoryIsError,
    error: categoryError,
  } = useGetRestaurantsCategory();

  const restaurantCategories = useMemo(() => {
    return restaurantsCategory?.map((item) => {
      return {
        title: item.title,
        category_id: item.entity_id,
      };
    });
  }, [restaurantsCategory]);

  const {
    data: restaurantList,
    isFetching,
    isError,
    error,
  } = useGetRestaurantList(
    restaurantsCategory?.filter((el) => el.entity_id === filterType)[0]?.title,
  );

  useEffect(() => {
    if (restaurantList?.find((el) => el.field_shop_ !== "")) {
      setIsShop(true);
    } else {
      setIsShop(false);
    }
  }, [restaurantList]);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (search !== "") {
        scrollToTargetAdjusted(restaurantRef);

        setFilterType(
          restaurantCategories.find((element) =>
            checkIncludes(element.title, getSearchValue(search)),
          )?.category_id,
        );
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [restaurantCategories, search]);

  return (
    <div ref={restaurantRef}>
      <Box>
        <ListWithTabs
          tabArr={concatAllValue(restaurantCategories)}
          filterType={filterType}
          setFilterType={setFilterType}
          isLoading={categoryIsLoading}
          isError={categoryIsError}
          error={categoryError}
        >
          <ErrorManage isFetching={isFetching} isError={isError} error={error}>
            <BoxListSix
              data={restaurantList}
              nameKey="field_restaurant_name"
              typeKey="field_restaurant_concept"
              locationKey="field_dining_location"
              linkTo="/eat-and-drink"
              restaurant
            />
          </ErrorManage>
        </ListWithTabs>
      </Box>
    </div>
  );
};

export default memo(Restaurants);
