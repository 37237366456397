import React from "react";
import ArenaPerformance from "./ArenaPerformance";
import { ArenaListBox } from "./WonderlandStyling";

export default function WonderlandSwiper({ list, bookOrView }) {
  // {
  //   "imgNum": "7_7",
  //   "title": "ZED TELOS TOUR IN Korea",
  //   "date": "2025.03.01",
  //   "url": "zedd-telos-tour-in-korea"
  // }
  return (
    <ArenaListBox>
      {list.map((item, idx) => (
        <ArenaPerformance
          key={`winter_arena_${idx}`}
          id={idx}
          imgNum={item.imgNum}
          title={item.title}
          date={item.date}
          viewmore={item.viewmore}
          url={item.url}
          bookOrView={bookOrView}
        />
      ))}
    </ArenaListBox>
  );
}
