import React, { memo, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";
import { Backdrop } from "@mui/material";

import { modalOverHeaderZIndex } from "@assets/styles/CommonStyles";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { GradientOverlay } from "@components/item/Overlays";
import { ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtmlContainer } from "@components/item/ItemStyling";
import { ErrorManage } from "@screens/index";
import { ViewFullVideoText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

// swiper styling
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";
import "swiper/css/scrollbar";

import { image } from "src/theme";
import {
  MainBannerContainer,
  MainBannerIFrameWrapper,
  MainBannerIFrame,
  MainBannerIFrameClose,
  MainBannerImage,
  MainBannerSwiper,
  MainBannerViewMoreButton,
} from "./HomeStyling";

import { MainBannerBookingBanner } from ".";

const MainBanner = ({ data, isLoading, isError, error }) => {
  const [openVideo, setOpenVideo] = useState(false);

  const handleClose = () => {
    setOpenVideo(false);
  };
  const handleOpen = () => {
    setOpenVideo(true);
  };

  const custom = {
    ...commonSwiperOptions,
    spaceBetween: 0,
  };

  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <MainBannerContainer>
      <Backdrop
        sx={{ color: "#fff", zIndex: modalOverHeaderZIndex }}
        open={openVideo}
        onClick={handleClose}
      >
        <MainBannerIFrameWrapper>
          <MainBannerIFrameClose type="button">
            <img
              src={image.modalCloseWhiteIcon.default}
              alt="Close Modal"
              width={isDesktop ? 40 : 32}
              height={isDesktop ? 40 : 32}
              loading="lazy"
              onClick={handleClose}
            />
          </MainBannerIFrameClose>

          <MainBannerIFrame
            title="vimeo-player"
            src="https://player.vimeo.com/video/1043330564?h=6438ffffd6&title=0&byline=0&portrait=0"
            frameborder="0"
            scrolling="no"
            allow="fullscreen; picture-in-picture"
            allowfullscreen
          />
        </MainBannerIFrameWrapper>
      </Backdrop>

      <MainBannerSwiper {...custom}>
        <MainBannerViewMoreButton onClick={handleOpen} type="button">
          <div>
            <ViewFullVideoText />
          </div>
        </MainBannerViewMoreButton>

        <ErrorManage isLoading={isLoading} isError={isError} error={error}>
          {data?.[0]?.field_home_slider.map((banner, idx) => {
            return (
              <SwiperSlide key={idx}>
                <ImageWrapper custom={MainBannerImage}>
                  <GradientOverlay opacity={0.15} direction="to bottom" />

                  <DangerouslyHtmlContainer
                    dangerouslySetInnerHTML={{
                      __html: `
                      <video 
                      preload="auto"
                      autoplay="true"
                      loop="loop"
                      playsinline
                      muted="true"
                      volume="0">
                      <source src="https://inspireweb-assets.s3.ap-northeast-2.amazonaws.com/Videos/2024+WINTER+WONDERLAND.mp4" type="video/mp4" />
                      </video>
                    `,
                    }}
                  />
                </ImageWrapper>
              </SwiperSlide>
            );
          })}
        </ErrorManage>
      </MainBannerSwiper>

      <MainBannerBookingBanner />
    </MainBannerContainer>
  );
};

export default memo(MainBanner);
