import React, { useEffect } from "react";

import { LoadingScreen } from "@screens/index";

import { useRedirectToStrivacity } from "../../../hooks/useRedirectToStrivacity";
import {
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from "@utils/api-requests/static-data";
import { useNavigate } from "react-router-dom";

const AgilisysSignUp = () => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
  const navigate = useNavigate();

  const { redirectToSignUpPage } = useRedirectToStrivacity();

  useEffect(() => {
    if (
      localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) !== null &&
      localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN)
    ) {
      // NOTE: when user is logged in, redirect user to profile page
      navigate(`/${lang}/my-profile`);
    } else {
      redirectToSignUpPage();
    }
  }, [lang, navigate, redirectToSignUpPage]);

  return (
    <>
      {/* NOTE: loading screen before redirect to strivacity */}
      <LoadingScreen />
    </>
  );
};

export default AgilisysSignUp;
