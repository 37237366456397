import { mq, mqMax } from "@utils/media-query/mediaQuery";

const { default: styled } = require("@emotion/styled");

// STYLE: Wonderland.jsx

export const SoldOutText = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  color: theme.color.white,
  fontWeight: 100,
  fontSize: "2.4rem",
  textAlign: "center",
  textTransform: "uppercase",

  [mq("tablet")]: {
    fontSize: "2.8rem",
  },

  [mq("desktop")]: {
    fontSize: "4rem",
  },
}));

export const WonderlandContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "2rem",
  margin: "0 10rem",
  fontFamily: theme.fontFamily.primaryFont,
  backgroundColor: "#7d6992",
  position: "relative",
  [mqMax("tablet")]: {
    margin: 0,
  },
}));

export const WonderlandSnowContainer = styled.div(({ theme }) => ({
  position: "relative",
}));

export const WonderlandContentContainer = styled.div(({ theme }) => ({
  position: "relative",
  padding: "0 5%",
}));

export const KeyVisual = styled.div(() => ({
  width: "100%",
  height: "fit-content",
  maxHeight: "1050px",
  [mqMax("mobileSmall")]: {
    maxHeight: "250px",
  },
}));

export const WonderlandSection = styled.div(({ theme }) => ({
  width: "100%",
  background: "#f3f3f3",
  padding: "4rem",
  marginBottom: "3rem",
  [mqMax("mobileSmall")]: {
    padding: "4rem 2rem",
  },
}));

export const SwiperBox = styled.div(({ theme }) => ({
  margin: "2rem 0",
}));

export const FourBoxContainer = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "2rem",
  flexWrap: "wrap",
  // fontFamily: theme.fontFamily.primaryFont,
  [mqMax("mobileSmall")]: {
    flexDirection: "column",
  },
}));

export const FourBoxItem = styled.div(({ theme, padding }) => ({
  width: "calc(50% - 10px)",
  position: "relative",
  [mqMax("mobileSmall")]: {
    width: "100%",
  },
}));

export const ThreeBoxContainer = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "2rem",
  flexWrap: "wrap",
  marginTop: "3rem",
  // fontFamily: theme.fontFamily.primaryFont,
  [mq("tablet")]: {
    gap: "1rem",
  },
  [mq("desktop")]: {
    gap: "2rem",
  },
}));

export const ThreeBoxItem = styled.div(({ theme, padding, imgNum, color }) => ({
  width: "100%",
  position: "relative",
  background: "#C1E2F9",
  display: "flex",
  flexDirection: "column",

  padding: padding,
  gap: "8rem",
  backgroundImage: `url(https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/${imgNum}.jpg)`,
  backgroundSize: "cover",

  [mq("tablet")]: {
    gap: "0rem",
    width: "calc(33% - 10px)",
  },
  [mq("desktop")]: {
    gap: "8rem",
    width: "calc(33% - 10px)",
  },
}));

export const FourBoxImageAndText = styled.div(({ theme, padding }) => ({
  display: "flex",
  flexDirection: "column",
  width: "calc(50% - 10px)",
  position: "relative",
  padding: "2rem 2.5rem",
  background: "white",

  [mqMax("mobileSmall")]: {
    width: "100%",
  },
}));

export const FourBoxImage = styled.div(({ theme, padding }) => ({
  width: "100%",
  position: "relative",
}));

export const FourBoxTitleTextContainer = styled.div(({ theme, padding }) => ({
  position: "absolute",
  bottom: "12%",
  left: "5%",
  width: "100%",
}));

export const FourBoxTitleText = styled.div(({ theme, isBlack }) => ({
  fontSize: "1.5rem",
  fontWeight: 100,
  color: isBlack ? "white" : "inherit",

  [mq("tablet")]: {
    fontSize: `1.4rem`,
  },
  [mq("desktop")]: {
    fontSize: `3rem`,
  },
}));

export const FourBoxText = styled.div(({ theme, padding }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const FourBoxItemMask = styled.div(({ theme, mask }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: mask ? "rgba(0,0,0,0.5)" : "",
  color: "white",
  padding: "3rem 0 0 3rem",

  [mq("tablet")]: {
    padding: "1rem 0 0 1rem",
  },
  [mq("desktop")]: {
    padding: "3rem 0 0 3rem",
  },
}));

export const ItemMask = styled.div(({ isCenter }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0,0,0,0.5)",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  alignItems: isCenter ? "center" : "",
  justifyContent: "space-between",
  padding: "2rem",
  [mq("tablet")]: {
    padding: "1rem",
  },
  [mq("desktop")]: {
    padding: "2rem",
  },
}));

export const WonderlandDescription = styled.div(() => ({
  textAlign: "center",
}));

export const WonderlandImage = styled.img(() => ({
  width: "100%",
  verticalAlign: "middle",
}));

export const EndLessJourneryText = styled.div(
  ({ size, weight, padding, mt, color }) => ({
    fontSize: `${size ? size / 1.5 : 0}rem`,
    fontWeight: weight,
    padding: padding,
    marginTop: `${mt ? mt : 0}rem`,
    color: color ? color : "inherit",
    wordBreak: "auto-phrase",
    textAlign: "center",
    [mq("tablet")]: {
      fontSize: `1.2rem`,
    },
    [mq("desktop")]: {
      fontSize: `${size ? size : 0}rem`,
    },
  }),
);

export const WonderlandCustomText = styled.div(
  ({ size, weight, padding, mt, color }) => ({
    fontSize: `${size ? size / 1.5 : 0}rem`,
    fontWeight: weight,
    padding: padding,
    marginTop: `${mt ? mt : 0}rem`,
    color: color ? color : "inherit",
    wordBreak: "auto-phrase",
    whiteSpace: "pre-wrap",

    [mq("tablet")]: {
      fontSize: `${size ? size / 1.5 : 0}rem`,
    },
    [mq("desktop")]: {
      fontSize: `${size ? size : 0}rem`,
    },
  }),
);

export const OffersImage = styled.img(() => ({
  width: "100%",
  verticalAlign: "middle",
}));

export const OffersButton = styled.div(({ margin }) => ({
  fontSize: "2rem",
  color: "white",
  display: "flex",
  gap: "0.8rem",
  margin: margin ? margin : "1rem 0",
}));

export const EventButton = styled.div(({ margin }) => ({
  margin: margin ? margin : "4rem auto 0",
  [mq("tablet")]: {
    margin: margin ? margin : "2rem auto 0",
  },
  [mq("desktop")]: {
    margin: margin ? margin : "4rem auto 0",
  },
}));

export const BookOrView = styled.div(({ bColor, color }) => ({
  background: bColor ? bColor : "#7A7A7A",
  padding: ".8rem .8rem",
  width: "fit-content",
  fontSize: "1rem",
  fontWeight: 600,
  color: color ? color : "inherit",

  [mq("tablet")]: {
    fontSize: ".8rem",
  },
  [mq("desktop")]: {
    fontSize: "2rem",
    padding: "1rem 1.5rem",
  },
}));

export const CulinaryGrid = styled.div(() => ({
  marginTop: "2rem",
  display: "grid",
  gridTemplateAreas: `"a a b b c c"
    "d d d d d d" `,
  gridTemplateColumns: "auto",
  gap: "2.5rem",

  [mqMax("mobileSmall")]: {
    gridTemplateAreas: `"a a b b c c d d" `,
    display: "flex",
    flexDirection: "column",
  },
}));

export const CulinaryGridSection = styled.div(({ grid }) => ({
  gridArea: grid,
}));

export const GridItem = styled.div(({ theme, padding, imgNum }) => ({
  position: "relative",
  // display: "flex",
  // flexDirection: "column",
  // justifyContent: "space-between",
  maxWidth: "100%",
  gap: "8rem",
  [mqMax("mobileSmall")]: {
    // textAlign: "center",
  },
}));

export const WideItem = styled.div(({ theme, mt }) => ({
  position: "relative",
  marginTop: mt ? mt : 0,
  textAlign: "center",
  [mqMax("mobileSmall")]: {
    minHeight: "250px",
    textAlign: "left",
  },
}));

export const WideItemWithImage = styled.div(({ theme, mt }) => ({
  position: "relative",
  marginTop: mt ? mt : 0,
  textAlign: "center",
  backgroundImage:
    "url(https://webdevfiles.s3.ap-northeast-2.amazonaws.com/eventimg/2024wonderland/Final/8.jpg)",
  [mqMax("mobileSmall")]: {
    minHeight: "250px",
  },
}));

export const WonderlandMainEventText = styled.div(() => ({
  fontWeight: "bold",
  fontSize: `1.5rem`,
  whiteSpace: "pre-wrap",

  [mq("tablet")]: {
    fontSize: `1.2rem`,
  },
  [mq("desktop")]: {
    fontSize: `3.5rem`,
  },
}));

export const WonderlandMainEvenTitleText = styled.div(() => ({
  // fontSize: `5rem`,
  fontSize: `2rem`,
  fontWeight: "bold",
  margin: "1rem 0",

  [mq("tablet")]: {
    fontSize: `1.4rem`,
  },
  [mq("desktop")]: {
    fontSize: `5rem`,
  },
}));

export const WonderlandMainEvenDateText = styled.div(() => ({
  fontSize: `1rem`,
  [mq("tablet")]: {
    fontSize: `1.4rem`,
  },
  [mq("desktop")]: {
    fontSize: `2.5rem`,
  },
}));

export const WonderlandBoxEventTitleText = styled.div(() => ({
  fontSize: `3.5rem`,
  fontWeight: "bold",
  [mqMax("mobileSmall")]: {
    // fontSize: `1.5rem`,
  },
}));

export const ArenaImage = styled.img(() => ({
  width: "100%",
  verticalAlign: "middle",
}));

export const ArenaListBox = styled.div(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  justifyContent: "center",
  [mqMax("tablet")]: {
    gap: "4rem",
  },
  [mqMax("mobileSmall")]: {
    flexDirection: "column",
    gap: "2rem",
  },
}));

export const ArenaContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  // minHeight: "525px",
  padding: "1.5rem 2rem",
  background: "white",
  width: "calc(32% - 10px)",
  [mqMax("tablet")]: {
    gap: "2rem",
  },
  [mqMax("mobileSmall")]: {
    minHeight: "280px",
    width: "100%",
  },
}));

export const WonderPhotoTitle = styled.div(() => ({
  display: "flex",
  gap: "1rem",
  justifyContent: "center",
  [mqMax("mobileSmall")]: {
    display: "block",
  },
}));

export const WonderlandEventText = styled.div(({ lang }) => ({
  fontSize: `1.5rem`,
  fontWeight: "bold",

  [mq("tablet")]: {
    fontSize: `1.4rem`,
  },
  [mq("desktop")]: {
    fontSize: `${lang === "ja" ? 3 : 4}rem`,
  },
}));

export const WonderlandEventDate = styled.div(() => ({
  fontSize: `1.5rem`,
  fontWeight: "bold",
  marginTop: "1rem",
  [mqMax("mobileSmall")]: {},
  [mq("tablet")]: {
    fontSize: `1.4rem`,
  },
  [mq("desktop")]: {
    fontSize: `2.2rem`,
  },
}));

export const ImageBox = styled.div(() => ({
  display: "flex",
  width: "100%",
  gap: "2rem",
  [mqMax("tablet")]: {
    flexDirection: "column",
  },
}));

export const Image = styled.img(() => ({
  width: "calc(33% - 10px)",
  [mqMax("tablet")]: {
    width: "100%",
  },
}));

export const ArenaTitleText = styled.div(() => ({
  fontSize: "3rem",
  fontWeight: "bold",
  marginTop: "1rem",
  [mq("tablet")]: {
    fontSize: "1.8rem",
  },
  [mqMax("mobileSmall")]: {
    fontSize: `2.5rem`,
  },
}));
