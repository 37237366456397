import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import {
  eatDrinkOfferRecommendationUrl,
  restaurantDetailUrl,
  restaurantListUrl,
  restaurantSeonalPickUrl,
  restaurantSignatureUrl,
  restaurantsCatogoryUrl,
} from "./apiUrls";
import { useIpInformation } from "./GlobalRequests";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// EatDrink.jsx
export function useGetEatDrinkOfferRecommendation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEatDrinkOfferRecommendation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${eatDrinkOfferRecommendationUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["eatDrinkOfferRecommendation", language],
    getEatDrinkOfferRecommendation,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// Restaurants.jsx
export function useGetRestaurantsCategory() {
  const { ipInformation } = useIpInformation();

  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getRestaurantsCategory = useCallback(async () => {
    const { status, data } = await axios.get(
      `${restaurantsCatogoryUrl}&language=${language}&code=${ipInformation?.countryCode}`,
    );

    if (status === 200) {
      return data.data;
    }
  }, [language, ipInformation]);

  return useQuery(
    ["restaurantsCategory", language, ipInformation],
    getRestaurantsCategory,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!ipInformation?.countryCode,
    },
  );
}

// Restaurants.jsx
export function useGetRestaurantList(categoryId) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getRestaurantList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${restaurantListUrl}&language=${language}${
        categoryId && categoryId !== ""
          ? `&category_name=${encodeURIComponent(categoryId)}`
          : ""
      }`,
    );

    if (status === 200) {
      return data;
    }
  }, [language, categoryId]);

  return useQuery(["restaurantList", language, categoryId], getRestaurantList, {
    notifyOnChangeProps: "tracked",
    enabled: !!language,
  });
}

// RestaurantDetail.jsx
export function useGetRestaurantDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getRestaurantDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${restaurantDetailUrl}?language=${language}&url_alias=/${alias}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(["restaurantDetail", language, alias], getRestaurantDetail, {
    notifyOnChangeProps: "tracked",
  });
}

// RestaurantDetail.jsx
export function useGetSeasonalPick() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getSeasonalPick = useCallback(async () => {
    const { status, data } = await axios.get(
      `${restaurantSeonalPickUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["seasonalPick", language], getSeasonalPick, {
    notifyOnChangeProps: "tracked",
  });
}

// RestaurantDetail.jsx for left and right arrow
export function useGetRestaurantAliasGroup(categoryId) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getRestaurantAliasGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${restaurantListUrl}&language=${language}&category_name=${encodeURIComponent(
        categoryId,
      )}`,
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language, categoryId]);

  return useQuery(
    ["restaurantAliasGroup", language, categoryId],
    getRestaurantAliasGroup,
    {
      notifyOnChangeProps: "tracked",
    },
  );
}

// SignatureMenu.jsx
export function useGetRestaurantSignatureList(type) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getRestaurantSignatureList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${restaurantSignatureUrl}?language=${language}&type=${type}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language, type]);

  return useQuery(
    ["restaurantSignatureList", language, type],
    getRestaurantSignatureList,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!type,
    },
  );
}
