import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { image } from "src/theme";
import { DangerouslyHtml } from "@components/item";
import { MomentumModal } from "@components/modal/modal-list";
import { ErrorManage } from "@screens/index";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  MobileMenuAccordion,
  MobileMenuAccordionDetails,
  MobileMenuAccordionSummary,
  MobileMenuArrow,
  MobileMenuArrowButton,
  MobileMenuDetailMenuTitle,
  MobileMenuDetailWrapper,
  MobileMenuModalContainer,
  MobileMenuModalUtilBar,
  MobileMenuModalUtilLink,
  MobileMenuSubmenuTitle,
  MobileMenuSummary,
  MobileMenuTitle,
  MobileSubMenuAccordion,
} from "./HeaderStyling";
import { SIGN_OUT_ENTITY_ID } from "./Header";
import { LanguageModal } from ".";
import { useLogoutUser } from "@utils/api-requests/userRequests";

const MobileMenuModal = ({
  active,
  setActive,
  headerList,
  utilbarArr,
  headerColored,
  isLoading,
  isError,
  error,
  languageModal,
  setLanguageModal,
}) => {
  const logout = useLogoutUser({ noRoute: true });

  const langModalRef = useRef(null);

  const [mainMenuExpanded, setMainMenuExpanded] = useState("");
  const [subMenuExpanded, setSubMenuExpanded] = useState("");

  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);

  const handleMainMenuChange = (main_menu) => {
    setMainMenuExpanded(main_menu);

    if (main_menu === mainMenuExpanded) {
      setMainMenuExpanded("");
    }

    if (subMenuExpanded !== "") {
      setSubMenuExpanded(false);
    }
  };

  const handleSubMenuChange = (sub_menu) => (_, isExpanded) => {
    setSubMenuExpanded(isExpanded ? sub_menu : false);
  };

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  const headerBlacked = useMemo(() => {
    if (headerColored || active) {
      return true;
    }

    return false;
  }, [headerColored, active]);

  const closeModal = useCallback(() => {
    setActive(false);
  }, [setActive]);

  const handleKeepInTouchModal = useCallback(
    (utilmenu) => {
      closeModal();

      if (utilmenu.entity_id === SIGN_OUT_ENTITY_ID) {
        logout();
      }
    },
    [closeModal, logout],
  );

  function useOutsideModalClicked(modalRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setLanguageModal(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [modalRef]);
  }

  useOutsideModalClicked(langModalRef);

  useEffect(() => {
    if (active) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, [active]);

  useEffect(() => {
    let vh = 0;

    const setVh = () => {
      vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVh();

    window.addEventListener("resize", setVh);

    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  return (
    <MobileMenuModalContainer active={active} headerColored={headerBlacked}>
      {active && (
        <MomentumModal
          openModal={signUpModalOpen}
          setOpenModal={setSignUpModalOpen}
          complete={complete}
          setComplete={setComplete}
        />
      )}

      <MobileMenuModalUtilBar>
        {utilbarArr?.map((utilmenu) => {
          return (
            <MobileMenuModalUtilLink
              key={utilmenu.entity_id}
              to={`${baseUrl}${utilmenu.url}`}
              onClick={(e) => handleKeepInTouchModal(utilmenu, e)}
            >
              <span>{utilmenu.title}</span>
            </MobileMenuModalUtilLink>
          );
        })}
      </MobileMenuModalUtilBar>

      <LanguageModal
        langModalRef={langModalRef}
        headerBlacked={headerBlacked}
        languageModal={languageModal}
        setLanguageModal={setLanguageModal}
      />

      <ErrorManage isLoading={isLoading} isError={isError} error={error}>
        {headerList?.map((item, idx) => (
          <MobileMenuAccordion
            key={item.entity_id}
            expanded={mainMenuExpanded === `main_menu_${idx}`}
          >
            <MobileMenuAccordionSummary
              aria-controls="main_menu-content"
              id="main_menu-header"
            >
              <MobileMenuSummary>
                <MobileMenuTitle
                  to={`${baseUrl}${item.url}`}
                  onClick={closeModal}
                >
                  <DangerouslyHtml value={item.title} />
                </MobileMenuTitle>

                {item.children.length > 0 && (
                  <MobileMenuArrowButton
                    onClick={() => handleMainMenuChange(`main_menu_${idx}`)}
                  >
                    <MobileMenuArrow
                      src={image.angleDown.default}
                      alt="down arrow icon"
                      expanded={mainMenuExpanded === `main_menu_${idx}`}
                      height={8}
                    />
                  </MobileMenuArrowButton>
                )}
              </MobileMenuSummary>
            </MobileMenuAccordionSummary>

            <MobileMenuAccordionDetails>
              {item.children.map((submenu, index) => {
                return (
                  <MobileSubMenuAccordion
                    key={submenu.entity_id}
                    expanded={subMenuExpanded === `sub_menu_${index}`}
                    onChange={handleSubMenuChange(`sub_menu_${index}`)}
                  >
                    <MobileMenuAccordionSummary ismargin="true">
                      <MobileMenuSubmenuTitle>
                        <div>
                          <DangerouslyHtml value={submenu.title} />
                        </div>

                        <span>
                          {subMenuExpanded === `sub_menu_${index}` ? "-" : "+"}
                        </span>
                      </MobileMenuSubmenuTitle>
                    </MobileMenuAccordionSummary>

                    <MobileMenuAccordionDetails>
                      <MobileMenuDetailWrapper>
                        {submenu?.children?.map((detailMenu) => {
                          return (
                            <MobileMenuDetailMenuTitle
                              key={detailMenu.entity_id}
                            >
                              <Link
                                key={detailMenu.entity_id}
                                to={`${baseUrl}${detailMenu.url}${
                                  submenu.category_id !== null
                                    ? `/${submenu.category_id.replaceAll(
                                        " ",
                                        "-",
                                      )}`
                                    : ""
                                }${
                                  detailMenu.content_link !== ""
                                    ? `${detailMenu.content_link}`
                                    : ""
                                }${
                                  detailMenu?.category_id !== null
                                    ? `?category=${detailMenu.category_id}`
                                    : ""
                                }`}
                                onClick={closeModal}
                              >
                                <DangerouslyHtml value={detailMenu.title} />
                              </Link>
                            </MobileMenuDetailMenuTitle>
                          );
                        })}
                      </MobileMenuDetailWrapper>
                    </MobileMenuAccordionDetails>
                  </MobileSubMenuAccordion>
                );
              })}
            </MobileMenuAccordionDetails>
          </MobileMenuAccordion>
        ))}
      </ErrorManage>
    </MobileMenuModalContainer>
  );
};

export default memo(MobileMenuModal);
